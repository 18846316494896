import React from 'react';
import { Typography, Tooltip, FormControlLabel, Box, Switch as MuiSwitch, styled } from '@mui/material';

interface LegendProps {
    selectedEventType: string | null;
    useNewColorScale: boolean;
    onColorScaleChange: (checked: boolean) => void;
}

// Özelleştirilmiş Switch komponenti
const CustomSwitch = styled(MuiSwitch)(({ theme }) => ({
    width: 42,
    height: 24,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(18px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: '#094151',
                opacity: 1,
                border: 0,
            },
        },
        '&.Mui-unchecked': {
            color: '#fff',
        }
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 20,
        height: 20,
        backgroundColor: '#fff',
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#094151',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

const Legend = ({ selectedEventType, useNewColorScale, onColorScaleChange }: LegendProps) => {
    const colorScales: Record<string, string[]> = {
        'cf5f045f-a360-9c6f-5566-757fd856878a': ['#FFC19F', '#FCAE84', '#EC8E5B', '#D76E3B'], //Terörizm (TE)
        '450b0d5e-6639-3649-b055-45ad25dc1e72': ['#FFB8B8', '#FF9B9B', '#FF7C7C', '#F35E5E'], //Örgütlü Suçlar (OS)
        'ff4ee709-9d4d-3b66-e0c7-09456d55d54d': ['#DEFFE9', '#B5EBC7', '#93DDAC', '#67C086'], //Sosyal ve Politik İstikrarsızlık (SP)
        '0d2afe18-19b2-d63a-5d3e-6f92105b778b': ['#C0E3CD', '#9ED0B1', '#7BB691', '#689D7B'], //Çevresel ve Doğal Afetler (ÇA)
        '2996d900-08f7-fe3b-624e-685d3e0230f8': ['#D7F5FF', '#ABE2F4', '#82CAE2', '#63ABC3'], //Bilgi Güvenliği ve Enformasyon Tehditleri (BG)
        '0a6d93e8-135c-d061-cac2-e9ff390567c8': ['#C0E1FF', '#96C8F6', '#75B1E9', '#4995DB'], //Bireysel ve Adi Suçlar (BA)
        'fa04c441-2bc2-3c4a-7f77-d59738f05372': ['#f6dfb4', '#b5a088', '#9d866b', '#8e6c58'], //Göç ve Mülteci Krizleri (GM)
        '415de057-31d6-87ab-00c2-9d038fc4a7d2': ['#a8c8ab', '#71ac81', '#417162', '#265454'], //Sağlık ve Biyolojik Tehditler (SB)
        'fb7cdcca-889e-f136-64ed-351dee94cebf': ['#329a98', '#1a7082', '#204551', '#0f223a'], //Yasal ve Düzenleyici Uyumsuzluklar (YD)
        '2567eb67-43ad-8075-07ce-8b95a5c59305': ['#ebc3aa', '#cb8374', '#a4574a', '#4a3b39'], //Ekonomik ve Finansal Tehditler (EF)
        'default': ['#9CC9E1', '#4C9CC2', '#367793', '#215264']
    };

    // Yeni renk skalası için sadece başlangıç ve bitiş seviyeleri
    const newRiskLevels = [
        { level: 'Düşük', color: '#00ff00', showLabel: true },      // Neon yeşil
        { level: '', color: '#66ff66', showLabel: false },          // Açık neon yeşil
        { level: '', color: '#f5f5f5', showLabel: false },          // Beyaz tonu (nötr)
        { level: '', color: '#ff4d4d', showLabel: false },          // Açık neon kırmızı
        { level: 'Çok Yüksek', color: '#ff0000', showLabel: true }  // Neon kırmızı
    ];

    // Eski renk skalası için sadece başlangıç ve bitiş seviyeleri
    const oldRiskLevels = [
        { level: 'Düşük', color: colorScales[selectedEventType || 'default'][0], showLabel: true },
        { level: '', color: colorScales[selectedEventType || 'default'][1], showLabel: false },
        { level: '', color: colorScales[selectedEventType || 'default'][2], showLabel: false },
        { level: 'Çok Yüksek', color: colorScales[selectedEventType || 'default'][3], showLabel: true }
    ];

    const currentRiskLevels = useNewColorScale ? newRiskLevels : oldRiskLevels;

    return (
        <div style={{
            position: 'absolute',
            bottom: '10px',
            right: '10px',
            zIndex: 1000,
            backgroundColor: 'white',
            padding: '10px',
            borderRadius: '5px',
            boxShadow: '0 1px 2px rgba(0,0,0,0.2)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center', // Merkeze hizalama
            width: '225px'
        }}>
            <Box sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                gap: 1,
                mb: 2,
                width: '100%'
            }}>
                <Typography sx={{ 
                    fontSize: '12px', 
                    color: !useNewColorScale ? '#094151' : '#666'
                }}>
                    Risk Seviyesi
                </Typography>
                <CustomSwitch
                    checked={useNewColorScale}
                    onChange={(e) => onColorScaleChange(e.target.checked)}
                    size="small"
                />
                <Typography sx={{ 
                    fontSize: '12px', 
                    color: !useNewColorScale ? '#666' : '#094151'
                }}>
                    Günlük Değişim
                </Typography>
            </Box>
            
            <Box sx={{ 
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <Box sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'space-between'
                }}>
                    <Typography sx={{ fontSize: '10px' }}>
                        {currentRiskLevels[0].level}
                    </Typography>
                    <Typography sx={{ fontSize: '10px' }}>
                        {currentRiskLevels[currentRiskLevels.length - 1].level}
                    </Typography>
                </Box>
                <Box sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    height: '15px',
                    mt: 0.5
                }}>
                    {currentRiskLevels.map((risk, index) => (
                        <div
                            key={index}
                            style={{
                                backgroundColor: risk.color,
                                height: '100%',
                                flex: 1,
                                border: risk.color === '#f5f5f5' ? '1px solid #ccc' : 'none'
                            }}
                        />
                    ))}
                </Box>
            </Box>

            <Tooltip
                title={
                    <Box sx={{ p: 1 }}>
                        {useNewColorScale ? (
                            <>
                                <Typography variant="body2" paragraph>
                                    Günlük Değişim Hesaplama Sistemi Nasıl Çalışıyor?
                                </Typography>
                                <Typography variant="body2" paragraph>
                                    Haritada iller bazında olay yoğunlukları, günlük ortalama olay sayılarındaki değişime göre görselleştirilir. Hesaplama süreci şu şekilde işler:
                                </Typography>
                                <Typography variant="body2" paragraph>
                                    Her ilin ortalama günlük olay sayıları, geçmiş veri analizleri kullanılarak hesaplanır.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                    Günlük gerçekleşen olay sayıları, bu ortalamalarla karşılaştırılır.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                    Ortalamanın altında kalan olay sayıları, haritada yeşil tonlarında gösterilerek düşük olay yoğunluğu ifade eder.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                    Ortalamanın üstünde olan olay sayıları ise haritada kırmızı tonlarında gösterilerek daha yoğun bir durumu vurgular.
                                </Typography>
                                <Typography variant="body2">
                                    Bu görselleştirme yöntemi sayesinde olağan dışı durumlar anında fark edilebilir ve gerektiğinde önlemler alınabilir.
                                </Typography>
                            </>
                        ) : (
                            <>
                                <Typography variant="body2" paragraph>
                                    Risk hesaplama sistemi nasıl çalışıyor?
                                </Typography>
                                <Typography variant="body2" paragraph>
                                    Risk göstergesi, bir olayın oluşturabileceği potansiyel zarar ve ihtimali görselleştirerek risk seviyelerini anlamanızı kolaylaştırır. Hesaplama, aşağıdaki formüle dayanır:
                                    R = P × I
                                </Typography>
                                <Typography variant="body2" paragraph>
                                    R: Genel risk seviyesi. Olayın tehlike derecesini ifade eder.
                                </Typography>
                                <Typography variant="body2" paragraph>
                                    P: Olayın gerçekleşme olasılığı (1-5 arasında normalize edilmiş bir değer).
                                </Typography>
                                <Typography variant="body2">
                                    I: Etki katsayısı (1-5), olayın doğrudan veya dolaylı etkisini temsil eder.
                                </Typography>
                            </>
                        )}
                    </Box>
                }
                arrow
                placement="top"
                sx={{
                    '& .MuiTooltip-tooltip': {
                        bgcolor: 'white',
                        color: 'black',
                        maxWidth: 400
                    }
                }}
            >
                <Typography
                    variant="caption"
                    sx={{
                        fontStyle: 'italic',
                        color: 'text.secondary',
                        fontSize: '9px',
                        mt: 1,
                        cursor: 'pointer',
                        '&:hover': {
                            textDecoration: 'underline'
                        }
                    }}
                >
                    Riski nasıl hesaplıyoruz?
                </Typography>
            </Tooltip>
        </div>
    );
};

export default Legend;