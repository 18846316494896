export const fetchWikipediaData = async (title: string) => {
	try {
		const apiUrl = `https://tr.wikipedia.org/api/rest_v1/page/summary/${encodeURIComponent(title)}`;

		const response = await fetch(apiUrl);
		if (response.status === 200) {
			const data = await response.json();

			if (data.title) {
				return {
					title: data.title,
					extract: data.extract,
					thumbnail: data.thumbnail
				};
			} else {
				return null;
			}
		} else {
			return null;
		}

	} catch (error) {
		console.error('Wikipedia verisi alınırken bir hata oluştu:', error);
		return null;
	}
};

export const fetchWikipediaDataByCoordinates = async (lat: number, lon: number) => {
	try {
		const apiUrl = `https://tr.wikipedia.org/w/api.php?action=query&gscoord=${lat}|${lon}&list=geosearch&format=json&origin=*`;

		const response = await fetch(apiUrl);
		const data = await response.json();

		if (data?.query?.geosearch?.length > 0) {
			const firstResultTitle = data.query.geosearch[0].title;

			// İlk sonucun başlığıyla Wikipedia verilerini al
			return await fetchWikipediaData(firstResultTitle);
		} else {

			return null;
		}
	} catch (error) {
		console.error('Geosearch API verisi alınırken bir hata oluştu:', error);
		return null;
	}
};