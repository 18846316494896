import React, { useState, useEffect, memo } from 'react';
import {
    Typography,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    Box,
    Paper,
    Grid,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    TextField,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,

} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import eventsApi from '../../../api/eventsApi/eventsApi';
import { useSelector } from 'react-redux';
import { Links } from '../../../constants/Links';

interface QuestionCategory {
    id: string;
    name: string;
}

interface Question {
    id: string;
    text: string;
    questionCategoryId: string;
}

interface Answer {
    answer: string;
    description: string;
    findings: string;
    proposedSolutions: string;
    locked?: boolean;
    files?: File[];
    questionAnswerId?: string;
}

interface FormAnswers {
    [key: string]: Answer;
}

interface ApiResponse<T> {
    items: T[];
}

interface RiskTableData {
    probabilityLevel: string;
    impactLevel: string;
    threatLevel: string;
    measureCoefficient: string;
    residualRiskLevel: string;
}

interface RiskTables {
    table1: RiskTableData;
    table2: RiskTableData;
}

interface QuestionAnswer {
    id: string;
    questionId: string;
    userId: string;
    yesNo: boolean;
    findings: string;
    pinId: string;
    proposedSolutions: string;
    description: string;
    files?: File[];
}

interface QuestionAnswerResponse {
    id: string;
    questionId: string;
    userId: string;
    yesNo: boolean;
    findings: string;
    pinId: string;
    proposedSolutions: string;
    description: string;
}

interface ChecklistModalProps {
    open: boolean;
    onClose: () => void;
    pinId: string;
}

interface NewCategoryData {
    name: string;
    questionTypeId: string;
}

interface AudioRecordingState {
    isRecording: boolean;
    mediaRecorder: MediaRecorder | null;
}

interface CategoryRiskAnswer {
    questionCategoryId: string;
    pinId: string;
    userId: string;
    probabilityLevel: number;
    impactLevel: number;
    threatLevel: number;
    appliedMeasureCoefficient: number;
    residualRiskLevel: number;
    isAfterMeasures: boolean;
}

interface CategoryRiskAnswerUpdate extends CategoryRiskAnswer {
    id: string;
}

interface CategoryRiskAnswerResponse extends CategoryRiskAnswerUpdate {
    // Eğer API'den dönen başka alanlar varsa buraya eklenebilir
}

const CategoryBox = memo(({ 
    name, 
    onClick, 
    questionCount,
    answeredCount 
}: { 
    name: string; 
    onClick: () => void;
    questionCount: number;
    answeredCount: number;
}) => {
    const isAllAnswered = answeredCount === questionCount;
    
    return (
        <Paper
            elevation={2}
            sx={{
                p: 2,
                height: '100px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
                transition: 'background-color 0.3s',
                position: 'relative'
            }}
            onClick={onClick}
        >
            <Typography
                sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    fontStyle: 'italic',
                    color: isAllAnswered ? '#2e7d32' : '#666',
                    fontWeight: isAllAnswered ? 700 : 400,
                }}
            >
                {answeredCount}/{questionCount}
            </Typography>
            <Typography
                variant="subtitle1"
                align="center"
                sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#333'
                }}
            >
                {name}
            </Typography>
        </Paper>
    );
});

const RiskTable = memo(({ 
    data, 
    onChange, 
    backgroundColor,
    isLocked,
    isEditing,
    onEdit,
    onSave,
    isAfterMeasures 
}: { 
    data: RiskTableData; 
    onChange: (field: keyof RiskTableData, value: string) => void;
    backgroundColor: string;
    isLocked?: boolean;
    isEditing?: boolean;
    onEdit?: () => void;
    onSave?: () => void;
    isAfterMeasures: boolean;
}) => {
    const calculateThreatLevel = () => {
        const probability = parseFloat(data.probabilityLevel) || 0;
        const impact = parseFloat(data.impactLevel) || 0;
        return (probability * impact).toString();
    };

    const calculateResidualRisk = () => {
        const threat = parseFloat(calculateThreatLevel()) || 0;
        const coefficient = parseFloat(data.measureCoefficient) || 0;
        return (threat * coefficient).toString();
    };

    const getRiskLevel = () => {
        const residualRisk = parseFloat(calculateResidualRisk()) || 0;
        
        if (residualRisk < 3) {
            return { text: 'DÜŞÜK', color: '#FFFFFF' };  // Beyaz
        } else if (residualRisk >= 3 && residualRisk <= 10) {
            return { text: 'ORTA', color: '#FFFF00' };   // Sarı
        } else if (residualRisk > 10 && residualRisk < 20) {
            return { text: 'YÜKSEK', color: '#FFA500' }; // Turuncu
        } else {
            return { text: 'KRİTİK', color: '#FF0000' }; // Kırmızı
        }
    };

    const riskLevel = getRiskLevel();

    return (
        <Box>
            {isLocked && (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1 }}>
                    <Button
                        onClick={isEditing ? onSave : onEdit}
                        sx={{
                            color: isEditing ? '#fff' : '#094151',
                            backgroundColor: isEditing ? '#094151' : 'transparent',
                            '&:hover': {
                                backgroundColor: isEditing ? '#022631' : 'rgba(9, 65, 81, 0.08)'
                            }
                        }}
                    >
                        {isEditing ? 'Güncelle' : 'Düzenle'}
                    </Button>
                </Box>
            )}
            <TableContainer component={Paper} sx={{ mb: 2, backgroundColor }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Olasılık Seviyesi</TableCell>
                            <TableCell>Etki Seviyesi</TableCell>
                            <TableCell>Tehdit Seviyesi</TableCell>
                            <TableCell>Uygulanan Tedbirler Kat Sayısı</TableCell>
                            <TableCell>Kalıntı Risk Seviyesi</TableCell>
                            <TableCell>Risk ve Tehdit Seviyesi</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <TextField
                                    size="small"
                                    value={data.probabilityLevel}
                                    onChange={(e) => onChange('probabilityLevel', e.target.value)}
                                    sx={{ width: '100px' }}
                                    disabled={isLocked && !isEditing}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    size="small"
                                    value={data.impactLevel}
                                    onChange={(e) => onChange('impactLevel', e.target.value)}
                                    sx={{ width: '100px' }}
                                    disabled={isLocked && !isEditing}
                                />
                            </TableCell>
                            <TableCell>
                                <Typography>{calculateThreatLevel()}</Typography>
                            </TableCell>
                            <TableCell>
                                <TextField
                                    size="small"
                                    value={data.measureCoefficient}
                                    onChange={(e) => onChange('measureCoefficient', e.target.value)}
                                    sx={{ width: '100px' }}
                                    disabled={isLocked && !isEditing}
                                />
                            </TableCell>
                            <TableCell>
                                <Typography>{calculateResidualRisk()}</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography sx={{ 
                                    backgroundColor: riskLevel.color,
                                    padding: '4px 8px',
                                    borderRadius: '4px',
                                    display: 'inline-block',
                                    fontWeight: 'bold'
                                }}>
                                    {riskLevel.text}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
});

const MicrophoneButton = memo(({ 
    onRecordingComplete,
    onTranscriptionComplete,
    disabled = false
}: { 
    onRecordingComplete: (audioBlob: Blob) => void;
    onTranscriptionComplete: (text: string) => void;
    disabled?: boolean;
}) => {
    const [recordingState, setRecordingState] = useState<AudioRecordingState>({
        isRecording: false,
        mediaRecorder: null
    });
    const [isProcessing, setIsProcessing] = useState(false);

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const mediaRecorder = new MediaRecorder(stream);
            const audioChunks: BlobPart[] = [];

            mediaRecorder.ondataavailable = (event) => {
                audioChunks.push(event.data);
            };

            mediaRecorder.onstop = async () => {
                const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
                onRecordingComplete(audioBlob);
                
                // Stop all tracks in the stream
                stream.getTracks().forEach(track => track.stop());

                // Process the audio with our API
                try {
                    setIsProcessing(true);
                    const response = await eventsApi.getSpeechToText(audioBlob);
                    // Parse the response text to get only the text content
                    try {
                        const parsedResponse = JSON.parse(response.transcription);
                        onTranscriptionComplete(parsedResponse.text);
                    } catch (error) {
                        // If parsing fails, use the raw text
                        onTranscriptionComplete(response.transcription);
                    }
                } catch (error) {
                    console.error('Transcription error:', error);
                } finally {
                    setIsProcessing(false);
                }
            };

            mediaRecorder.start();
            setRecordingState({ isRecording: true, mediaRecorder });
        } catch (error) {
            console.error('Error accessing microphone:', error);
        }
    };

    const stopRecording = () => {
        if (recordingState.mediaRecorder) {
            recordingState.mediaRecorder.stop();
            setRecordingState({ isRecording: false, mediaRecorder: null });
        }
    };

    return (
        <IconButton
            size="small"
            onClick={recordingState.isRecording ? stopRecording : startRecording}
            disabled={disabled || isProcessing}
            sx={{
                color: recordingState.isRecording ? '#f44336' : 
                       isProcessing ? '#999' : '#7FB3C8',
                padding: '4px',
                '&:hover': {
                    backgroundColor: recordingState.isRecording ? 
                        'rgba(244, 67, 54, 0.04)' : 
                        'rgba(9, 65, 81, 0.04)'
                }
            }}
        >
            {recordingState.isRecording ? 
                <MicOffIcon sx={{ fontSize: '18px' }} /> :
                <MicIcon sx={{ fontSize: '18px' }} />
            }
        </IconButton>
    );
});

const QuestionItem = memo(({
    question,
    index,
    answer,
    onAnswerChange,
    onDescriptionChange,
    onFindingsChange,
    onProposedSolutionsChange,
    onEditClick,
    onFileChange,
    onSaveEdit,
    isEditing
}: {
    question: Question;
    index: number;
    answer: Answer;
    onAnswerChange: (id: string, value: string) => void;
    onDescriptionChange: (id: string, value: string) => void;
    onFindingsChange: (id: string, value: string) => void;
    onProposedSolutionsChange: (id: string, value: string) => void;
    onEditClick: (id: string) => void;
    onFileChange: (id: string, files: File[]) => void;
    onSaveEdit: (id: string) => void;
    isEditing: boolean;
}) => (
    <Paper sx={{ mb: 3, p: 2, backgroundColor:'#f9f9f9', borderRadius: 1, position: 'relative' }}>
        {answer.locked && (
            <Box sx={{ position: 'absolute', right: 16, top: 16 }}>
                <Button
                    onClick={() => isEditing ? onSaveEdit(question.id) : onEditClick(question.id)}
                    sx={{
                        color: isEditing ? '#fff' : '#094151',
                        backgroundColor: isEditing ? '#094151' : 'transparent',
                        '&:hover': {
                            backgroundColor: isEditing ? '#022631' : 'rgba(9, 65, 81, 0.08)',
                            color: isEditing ? '#fff !important' : '#094151 !important',
                        }
                    }}
                >
                    {isEditing ? 'Güncelle' : 'Düzenle'}
                </Button>
            </Box>
        )}
        
        <Typography sx={{ mb: 2, fontWeight: 500, pr: answer.locked ? 8 : 0 }}>
            {index + 1}. {question.text}
        </Typography>
        
        <FormControl component="fieldset" sx={{ mb: 2 }} disabled={answer.locked && !isEditing}>
            <RadioGroup
                row
                value={answer?.answer || ''}
                onChange={(e) => onAnswerChange(question.id, e.target.value)}
            >
                <FormControlLabel value="evet" control={<Radio />} label="Evet" />
                <FormControlLabel value="hayir" control={<Radio />} label="Hayır" />
            </RadioGroup>
        </FormControl>

        <Grid sx={{ position: 'relative'}}>
            <TextField
                fullWidth
                size="small"
                label="Açıklama"
                variant="outlined"
                value={answer?.description || ''}
                onChange={(e) => onDescriptionChange(question.id, e.target.value)}
                sx={{
                    mb: 1,
                    display: 'flex',
                    backgroundColor: '#fff',
                    borderRadius: '6px !important',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '64px',
                    '& .MuiInputBase-root': { height: '64px' },
                    '& .MuiOutlinedInput-input': { height: '100%', padding: '0 14px' }
                }}
                disabled={answer.locked && !isEditing}
            />
            <Grid sx={{
                position: 'absolute',
                right: 8,
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: 1,
            }}>
                <MicrophoneButton
                    onRecordingComplete={(audioBlob) => {}}
                    onTranscriptionComplete={(text) => {
                        onDescriptionChange(question.id, text);
                    }}
                    disabled={answer.locked && !isEditing}
                />
            </Grid>
        </Grid>

        <Grid sx={{ position: 'relative' }}>
            <TextField
                fullWidth
                size="small"
                label="Tespitler"
                variant="outlined"
                value={answer?.findings || ''}
                onChange={(e) => onFindingsChange(question.id, e.target.value)}
                sx={{
                    mb: 1,
                    display: 'flex',
                    backgroundColor: '#fff',
                    borderRadius: '6px !important',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '64px',
                    '& .MuiInputBase-root': { height: '64px' },
                    '& .MuiOutlinedInput-input': { height: '100%', padding: '0 14px' }
                }}
                multiline
                rows={2}
                disabled={answer.locked && !isEditing}
            />
            <Box sx={{
                position: 'absolute',
                right: 8,
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: 1
            }}>
                <MicrophoneButton
                    onRecordingComplete={(audioBlob) => {}}
                    onTranscriptionComplete={(text) => {
                        onFindingsChange(question.id, text);
                    }}
                    disabled={answer.locked && !isEditing}
                />
            </Box>
        </Grid>

        <Grid sx={{ position: 'relative' }}>
            <TextField
                fullWidth
                size="small"
                label="Öneriler"
                variant="outlined"
                value={answer?.proposedSolutions || ''}
                onChange={(e) => onProposedSolutionsChange(question.id, e.target.value)}
                sx={{
                    mb: 1,
                    display: 'flex',
                    backgroundColor: '#fff',
                    borderRadius: '6px !important',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '64px',
                    '& .MuiInputBase-root': { height: '64px' },
                    '& .MuiOutlinedInput-input': { height: '100%', padding: '0 14px' }
                }}
                multiline
                rows={2}
                disabled={answer.locked && !isEditing}
            />
            <Box sx={{
                position: 'absolute',
                right: 8,
                top: '50%',
                transform: 'translateY(-50%)',
                zIndex: 1
            }}>
                <MicrophoneButton
                    onRecordingComplete={(audioBlob) => {}}
                    onTranscriptionComplete={(text) => {
                        onProposedSolutionsChange(question.id, text);
                    }}
                    disabled={answer.locked && !isEditing}
                />
            </Box>
        </Grid>


        <Box sx={{ 
            border: '1px dashed #094151', 
            borderRadius: 1, 
            p: 2, 
            display: 'flex', 
            flexDirection: 'column',
            gap: 2,
            backgroundColor: '#fff',
            opacity: answer.locked ? 0.7 : 1
        }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Button
                    variant="outlined"
                    component="label"
                    disabled={answer.locked && !isEditing}
                    sx={{width:'160px', backgroundColor: '#196074', color:"#fff",
                        '&:hover': {
                            backgroundColor: '#094151', color:"#fff", fontWeight: 600
                        }
                    }}
                >
                    Dosya Seç
                    <input
                        type="file"
                        hidden
                        multiple
                        onChange={(e) => {
                            if (e.target.files) {
                                const filesArray = Array.from(e.target.files);
                                onFileChange(question.id, filesArray);
                            }
                        }}
                        disabled={answer.locked && !isEditing}
                    />
                </Button>
                <Typography variant="body2" color="textSecondary">
                    Desteklenen dosya formatları: PDF, JPEG, PNG (Max: 5MB)
                </Typography>
            </Box>
            
            {answer.files && answer.files.length > 0 && (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {answer.files.map((file, index) => (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                backgroundColor: '#f0f0f0',
                                padding: '4px 8px',
                                borderRadius: '4px'
                            }}
                        >
                            <Typography variant="body2">{file.name}</Typography>
                        </Box>
                    ))}
                </Box>
            )}
        </Box>
    </Paper>
));

const AddCategoryBox = memo(({ onAddClick }: { onAddClick: () => void }) => (
    <Paper
        elevation={2}
        sx={{
            p: 2,
            height: '100px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            backgroundColor: '#f5f5f5',
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
            transition: 'background-color 0.3s'
        }}
        onClick={onAddClick}
    >
        <AddIcon sx={{ fontSize: 32, color: '#666' }} />
        <Typography sx={{ mt: 1, color: '#666' }}>Yeni Kategori Ekle</Typography>
    </Paper>
));

const AddQuestionBox = memo(({ onAddClick }: { onAddClick: () => void }) => (
    <Paper
        elevation={2}
        sx={{
            p: 2,
            mt: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            backgroundColor: '#f5f5f5',
            '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
            transition: 'background-color 0.3s'
        }}
        onClick={onAddClick}
    >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <AddIcon sx={{ fontSize: 24, color: '#666' }} />
            <Typography sx={{ color: '#666' }}>Yeni Soru Ekle</Typography>
        </Box>
    </Paper>
));

const ChecklistModal: React.FC<ChecklistModalProps> = ({ open, onClose, pinId }) => {
    const [categories, setCategories] = useState<QuestionCategory[]>([]);
    const [questions, setQuestions] = useState<Question[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
    const [answers, setAnswers] = useState<FormAnswers>({});
    const [loading, setLoading] = useState(true);
    const [riskTablesData, setRiskTablesData] = useState<{[key: string]: {
        table1: RiskTableData;
        table2: RiskTableData;
    }}>({});
    const [categoryQuestionCounts, setCategoryQuestionCounts] = useState<{[key: string]: number}>({});
    const [userId, setUserId] = useState<string>('');
    const [newCategoryDialogOpen, setNewCategoryDialogOpen] = useState(false);
    const [newCategoryName, setNewCategoryName] = useState('');
    const [newQuestionDialogOpen, setNewQuestionDialogOpen] = useState(false);
    const [newQuestionText, setNewQuestionText] = useState('');
    const [selectedCategoryName, setSelectedCategoryName] = useState<string>('');
    const [categoryAnsweredCounts, setCategoryAnsweredCounts] = useState<{[key: string]: number}>({});
    const [editingQuestionId, setEditingQuestionId] = useState<string | null>(null);
    const [riskAnswers, setRiskAnswers] = useState<{[key: string]: {
        beforeMeasures?: CategoryRiskAnswerResponse;
        afterMeasures?: CategoryRiskAnswerResponse;
    }}>({});
    const [editingRiskTable, setEditingRiskTable] = useState<{
        categoryId: string | null;
        isAfterMeasures: boolean;
    }>({ categoryId: null, isAfterMeasures: false });

    const DEFAULT_QUESTION_TYPE_ID = "72236abf-77d8-49e7-baf7-59d68da4dc81";

    const storeUserId = useSelector((state: any) => state.auth.id);

    useEffect(() => {
        if (!storeUserId) {
            console.error('User ID not found');
            return;
        }
        setUserId(storeUserId);
    }, [storeUserId]);

    useEffect(() => {
        const fetchCategories = async () => {
            if (open) {
                try {
                    const categoriesResponse = await eventsApi.getQuestionCategories() as ApiResponse<QuestionCategory>;
                    setCategories(categoriesResponse.items || []);
                    
                    const questionsResponse = await eventsApi.getQuestions() as ApiResponse<Question>;
                    const counts = questionsResponse.items.reduce((acc, question) => {
                        acc[question.questionCategoryId] = (acc[question.questionCategoryId] || 0) + 1;
                        return acc;
                    }, {} as {[key: string]: number});
                    
                    setCategoryQuestionCounts(counts);

                    // Tüm cevapları al ve pinId'ye göre filtrele
                    const answersResponse = await eventsApi.getQuestionAnswers(pinId) as ApiResponse<QuestionAnswerResponse>;
                    const filteredAnswers = answersResponse.items.filter(item => item.pinId === pinId);
                    
                    // Filtrelenmiş cevapları formatlayıp state'e kaydet
                    const formattedAnswers = filteredAnswers.reduce((acc, item) => {
                        acc[item.questionId] = {
                            answer: item.yesNo ? 'evet' : 'hayir',
                            findings: item.findings,
                            description: item.description,
                            proposedSolutions: item.proposedSolutions,
                            locked: true,
                            questionAnswerId: item.id
                        };
                        return acc;
                    }, {} as FormAnswers);

                    setAnswers(formattedAnswers);

                    // Her kategori için cevaplanan soru sayısını hesapla
                    const answeredCountsByCategory = questionsResponse.items.reduce((acc, question) => {
                        if (formattedAnswers[question.id]) {
                            acc[question.questionCategoryId] = (acc[question.questionCategoryId] || 0) + 1;
                        }
                        return acc;
                    }, {} as {[key: string]: number});

                    setCategoryAnsweredCounts(answeredCountsByCategory);

                } catch (error) {
                    console.error('Error fetching data:', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        fetchCategories();
    }, [open, pinId]);

    useEffect(() => {
        const loadRiskAnswers = async () => {
            if (open && pinId) {
                try {
                    const response = await eventsApi.getCategoryRiskAnswers(pinId) as ApiResponse<CategoryRiskAnswerResponse>;
                    const formattedAnswers = response.items.reduce((acc, item) => {
                        if (!acc[item.questionCategoryId]) {
                            acc[item.questionCategoryId] = {};
                        }
                        if (item.isAfterMeasures) {
                            acc[item.questionCategoryId].afterMeasures = item;
                        } else {
                            acc[item.questionCategoryId].beforeMeasures = item;
                        }
                        return acc;
                    }, {} as {[key: string]: {
                        beforeMeasures?: CategoryRiskAnswerResponse;
                        afterMeasures?: CategoryRiskAnswerResponse;
                    }});
                    setRiskAnswers(formattedAnswers);
                } catch (error) {
                    console.error('Risk cevapları yüklenirken hata:', error);
                }
            }
        };
        loadRiskAnswers();
    }, [open, pinId]);

    const handleRiskTableChange = (questionId: string, tableIndex: number, field: keyof RiskTableData, value: string) => {
        setRiskTablesData(prev => ({
            ...prev,
            [questionId]: {
                ...prev[questionId] || {},
                ...(tableIndex === 1 ? {
                    table1: {
                        ...((prev[questionId] || {})?.table1 || getEmptyRiskTableData()),
                        [field]: value
                    },
                    table2: (prev[questionId] || {})?.table2 || getEmptyRiskTableData()
                } : {
                    table1: (prev[questionId] || {})?.table1 || getEmptyRiskTableData(),
                    table2: {
                        ...((prev[questionId] || {})?.table2 || getEmptyRiskTableData()),
                        [field]: value
                    }
                })
            }
        }));
    };

    const getEmptyRiskTableData = (): RiskTableData => ({
        probabilityLevel: '',
        impactLevel: '',
        threatLevel: '',
        measureCoefficient: '',
        residualRiskLevel: '',
    });

    const handleCategoryClick = async (categoryId: string) => {
        setLoading(true);
        try {
            const questionsResponse = await eventsApi.getQuestions() as ApiResponse<Question>;
            const categoryQuestions = questionsResponse.items.filter(
                q => q.questionCategoryId === categoryId
            );
            setQuestions(categoryQuestions);
            setSelectedCategory(categoryId);
            
            const category = categories.find(c => c.id === categoryId);
            if (category) {
                setSelectedCategoryName(category.name);
            }
        } catch (error) {
            console.error('Error fetching questions:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleBackClick = async () => {
        try {
            // Tüm cevapları al ve pinId'ye göre filtrele
            const answersResponse = await eventsApi.getQuestionAnswers(pinId) as ApiResponse<QuestionAnswerResponse>;
            const filteredAnswers = answersResponse.items.filter(item => item.pinId === pinId);
            
            // Filtrelenmiş cevapları formatlayıp state'e kaydet
            const formattedAnswers = filteredAnswers.reduce((acc, item) => {
                acc[item.questionId] = {
                    answer: item.yesNo ? 'evet' : 'hayir',
                    findings: item.findings,
                    description: item.description,
                    proposedSolutions: item.proposedSolutions,
                    locked: true,
                    questionAnswerId: item.id
                };
                return acc;
            }, {} as FormAnswers);

            const questionsResponse = await eventsApi.getQuestions() as ApiResponse<Question>;
            const answeredCountsByCategory = questionsResponse.items.reduce((acc, question) => {
                if (formattedAnswers[question.id]) {
                    acc[question.questionCategoryId] = (acc[question.questionCategoryId] || 0) + 1;
                }
                return acc;
            }, {} as {[key: string]: number});

            setCategoryAnsweredCounts(answeredCountsByCategory);
            setSelectedCategory(null);
            setQuestions([]);
            setSelectedCategoryName('');
        } catch (error) {
            console.error('Error updating answered counts:', error);
        }
    };

    const handleAnswerChange = (questionId: string, value: string) => {
        setAnswers(prev => {
            const updatedAnswers = {
                ...prev,
                [questionId]: { 
                    ...prev[questionId], 
                    answer: value 
                }
            };

            return updatedAnswers;
        });
    };

    const handleDescriptionChange = (questionId: string, value: string) => {
        setAnswers(prev => ({
            ...prev,
            [questionId]: { ...prev[questionId], description: value }
        }));
    };

    const handleFindingsChange = (questionId: string, value: string) => {
        setAnswers(prev => ({
            ...prev,
            [questionId]: { ...prev[questionId], findings: value }
        }));
    };

    const handleProposedSolutionsChange = (questionId: string, value: string) => {
        setAnswers(prev => ({
            ...prev,
            [questionId]: { ...prev[questionId], proposedSolutions: value }
        }));
    };

    const handleFileChange = (questionId: string, files: File[]) => {
        setAnswers(prev => ({
            ...prev,
            [questionId]: { ...prev[questionId], files }
        }));
    };

    const handleRiskTableSave = async (categoryId: string, isAfterMeasures: boolean) => {
        const tableData = riskTablesData[categoryId]?.[isAfterMeasures ? 'table2' : 'table1'];
        if (!tableData) return;

        // Tehdit seviyesi hesaplama
        const probabilityLevel = parseFloat(tableData.probabilityLevel) || 0;
        const impactLevel = parseFloat(tableData.impactLevel) || 0;
        const threatLevel = probabilityLevel * impactLevel;

        // Kalıntı risk seviyesi hesaplama
        const appliedMeasureCoefficient = parseFloat(tableData.measureCoefficient) || 0;
        const residualRiskLevel = threatLevel * appliedMeasureCoefficient;

        const data: CategoryRiskAnswer = {
            questionCategoryId: categoryId,
            pinId,
            userId,
            probabilityLevel,
            impactLevel,
            threatLevel,
            appliedMeasureCoefficient,
            residualRiskLevel,
            isAfterMeasures: Boolean(isAfterMeasures)
        };

        // CURL komutunu oluştur
        const curlCommand = `curl -X 'POST' \\
        '${Links.BASE_URL_WITHOUT_TENANT}/api/CategoryRiskAnswers' \\
        -H 'accept: */*' \\
        -H 'Content-Type: application/json' \\
        -d '${JSON.stringify(data, null, 2)}'`;

        try {
            const response = await eventsApi.saveCategoryRiskAnswers(data);
            setRiskAnswers(prev => ({
                ...prev,
                [categoryId]: {
                    ...prev[categoryId],
                    [isAfterMeasures ? 'afterMeasures' : 'beforeMeasures']: response
                }
            }));
        } catch (error) {
            console.error('Risk tablosu kaydedilirken hata:', error);
        }
    };

    const handleRiskTableEdit = (categoryId: string, isAfterMeasures: boolean) => {
        setEditingRiskTable({ categoryId, isAfterMeasures });
    };

    const handleRiskTableUpdate = async (categoryId: string, isAfterMeasures: boolean) => {
        const tableData = riskTablesData[categoryId]?.[isAfterMeasures ? 'table2' : 'table1'];
        const existingAnswer = isAfterMeasures ? 
            riskAnswers[categoryId]?.afterMeasures : 
            riskAnswers[categoryId]?.beforeMeasures;

        if (!tableData || !existingAnswer) return;

        // Tehdit seviyesi hesaplama
        const probabilityLevel = parseFloat(tableData.probabilityLevel) || 0;
        const impactLevel = parseFloat(tableData.impactLevel) || 0;
        const threatLevel = probabilityLevel * impactLevel;

        // Kalıntı risk seviyesi hesaplama
        const appliedMeasureCoefficient = parseFloat(tableData.measureCoefficient) || 0;
        const residualRiskLevel = threatLevel * appliedMeasureCoefficient;

        const data: CategoryRiskAnswerUpdate = {
            id: existingAnswer.id,
            questionCategoryId: categoryId,
            pinId,
            userId,
            probabilityLevel,
            impactLevel,
            threatLevel,
            appliedMeasureCoefficient,
            residualRiskLevel,
            isAfterMeasures
        };

        try {
            await eventsApi.updateCategoryRiskAnswers(data);
            setEditingRiskTable({ categoryId: null, isAfterMeasures: false });
        } catch (error) {
            console.error('Risk tablosu güncellenirken hata:', error);
        }
    };

    const handleSave = async () => {
        if (!userId) {
            console.error('User ID is required');
            return;
        }

        try {
            const unlockedAnswers = Object.entries(answers).filter(([_, answer]) => !answer.locked);
            
            const savePromises = unlockedAnswers.map(([questionId, answer]) => {
                const questionAnswer: Omit<QuestionAnswer, 'id'> = {
                    questionId: questionId,
                    userId: userId,
                    yesNo: answer.answer === 'evet',
                    findings: answer.findings || '',
                    pinId: pinId,
                    proposedSolutions: answer.proposedSolutions || '',
                    description: answer.description || '',
                    files: answer.files
                };
                
                setAnswers(prev => ({
                    ...prev,
                    [questionId]: { ...prev[questionId], locked: true }
                }));
                
                return eventsApi.saveQuestionAnswers(questionAnswer);
            });

            await Promise.all(savePromises);


            // Risk tablolarını kaydet
            if (selectedCategory) {
                await handleRiskTableSave(selectedCategory, false); // Öncesi
                await handleRiskTableSave(selectedCategory, true);  // Sonrası
            }
        } catch (error) {
            console.error('Cevaplar kaydedilirken hata oluştu:', error);
        }
    };

    const handleEditClick = (questionId: string) => {
        setEditingQuestionId(questionId);
    };

    const handleSaveEdit = async (questionId: string) => {
        try {
            const answer = answers[questionId];
            if (!answer.questionAnswerId) {
                console.error('Question answer ID not found');
                return;
            }

            const answerData = {
                id: answer.questionAnswerId,
                questionId: questionId,
                userId: userId,
                yesNo: answer.answer === 'evet',
                findings: answer.findings || '',
                pinId: pinId,
                proposedSolutions: answer.proposedSolutions || '',
                description: answer.description || '',
                files: answer.files
            };

            await eventsApi.updateQuestionAnswer(answerData);
            
            setAnswers(prev => ({
                ...prev,
                [questionId]: { ...prev[questionId], locked: true }
            }));
            setEditingQuestionId(null);

        } catch (error) {
            console.error('Soru güncellenirken hata oluştu:', error);
        }
    };

    const getInfoText = () => {
        if (!selectedCategory) {
            return `Risk Analiz Formu ${categories.length} bölümden oluşmaktadır.`;
        }
        return `Bu bölümde ${questions.length} soru bulunmaktadır.`;
    };

    const handleAddCategory = async () => {
        if (!newCategoryName.trim() || !userId) {
            return;
        }

        try {
            const categoryData = {
                name: newCategoryName.trim(),
                userId: userId,
                questionTypeId: DEFAULT_QUESTION_TYPE_ID
            };

            await eventsApi.saveQuestionCategory(categoryData);
            
            // Kategorileri yeniden yükle
            const categoriesResponse = await eventsApi.getQuestionCategories() as ApiResponse<QuestionCategory>;
            setCategories(categoriesResponse.items || []);
            
            // Kategori sayılarını güncelle
            const questionsResponse = await eventsApi.getQuestions() as ApiResponse<Question>;
            const counts = questionsResponse.items.reduce((acc, question) => {
                acc[question.questionCategoryId] = (acc[question.questionCategoryId] || 0) + 1;
                return acc;
            }, {} as {[key: string]: number});
            
            setCategoryQuestionCounts(counts);
            
            // Dialog'u kapat ve form'u temizle
            setNewCategoryName('');
            setNewCategoryDialogOpen(false);
        } catch (error) {
            console.error('Kategori eklenirken hata oluştu:', error);
            // Kullanıcıya hata mesajı gösterilebilir
        }
    };

    const handleAddQuestion = async () => {
        if (!newQuestionText.trim() || !userId || !selectedCategory) {
            return;
        }

        try {
            const questionData = {
                text: newQuestionText.trim(),
                userId: userId,
                questionCategoryId: selectedCategory
            };

            await eventsApi.saveQuestion(questionData);
            
            // Dialog'u kapat ve form'u temizle
            setNewQuestionText('');
            setNewQuestionDialogOpen(false);
            
            // Kategori sayfasına geri dön
            setSelectedCategory(null);
            setQuestions([]);
            
            // Kategori listesini ve soru sayılarını güncelle
            const categoriesResponse = await eventsApi.getQuestionCategories() as ApiResponse<QuestionCategory>;
            setCategories(categoriesResponse.items || []);
            
            const allQuestionsResponse = await eventsApi.getQuestions() as ApiResponse<Question>;
            const counts = allQuestionsResponse.items.reduce((acc, question) => {
                acc[question.questionCategoryId] = (acc[question.questionCategoryId] || 0) + 1;
                return acc;
            }, {} as {[key: string]: number});
            setCategoryQuestionCounts(counts);
            
        } catch (error) {
            console.error('Soru eklenirken hata oluştu:', error);
        }
    };

    const renderContent = () => {
        if (loading) {
            return (
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center', 
                    minHeight: '200px',
                    backgroundColor: '#fff'
                }}>
                    <Typography>Sorular yükleniyor...</Typography>
                </Box>
            );
        }

        if (!selectedCategory) {
            return (
                <Box sx={{ flexGrow: 1, mb: 3 }}>
                    <Grid container spacing={2}>
                        {categories.map((category) => (
                            <Grid item xs={12} sm={6} md={3} key={category.id}>
                                <CategoryBox
                                    name={category.name}
                                    onClick={() => handleCategoryClick(category.id)}
                                    questionCount={categoryQuestionCounts[category.id] || 0}
                                    answeredCount={categoryAnsweredCounts[category.id] || 0}
                                />
                            </Grid>
                        ))}
                        <Grid item xs={12} sm={6} md={3}>
                            <AddCategoryBox onAddClick={() => setNewCategoryDialogOpen(true)} />
                        </Grid>
                    </Grid>
                </Box>
            );
        }
    
        return (
            <Box sx={{ mb: 3 }}>
                {questions.map((question, index) => (
                    <QuestionItem
                        key={question.id}
                        question={question}
                        index={index}
                        answer={answers[question.id] || {}}
                        onAnswerChange={handleAnswerChange}
                        onDescriptionChange={handleDescriptionChange}
                        onFindingsChange={handleFindingsChange}
                        onProposedSolutionsChange={handleProposedSolutionsChange}
                        onEditClick={handleEditClick}
                        onFileChange={handleFileChange}
                        onSaveEdit={handleSaveEdit}
                        isEditing={editingQuestionId === question.id}
                    />
                ))}
                
                <Box sx={{ mb: 4 }}>
                    <Typography 
                        variant="h6" 
                        sx={{ 
                            mb: 2, 
                            fontSize: '16px',
                            fontWeight: 500,
                            color: '#094151'
                        }}
                    >
                        Etkin Karşı Tedbirler Öncesi Risk ve Tehdit Seviyesi
                    </Typography>
                    <RiskTable 
                        data={riskTablesData[selectedCategory]?.table1 || getEmptyRiskTableData()}
                        onChange={(field, value) => handleRiskTableChange(selectedCategory, 1, field, value)}
                        backgroundColor="#bbdefb"
                        isLocked={!!riskAnswers[selectedCategory]?.beforeMeasures}
                        isEditing={editingRiskTable.categoryId === selectedCategory && !editingRiskTable.isAfterMeasures}
                        onEdit={() => handleRiskTableEdit(selectedCategory, false)}
                        onSave={() => handleRiskTableUpdate(selectedCategory, false)}
                        isAfterMeasures={false}
                    />
                    
                    <Typography 
                        variant="h6" 
                        sx={{ 
                            mt: 4,
                            mb: 2, 
                            fontSize: '16px',
                            fontWeight: 500,
                            color: '#094151'
                        }}
                    >
                        Etkin Karşı Tedbirler Sonrası Risk ve Tehdit Seviyesi
                    </Typography>
                    <RiskTable 
                        data={riskTablesData[selectedCategory]?.table2 || getEmptyRiskTableData()}
                        onChange={(field, value) => handleRiskTableChange(selectedCategory, 2, field, value)}
                        backgroundColor="#c8e6c9"
                        isLocked={!!riskAnswers[selectedCategory]?.afterMeasures}
                        isEditing={editingRiskTable.categoryId === selectedCategory && editingRiskTable.isAfterMeasures}
                        onEdit={() => handleRiskTableEdit(selectedCategory, true)}
                        onSave={() => handleRiskTableUpdate(selectedCategory, true)}
                        isAfterMeasures={true}
                    />
                </Box>
                
                <AddQuestionBox onAddClick={() => setNewQuestionDialogOpen(true)} />

                {/* New Question Dialog */}
                <Dialog open={newQuestionDialogOpen} onClose={() => setNewQuestionDialogOpen(false)}>
                    <DialogTitle>Yeni Soru Ekle</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Soru"
                            fullWidth
                            multiline
                            rows={3}
                            value={newQuestionText}
                            onChange={(e) => setNewQuestionText(e.target.value)}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setNewQuestionDialogOpen(false)}>İptal</Button>
                        <Button onClick={handleAddQuestion} variant="contained">Ekle</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        );
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="lg"
            fullWidth
            PaperProps={{
                sx: { maxHeight: '90vh' }
            }}
        >
            <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {selectedCategory && (
                    <IconButton onClick={handleBackClick} size="small">
                        <ArrowBackIcon />
                    </IconButton>
                )}
                {selectedCategory ? selectedCategoryName : 'RİSK ANALİZ FORMU'}
            </DialogTitle>
            <DialogContent>
                <Typography sx={{ fontSize: "12px", marginBottom: "24px" }}>
                    {getInfoText()}
                </Typography>
                {renderContent()}
            </DialogContent>

            {/* Yeni Kategori Dialog'u ekleyelim */}
            <Dialog 
                open={newCategoryDialogOpen} 
                onClose={() => setNewCategoryDialogOpen(false)}
            >
                <DialogTitle>Yeni Kategori Ekle</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Kategori Adı"
                        fullWidth
                        value={newCategoryName}
                        onChange={(e) => setNewCategoryName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setNewCategoryDialogOpen(false)}>
                        İptal
                    </Button>
                    <Button 
                        onClick={handleAddCategory}
                        variant="contained"
                        sx={{
                            backgroundColor: '#094151',
                            '&:hover': {
                                backgroundColor: '#022631'
                            }
                        }}
                    >
                        Ekle
                    </Button>
                </DialogActions>
            </Dialog>

            <DialogActions sx={{justifyContent: 'flex-end', display: 'flex', pr:5}}>
                {selectedCategory && (
                    <Button
                        variant="contained"
                        onClick={handleSave}
                        sx={{width:'120px' ,backgroundColor: '#094151', color:"#fff",
                            '&:hover': {
                                backgroundColor: '#022631', color:"#fff"
                            } }}
                    >
                        Kaydet
                    </Button>
                )}
                {!selectedCategory && (
                    <Button onClick={onClose}
                        sx={{width:'220px', backgroundColor: '#094151', color:"#fff",
                            borderWidth: '2px',
                            '&:hover': {
                                backgroundColor: '#022631', color:"#fff", fontWeight: 600
                            }
                        }}>Lokasyon Sayfasına Dön</Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default memo(ChecklistModal);