import ApiBase from '../apiBase';

const pageSize = 100000;

export interface PinData {
	Name: string;
	Latitude: number;
	Longitude: number;
	RadiusInMeters: number;
	Sector: string;
	userId: string;
	File?: File;
}

interface PinResponse {
	id: string;
	userId: string;
	name: string;
	latitude: number;
	longitude: number;
	radiusInMeters: number;
	locationCount: number;
	sector: string;
	reportDescription: string | null;
	districtDistributionJson: string;
}

interface QuestionAnswer {
	questionId: string;
	userId: string;
	yesNo: boolean;
	findings: string;
	pinId: string;
	proposedSolutions: string;
	description: string;
	files?: File[];
}

interface QuestionCategoryCreate {
	name: string;
	userId: string;
	questionTypeId: string;
}

interface QuestionCreate {
	text: string;
	userId: string;
	questionCategoryId: string;
}

interface PinInfo {
	Id: string;
	UserId: string;
	Name: string;
	Latitude: number;
	Longitude: number;
	RadiusInMeters: number;
	LocationCount: number;
	Sector: string;
	ReportDescription: string | null;
	DistrictDistributionJson: string;
	QuestionsJson: {
		wikipediaTitle: string;
		wikipediaSummary: string;
	};
	JsonContentStream: any;
	Locations: any[];
}

interface PinEventsResponse {
	locations: {
		locationId: string;
		district1: string;
		events: {
			id: string;
			date: string;
			eventType: string;
			description: string;
		}[];
	}[];
}

interface QuestionAnswerUpdate extends QuestionAnswer {
	id: string;
}

interface CategoryRiskAnswer {
	questionCategoryId: string;
	pinId: string;
	userId: string;
	probabilityLevel: number;
	impactLevel: number;
	threatLevel: number;
	appliedMeasureCoefficient: number;
	residualRiskLevel: number;
	isAfterMeasures: boolean;
}

interface CategoryRiskAnswerUpdate extends CategoryRiskAnswer {
	id: string;
}

interface CategoryRiskAnswerResponse extends CategoryRiskAnswerUpdate {
	// Eğer API'den dönen başka alanlar varsa buraya eklenebilir
}

interface TranscriptionResponse {
	transcription: string;
}

interface PinReportResponse {
	pinId: string;
	reportDescription: string;
	filteredEventCount: number;
	filteredLocationCount: number;
}

interface PinReportData {
	pinInfo: PinInfo;
	questionAnswers: {
		answer: any; // QuestionAnswer tipi
		categoryName: string;
	}[];
	riskAnswers: CategoryRiskAnswerResponse[];
}

interface QuestionCategory {
	id: string;
	name: string;
	questions: {
		id: string;
		text: string;
		questionCategoryId: string;
	}[];
}

interface PinQuestionAnswer {
	id: string;
	questionId: string;
	questionText: string;
	description: string;
	findings: string;
	proposedSolutions: string;
	questionCategoryName: string;
}

interface PinCategoryRiskAnswer {
	id: string;
	probabilityLevel: number;
	impactLevel: number;
	threatLevel: number;
	appliedMeasureCoefficient: number;
	residualRiskLevel: number;
	isAfterMeasures: boolean;
	categoryName: string;
	questionTypeName: string;
}

interface PinQuestionsResponse {
	pin: {
		id: string;
		name: string;
		latitude: number;
		longitude: number;
		radiusInMeters: number;
		sector: string;
		reportDescription: string;
		fileAttachmentIds: string[];
		questionAnswers: {
			id: string;
			questionId: string;
			questionText: string;
			questionCategoryName: string;
			description: string;
			findings: string;
			proposedSolutions: string;
			fileAttachmentIds: string[];
		}[];
		categoryRiskAnswers: PinCategoryRiskAnswer[];
	}
}

interface PinFullReportData {
	pinInfo: {
		Name: string;
		Sector: string;
		Latitude: number;
		Longitude: number;
		RadiusInMeters: number;
		reportDescription: string | null;
		fileAttachmentIds: string[];
	};
	questionAnswers: {
		answer: {
			id: string;
			questionId: string;
			findings: string;
			proposedSolutions: string;
			description: string;
			questionCategoryName: string;
			fileAttachmentIds: string[];
		};
	}[];
	riskAnswers: {
		id: string;
		probabilityLevel: number;
		impactLevel: number;
		threatLevel: number;
		appliedMeasureCoefficient: number;
		residualRiskLevel: number;
		isAfterMeasures: boolean;
		categoryName: string;
		questionTypeName: string;
	}[];
}

const getPinReport = async (pinId: string): Promise<PinReportResponse> => {
	const endDate = new Date().toISOString().split('T')[0];
	const startDate = new Date();
	startDate.setMonth(startDate.getMonth() - 1);
	const startDateStr = startDate.toISOString().split('T')[0];

	return ApiBase.postReportPromise<PinReportResponse>(
		`/api/Pins/${pinId}/createReport?startDate=${startDateStr}&endDate=${endDate}`
	);
};

const getPinFullReport = async (pinId: string): Promise<PinFullReportData> => {
	try {
		const response = (await ApiBase.getPromise<PinQuestionsResponse>(`/api/Pins/Questions?pinId=${pinId}`)) as PinQuestionsResponse;
		const pin = response.pin;

		return {
			pinInfo: {
				Name: pin.name,
				Sector: pin.sector,
				Latitude: pin.latitude,
				Longitude: pin.longitude,
				RadiusInMeters: pin.radiusInMeters,
				reportDescription: pin.reportDescription,
				fileAttachmentIds: pin.fileAttachmentIds
			},
			questionAnswers: pin.questionAnswers.map(qa => ({
				answer: {
					id: qa.id,
					questionId: qa.questionId,
					findings: qa.findings,
					proposedSolutions: qa.proposedSolutions,
					description: qa.description,
					questionCategoryName: qa.questionCategoryName,
					fileAttachmentIds: qa.fileAttachmentIds
				}
			})),
			riskAnswers: pin.categoryRiskAnswers.map(ra => ({
				id: ra.id,
				probabilityLevel: ra.probabilityLevel,
				impactLevel: ra.impactLevel,
				threatLevel: ra.threatLevel,
				appliedMeasureCoefficient: ra.appliedMeasureCoefficient,
				residualRiskLevel: ra.residualRiskLevel,
				isAfterMeasures: ra.isAfterMeasures,
				categoryName: ra.categoryName,
				questionTypeName: ra.questionTypeName
			}))
		};
	} catch (error) {
		throw error;
	}
};

const getFileAttachment = async (fileAttachmentId: string): Promise<Blob> => {
	try {
		return await ApiBase.getBlobPromise(
			`/api/FileAttachments/${fileAttachmentId}/download`
		);
	} catch (error) {
		throw error;
	}
};

const EventsApi = {

	getEvents(params?: any) {
		return ApiBase.getPromise('/api/Events', { ...params, pageSize });
	},
	getEventsCounts(params?: any) {
		return ApiBase.getPromise('/api/Events', { ...params });
	},
	getPins() {
		return ApiBase.getPromise('/api/Pins', {pageSize});
	},
	getLocations() {
		return ApiBase.getPromise('/api/Locations', {pageSize});
	},
	getActorCategories() {
		return ApiBase.getPromise('/api/ActorCategories', {pageSize});
	},
	getEventTypes() {
		return ApiBase.getPromise('/api/EventTypes', {pageSize});
	},
	createNewPin(pinData: PinData | FormData): Promise<PinResponse> {
		return ApiBase.postPromise<PinResponse>('/api/Pins/UploadQuestionsJson', pinData);
	},
	deletePin(pinId: string) {
		return ApiBase.deletePromise(`/api/Pins/${pinId}`, null);
	},
	getDistrictBounds() {
		return fetch('https://inttechparser.blob.core.windows.net/citydistrictboundaries/simplified_ilce_sinirlari.json')
			.then((response) => response.json())
			.catch((error) => {
				console.error(error);
			});
	},
	getQuestions(categoryId?: string) {
		const url = categoryId 
			? `/api/Questions?categoryId=${categoryId}`
			: '/api/Questions';
		return ApiBase.getPromise(url, { pageSize: 500 });
	},
	getQuestionCategories() {
		return ApiBase.getPromise('/api/QuestionCategories', { pageSize: 500 });
	},
	saveQuestionAnswers(answer: QuestionAnswer) {
		const formData = new FormData();
		formData.append('QuestionId', answer.questionId || '');
		formData.append('UserId', answer.userId || '');
		formData.append('YesNo', answer.yesNo ? 'true' : 'false');
		formData.append('Findings', answer.findings || '');
		formData.append('PinId', answer.pinId || '');
		formData.append('ProposedSolutions', answer.proposedSolutions || '');
		formData.append('Description', answer.description || '');
		
		if (answer.files && answer.files.length > 0) {
			answer.files.forEach((file, index) => {
				formData.append(`Files`, file);
			});
		}

		return ApiBase.postPromise('/api/QuestionAnswers', formData);
	},
	saveQuestionCategory(categoryData: QuestionCategoryCreate) {
		return ApiBase.postQuestionPromise('/api/QuestionCategories', categoryData);
	},
	saveQuestion(questionData: QuestionCreate) {
		return ApiBase.postQuestionPromise('/api/Questions', questionData);
	},
	getQuestionAnswers(pinId: string) {
		return ApiBase.getPromise(`/api/QuestionAnswers`, { pageSize: 500 });
	},
	getPinInfo(pinId: string): Promise<PinInfo> {
		return ApiBase.getPromise(`/api/Pins/${pinId}`) as Promise<PinInfo>;
	},
	getPinEvents(pinId: string, startDate: string, endDate: string): Promise<PinEventsResponse> {
		return ApiBase.getPromise(
			`/api/Pins/${pinId}/info?startDate=${startDate}&endDate=${endDate}`
		) as Promise<PinEventsResponse>;
	},
	updateQuestionAnswer(answer: QuestionAnswerUpdate) {
		const formData = new FormData();
		formData.append('QuestionId', answer.questionId || '');
		formData.append('UserId', answer.userId || '');
		formData.append('YesNo', answer.yesNo ? 'true' : 'false');
		formData.append('Findings', answer.findings || '');
		formData.append('PinId', answer.pinId || '');
		formData.append('ProposedSolutions', answer.proposedSolutions || '');
		formData.append('Id', answer.id || '');
		formData.append('Description', answer.description || '');

		if (answer.files && answer.files.length > 0) {
			answer.files.forEach(file => {
				formData.append('Files', file);
			});
		}

		return ApiBase.putPromise('/api/QuestionAnswers', formData);
	},
	saveCategoryRiskAnswers(data: CategoryRiskAnswer) {
		return ApiBase.postQuestionPromise('/api/CategoryRiskAnswers', data);
	},
	updateCategoryRiskAnswers(data: CategoryRiskAnswerUpdate) {
		return ApiBase.putCategoryRiskAnswerPromise('/api/CategoryRiskAnswers', data);
	},
	getCategoryRiskAnswers(pinId: string) {
		return ApiBase.getPromise(`/api/CategoryRiskAnswers?pinId=${pinId}`, { pageSize: 500 });
	},
	getSpeechToText(audioBlob: Blob) {
		const formData = new FormData();
		formData.append('audioFile', audioBlob, 'audio.webm');

		return ApiBase.postPromise<TranscriptionResponse>('/api/SpeechToText/transcribe', formData);
	},
	getPinReport,
	getPinFullReport,
	updatePin(pinId: string, pinData: PinData) {
		const formData = new FormData();
		
		// Add required fields
		formData.append('Id', pinId);
		formData.append('UserId', pinData.userId);

		// Add file if it exists
		if (pinData.File) {
			formData.append('Files', pinData.File);
		}

		return ApiBase.putPromise('/api/Pins', formData);
	},
	getFileAttachment,
};

export default EventsApi;
