import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Typography,
    Box,
    Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export interface EventItem {
    latitude: number;
    longitude: number;
    eventTypeId: string;
    description?: string;
    date?: string;
}

interface EventTypeInfo {
    id: string;
    name: string;
    color: string;
}

interface EventPinModalProps {
    open: boolean;
    onClose: () => void;
    events: EventItem[];
    coordinates: {
        latitude: number;
        longitude: number;
    };
}

const eventTypes: { [key: string]: EventTypeInfo } = {
    'cf5f045f-a360-9c6f-5566-757fd856878a': { id: 'cf5f045f-a360-9c6f-5566-757fd856878a', name: 'Terörizm (TE)', color: '#EC8E5B' },
    '450b0d5e-6639-3649-b055-45ad25dc1e72': { id: '450b0d5e-6639-3649-b055-45ad25dc1e72', name: 'Örgütlü Suçlar (OS)', color: '#F35E5E' },
    'ff4ee709-9d4d-3b66-e0c7-09456d55d54d': { id: 'ff4ee709-9d4d-3b66-e0c7-09456d55d54d', name: 'Sosyal ve Politik İstikrarsızlık (SP)', color: '#67C086' },
    '0d2afe18-19b2-d63a-5d3e-6f92105b778b': { id: '0d2afe18-19b2-d63a-5d3e-6f92105b778b', name: 'Çevresel ve Doğal Afetler (ÇA)', color: '#689D7B' },
    '2996d900-08f7-fe3b-624e-685d3e0230f8': { id: '2996d900-08f7-fe3b-624e-685d3e0230f8', name: 'Bilgi Güvenliği ve Enformasyon Tehditleri (BG)', color: '#63ABC3' },
    '0a6d93e8-135c-d061-cac2-e9ff390567c8': { id: '0a6d93e8-135c-d061-cac2-e9ff390567c8', name: 'Bireysel ve Adi Suçlar (BA)', color: '#4995DB' },
    'fa04c441-2bc2-3c4a-7f77-d59738f05372': { id: 'fa04c441-2bc2-3c4a-7f77-d59738f05372', name: 'Göç ve Mülteci Krizleri (GM)', color: '#8e6c58' },
    '415de057-31d6-87ab-00c2-9d038fc4a7d2': { id: '415de057-31d6-87ab-00c2-9d038fc4a7d2', name: 'Sağlık ve Biyolojik Tehditler (SB)', color: '#265454' },
    'fb7cdcca-889e-f136-64ed-351dee94cebf': { id: 'fb7cdcca-889e-f136-64ed-351dee94cebf', name: 'Yasal ve Düzenleyici Uyumsuzluklar (YD)', color: '#0f223a' },
    '2567eb67-43ad-8075-07ce-8b95a5c59305': { id: '2567eb67-43ad-8075-07ce-8b95a5c59305', name: 'Ekonomik ve Finansal Tehditler (EF)', color: '#4a3b39' },
};

const EventPinModal: React.FC<EventPinModalProps> = ({ open, onClose, events, coordinates }) => {
    // Olayları türlerine göre grupla
    const groupedEvents = events.reduce((acc, event) => {
        const eventType = eventTypes[event.eventTypeId];
        if (!acc[event.eventTypeId]) {
            acc[event.eventTypeId] = {
                type: eventType,
                events: []
            };
        }
        acc[event.eventTypeId].events.push(event);
        return acc;
    }, {} as { [key: string]: { type: EventTypeInfo, events: EventItem[] } });

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            fullWidth
            sx={{
                '& .MuiDialog-paper': {
                    borderRadius: '12px',
                }
            }}
        >
            <DialogTitle sx={{ m: 0, p: 2 }}>
                <Typography variant="h6" component="div">
                    Olay Detayları
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                    Koordinatlar: {coordinates.latitude.toFixed(6)}, {coordinates.longitude.toFixed(6)}
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers sx={{ p: 3 }}>
                {Object.entries(groupedEvents).map(([eventTypeId, { type, events }]) => (
                    <Box key={eventTypeId} sx={{ mb: 3 }}>
                        {/* Olay tipi başlığı */}
                        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                            <Box
                                sx={{
                                    width: 16,
                                    height: 16,
                                    borderRadius: 1,
                                    backgroundColor: type.color,
                                    mr: 2
                                }}
                            />
                            <Typography variant="h6">
                                {type.name} ({events.length})
                            </Typography>
                        </Box>

                        {/* Olayların listesi */}
                        <Box sx={{ pl: 4 }}>
                            {events.map((event, index) => (
                                <Box key={index} sx={{ mb: 2 }}>
                                    {event.description && (
                                        <Box sx={{
                                            p: 2,
                                            bgcolor: 'grey.50',
                                            borderRadius: 1,
                                            mb: 1
                                        }}>
                                            <Typography variant="body1">
                                                {event.description}
                                            </Typography>
                                        </Box>
                                    )}
                                    {event.date && (
                                        <Typography variant="body2" color="text.secondary">
                                            Tarih: {new Date(event.date).toLocaleDateString('tr-TR')}
                                        </Typography>
                                    )}
                                    {index < events.length - 1 && <Divider sx={{ my: 1 }} />}
                                </Box>
                            ))}
                        </Box>
                        <Divider sx={{ mt: 2 }} />
                    </Box>
                ))}
            </DialogContent>
        </Dialog>
    );
};

export default EventPinModal;