import {LineChart} from '@mui/x-charts/LineChart';
import React, {useEffect, useRef, useState} from 'react';
import _ from 'lodash';
import {
	Box,
	Card,
	CardContent,
	CardHeader,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	Typography,
	useMediaQuery
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {GaugeContainer, GaugeReferenceArc, GaugeValueArc, useGaugeState,} from '@mui/x-charts/Gauge';
import eventsApi from "../../../api/eventsApi/eventsApi";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Tooltip from '@mui/material/Tooltip';

interface DistrictModalProps {
	open: boolean;
	onClose: () => void;
	districtName: string;
	filters: {
		dateStart: string;
		dateEnd: string;
		city?: string;
		eventTypeId?: string;
	};
}

interface EventsResponse {
	events: {
		items: any[];
		cityEventCounts?: any[];
		districtEventCounts?: any[];
		count?: number;
	};
	overallThreatLevel?: number;
	overallLogThreatLevel?: number;
}

const GaugePointer = () => {
	const {valueAngle, outerRadius, cx, cy} = useGaugeState();
	if (valueAngle === null) return null;

	const target = {
		x: cx + outerRadius * Math.sin(valueAngle),
		y: cy - outerRadius * Math.cos(valueAngle),
	};

	return (
		<g>
			<circle cx={cx} cy={cy} r={5} fill="#3F51B5"/>
			<path
				d={`M ${cx} ${cy} L ${target.x} ${target.y}`}
				stroke="#3F51B5"
				strokeWidth={3}
			/>
		</g>
	);
};

const DistrictModal: React.FC<DistrictModalProps> = ({open, onClose, districtName, filters}) => {
	const [weeklyData, setWeeklyData] = useState<number[]>([]);
	const [currentWeekData, setCurrentWeekData] = useState<number | null>(null);
	const [forecast, setForecast] = useState<number | null>(null);
	const [weekLabels, setWeekLabels] = useState<string[]>([]);
	const [threatLevel, setThreatLevel] = useState<number | null>(null);
	const [logThreatLevel, setlogThreatLevel] = useState<number | null>(null);
	const cachedData = useRef<{ [key: string]: any }>({});
	const [isLoading, setIsLoading] = useState(true);
	const controllerRef = useRef<AbortController | null>(null);

	const isLargeScreen = useMediaQuery('(min-width:1200px)');

	useEffect(() => {
		cachedData.current = {};
	}, [filters.city, filters.eventTypeId, districtName]);

	useEffect(() => {
		if (!open) return;

		// Create new controller for this effect instance
		controllerRef.current = new AbortController();

		const getEventData = async () => {
			setIsLoading(true);
			
			const cacheKey = `${districtName}-${filters.dateStart}-${filters.dateEnd}-${filters.city}-${filters.eventTypeId}`;

			if (cachedData.current[cacheKey]) {
				const cachedResult = cachedData.current[cacheKey];
				setWeeklyData(cachedResult.weeklyData);
				setCurrentWeekData(cachedResult.currentWeekData);
				setForecast(cachedResult.forecast);
				setWeekLabels(cachedResult.weekLabels);
				setThreatLevel(cachedResult.threatLevel);
				setlogThreatLevel(cachedResult.logThreatLevel);
				setIsLoading(false);
				return;
			}

			try {
				const params: any = {
					dateStart: filters.dateStart,
					dateEnd: filters.dateEnd,
				};

				if (districtName) {
					params.district = districtName;
				} else if (filters.city) {
					params.city = filters.city;
				}

				if (filters.eventTypeId) {
					params.eventTypeId = filters.eventTypeId;
				}

				const response = await eventsApi.getEventsCounts(params) as EventsResponse;

				// Şehir seçiliyse ve ilçe seçili değilse, cityEventCounts'dan threat level'ları alalım
				let currentThreatLevel = 0;
				let currentLogThreatLevel = 0;

				if (!districtName && filters.city && response.events?.cityEventCounts) {
					const cityData = response.events.cityEventCounts.find(
						(city: any) => city.city === filters.city
					);
					currentThreatLevel = cityData?.threatLevel || 0;
					currentLogThreatLevel = cityData?.logThreatLevel || 0;
				} else {
					// İlçe seçiliyse veya diğer durumlar için
					currentThreatLevel = response.overallThreatLevel || 0;
					currentLogThreatLevel = response.overallLogThreatLevel || 0;
				}

				// Haftalık veriler için hala aynı hesaplamayı yapabiliriz
				const dateEnd = new Date(filters.dateEnd);
				const mondayOfDateEnd = new Date(dateEnd);
				mondayOfDateEnd.setDate(dateEnd.getDate() - dateEnd.getDay() + 1);

				const weeks = Array.from({length: 5}, (_, i) => {
					const start = new Date(mondayOfDateEnd);
					start.setDate(start.getDate() - (4 - i) * 7);
					const end = new Date(start);
					end.setDate(end.getDate() + 6);
					return {
						start,
						end,
						label: `${start.toLocaleDateString('tr-TR', {day: '2-digit', month: 'long'})} Haftası`,
						isCurrent: i === 4
					};
				});

				// Haftalık veriler için ayrı çağrılar
				const weeklyPromises = weeks.map(({start, end}) => {
					return eventsApi.getEventsCounts({
						...params,
						dateStart: start.toISOString(),
						dateEnd: end.toISOString(),
					});
				});

				const weeklyResults = await Promise.all(weeklyPromises) as EventsResponse[];

				const allCounts = weeklyResults.map((res) => res.events?.count || 0);
				const weeklyDataValue = allCounts.slice(0, -1);
				const currentWeekDataValue = allCounts[allCounts.length - 1];

				const alpha = 0.3;
				const forecastValue = Math.round(
					allCounts.slice(0, -1).reduce(
						(acc, count) => alpha * count + (1 - alpha) * acc,
						allCounts[0] || 0
					)
				);

				const weekLabelsValue = weeks.map(week => week.label);

				const cacheData = {
					weeklyData: weeklyDataValue,
					currentWeekData: currentWeekDataValue,
					forecast: forecastValue,
					weekLabels: weekLabelsValue,
					threatLevel: currentThreatLevel,
					logThreatLevel: currentLogThreatLevel
				};
				

				cachedData.current[cacheKey] = cacheData;

				setWeeklyData(weeklyDataValue);
				setCurrentWeekData(currentWeekDataValue);
				setForecast(forecastValue);
				setWeekLabels(weekLabelsValue);
				setThreatLevel(currentThreatLevel);
				setlogThreatLevel(currentLogThreatLevel);

			} catch (error) {
				if (error instanceof Error) {
					if (error.name !== 'AbortError') {
						console.error("🔥 Data fetching error:", error.message);
					}
				} else {
					console.error("💥 An unknown error occurred");
				}
			} finally {
				setIsLoading(false);
			}
		};

		getEventData();

		return () => {
			// Abort any ongoing requests when cleaning up
			if (controllerRef.current) {
				controllerRef.current.abort();
			}
		};
	}, [open, districtName, filters.dateStart, filters.dateEnd, filters.city, filters.eventTypeId]);

	const renderGauge = () => {
		return (
			<Box sx={{ position: 'relative', width: 200, height: 200 }}>
				<GaugeContainer
					width={200}
					height={200}
					startAngle={-90}
					endAngle={90}
					value={logThreatLevel ?? 0}
					valueMin={0}
					valueMax={25}
					sx={() => ({
						[`& .ref-arc`]: {
							fill: "#b3b3b3",
						},
						[`& .value-arc`]: {
							fill: logThreatLevel ? (
								logThreatLevel > 12 ? "#FF0000" :  // Kritik - Kırmızı
								logThreatLevel > 6 ? "#FFA500" :  // Yüksek - Turuncu
								logThreatLevel > 3 ? "#FFFF00" :   // Orta - Sarı
								"#eee1de"                            // Düşük - Beyaz
							) : "#eee1de"
						},
					})}
				>
					<GaugeReferenceArc className="ref-arc" stroke="#e7e7e7" strokeWidth={6}/>
					<GaugeValueArc className="value-arc"  stroke="#e7e7e7" strokeWidth={6}/>
					<GaugePointer />
					<text
						x="100"
						y="180"
						textAnchor="middle"
						fontSize="16"
						fill="#666"
					>
						{logThreatLevel ?? 0}
					</text>
				</GaugeContainer>
			</Box>
		);
	};

	const renderContent = () => (
		<Box sx={{width: '100%'}}>
			{isLoading ? (
				<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
					<Typography>Veriler yükleniyor...</Typography>
				</Box>
			) : weeklyData.length > 0 ? (
				<Grid container direction="column" spacing={2}>
					<Grid item xs={12}>
						<Card elevation={2}
						      sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
							<CardHeader
								title={
									<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
										<Typography variant="h6" component="span">Risk Seviyesi</Typography>
										<Tooltip
											title={
												<Box sx={{ p: 1 }}>
													<Typography variant="body2" paragraph>
														Risk hesaplama sistemi nasıl çalışıyor?
													</Typography>
													<Typography variant="body2" paragraph>
														Risk göstergesi, bir olayın oluşturabileceği potansiyel zarar ve ihtimali görselleştirerek risk seviyelerini anlamanızı kolaylaştırır. Hesaplama, aşağıdaki formüle dayanır:
														R = P × I
													</Typography>
													<Typography variant="body2" paragraph>
														R: Genel risk seviyesi. Olayın tehlike derecesini ifade eder.
													</Typography>
													<Typography variant="body2" paragraph>
														P: Olayın gerçekleşme olasılığı (1-5 arasında normalize edilmiş bir değer).
													</Typography>
													<Typography variant="body2" paragraph>
														I: Etki katsayısı (1-5), olayın doğrudan veya dolaylı etkisini temsil eder.
													</Typography>
													<Typography variant="body2" paragraph>
														Bir olayın gerçekleşme olasılığı, olay sayılarının logaritmik dağılımı üzerinden hesaplanır. Bu yöntem, düşük frekanslı olayların etkisini daha hassas bir şekilde ölçerken, yüksek frekanslı olayların etkisini normalize eder. Böylece, analiz süreçleri daha tutarlı ve veri odaklı bir şekilde yürütülür.
													</Typography>
													<Typography variant="body2" paragraph>
														Riske göre belirlenen renk skalası ise şu şekildedir:
													</Typography>
													<Typography variant="body2">
														Beyaz: Düşük risk (R {'<'} 3)
													</Typography>
													<Typography variant="body2">
														Sarı: Orta risk (3 ≤ R ≤ 10)
													</Typography>
													<Typography variant="body2">
														Turuncu: Yüksek risk (10 {'<'} R ≤ 20)
													</Typography>
													<Typography variant="body2">
														Kırmızı: Kritik risk (R ≥ 20)
													</Typography>
												</Box>
											}
											sx={{
												'& .MuiTooltip-tooltip': {
													maxWidth: 400,
												}
											}}
										>
											<HelpOutlineIcon 
												sx={{ 
													opacity: 0.6,
													cursor: 'help',
													fontSize: '1.2rem'
												}} 
											/>
										</Tooltip>
									</Box>
								}
								sx={{pb: 0, p: 1}}
							/>
							<CardContent sx={{display: 'flex', justifyContent: 'center', p: '8px !important'}}>
								{renderGauge()}
							</CardContent>
						</Card>
					</Grid>

					<Grid item xs={12}>
						<Card elevation={2}
						      sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
							<CardHeader
								title={
									<Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
										<Typography variant="h6" component="span">Haftalık Trend ve Tahmin</Typography>
										<Tooltip
											title={
												<Box sx={{ p: 1 }}>
													<Typography variant="body2" paragraph>
														Bu Grafik Ne İfade Ediyor?
													</Typography>
													<Typography variant="body2" paragraph>
														Öngörü (Forecast) Grafiği, yapay zeka modelimizin, geçmiş verileri analiz ederek bir sonraki hafta gerçekleşebilecek olay sayısına dair tahminlerini görselleştirir.
													</Typography>
													<Typography variant="body2" paragraph>
														Ekseni:
													</Typography>
													<Typography variant="body2" paragraph>
														Yatay: Haftalık zaman dilimleri.
													</Typography>
													<Typography variant="body2" paragraph>
														Dikey: Gerçekleşmiş veya öngörülmüş toplam olay sayısı.
													</Typography>
													<Typography variant="body2" paragraph>
														Çizgiler:
													</Typography>
													<Typography variant="body2" paragraph>
														Mavi: Geçmişteki olay sayısı (veri seti).
													</Typography>
													<Typography variant="body2">
														Kırmızı: Gelecek hafta tahmini olay sayısı.
													</Typography>
													<Typography variant="body2" sx={{ mt: 1 }}>
														Bu grafik, geçmiş ve geleceği ilişkilendirerek veri odaklı analiz ve planlama sağlar.
													</Typography>
												</Box>
											}
											sx={{
												'& .MuiTooltip-tooltip': {
													maxWidth: 400,
												}
											}}
										>
											<HelpOutlineIcon 
												sx={{ 
													opacity: 0.6,
													cursor: 'help',
													fontSize: '1.2rem'
												}} 
											/>
										</Tooltip>
									</Box>
								}
								sx={{pb: 0, p: 1}}
							/>
							<CardContent sx={{p: '8px !important'}}>
								<Box sx={{width: '100%'}}>
									<Box sx={{display: 'flex', justifyContent: 'center', gap: 2, mb: 1}}>
										<Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
											<Box sx={{width: 12, height: 12, bgcolor: '#3F51B5', borderRadius: '50%'}}/>
											<Typography sx={{fontSize: '0.75rem'}}>Olay Sayıları</Typography>
										</Box>
										<Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
											<Box sx={{width: 12, height: 12, bgcolor: '#FF6B6B', borderRadius: '50%'}}/>
											<Typography sx={{fontSize: '0.75rem'}}>Tahmin</Typography>
										</Box>
									</Box>
									<LineChart
										xAxis={[{
											data: weekLabels,
											scaleType: 'band',
											tickLabelStyle: {
												angle: 45,
												textAnchor: 'start',
												fontSize: 10
											},
										}]}
										series={[
											{
												data: [...Array(3).fill(null), weeklyData[weeklyData.length - 1], forecast],
												curve: 'linear',
												color: '#FF6B6B',
												showMark: true,
												connectNulls: true,
												id: 'forecast',
												valueFormatter: (value, context) => {
													return context.dataIndex === 4 ? `Tahmin: ${value}` : null
												}
											},
											{
												data: [...weeklyData],
												curve: 'linear',
												showMark: true,
												color: '#3F51B5',
												id: 'weekly',
												valueFormatter: (value) => value ? `Olay Sayısı: ${value}` : null
											}
										]}
										width={isLargeScreen ? 280 : 180}
										height={220}
										margin={{left: 40, right: 40, top: 16, bottom: 80}}
										sx={{
											'.MuiLineElement-series-forecast': {
												strokeDasharray: '5 5',
												strokeWidth: 2,
											},
											'.MuiLineElement-series-weekly': {
												strokeWidth: 2,
											},
											'.MuiMarkElement-root': {
												strokeWidth: 2,
												r: 4,
											}
										}}
										slotProps={{
											legend: {
												hidden: true
											}
										}}
									/>
								</Box>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
			) : (
				<Typography>Veri bulunamadı</Typography>
			)}
		</Box>
	);

	// İlçe seçili ise modal olarak göster
	if (districtName) {
		return (
			<Dialog
				open={open}
				onClose={onClose}
				maxWidth="md"
				fullWidth
				sx={{
					'& .MuiDialog-paper': {
						borderRadius: '12px',
					}
				}}
			>
				<DialogTitle sx={{m: 0, p: 2}}>
					<Typography variant="h6" component="div">
						{districtName}
					</Typography>
					<IconButton
						aria-label="close"
						onClick={onClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon/>
					</IconButton>
				</DialogTitle>
				<DialogContent dividers>
					{renderContent()}
				</DialogContent>
			</Dialog>
		);
	}

	// İlçe seçili değilse normal div olarak göster
	return renderContent();
};

export default DistrictModal;