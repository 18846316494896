import axios, {AxiosResponse, InternalAxiosRequestConfig} from 'axios';
import store from "../redux/store";
import {startLoading, stopLoading} from "../redux/reducers/loadingReducer";
import Links from "../constants/Links";

const baseUrl = Links.BASE_URL_WITHOUT_TENANT;
let accessTokenId = -1;
const ApiBase = {
	login(credentials: any) {
		return ApiBase.postLoginPromise('/api/Auth/Login', credentials).then((resp: any) => {
			return resp;
		});
	},

	putAccessTokenInfo(accessToken: any) {
		accessTokenId !== -1 && axios.interceptors.request.eject(accessTokenId);
		accessTokenId = axios.interceptors.request.use(
			(config: any) => {
				config.headers['Authorization'] = accessToken ? `Bearer ${accessToken}` : undefined;
				return config;
			},
			function (error: Error) {
				return Promise.reject(error);
			},
		);
	},

	getPromise<T>(endpoint: string, params?: object | undefined) {
		return new Promise((resolve, reject) => {
			axios
				.get(baseUrl + endpoint, {params})
				.then((response: AxiosResponse) => resolve(response.data as T))
				.catch((error: Error) => reject(error));
		});
	},

	getPromiseWithParams(endpoint: string, params: any) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'GET',
				params: params,
				url: baseUrl + endpoint,
			})
				.then((response: AxiosResponse) => resolve(response.data))
				.catch((error: Error) => reject(error));
		});
	},

	postLoginPromise(endpoint: string, data: any) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'POST',
				data: data,
				url: baseUrl + endpoint,
			})
				.then((response: AxiosResponse) => resolve(response.data))
				.catch((error: Error) => reject(error));
		});
	},

	postPromise<T>(endpoint: string, data: any): Promise<T> {
		// Form data oluştur
		let formData: FormData;
		
		// Eğer data bir FormData değilse, her bir property'i FormData'ya ekle
		if (!(data instanceof FormData)) {
			formData = new FormData();
			Object.keys(data).forEach(key => {
				formData.append(key, data[key].toString());
			});
		} else {
			// Eğer zaten FormData ise direkt kullan
			formData = data;
		}

		// CURL komutunu oluştur
		let curlCommand = `curl -X 'POST' \\
		'${baseUrl}${endpoint}' \\
		-H 'accept: */*' \\
		-H 'Content-Type: multipart/form-data'`;

		// FormData içeriğini CURL komutuna ekle
		formData.forEach((value, key) => {
			if (value instanceof File) {
				curlCommand += ` \\\n  -F '${key}=@${value.name}'`;
			} else {
				curlCommand += ` \\\n  -F '${key}=${value}'`;
			}
		});

		return new Promise((resolve, reject) => {
			axios({
				method: 'POST',
				data: formData,
				url: baseUrl + endpoint,
				headers: {
					'Content-Type': 'multipart/form-data',
					'accept': '*/*'
				},
			})
				.then((response: AxiosResponse) => resolve(response.data as T))
				.catch((error: Error) => reject(error));
		});
	},

	postPromiseFormData(endpoint: string, data: FormData) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'POST',
				data: data,
				url: baseUrl + endpoint,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
				.then((response: AxiosResponse) => resolve(response.data))
				.catch((error: Error) => reject(error));
		});
	},

	postPromiseWithoutData(endpoint: string) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'POST',
				url: baseUrl + endpoint,
			})
				.then((response: AxiosResponse) => resolve(response.data))
				.catch((error: Error) => reject(error));
		});
	},

	deletePromise(endpoint: string, data: any) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'DELETE',
				data: data,
				url: baseUrl + endpoint,
			})
				.then((response: AxiosResponse) => resolve(response.data))
				.catch((error: Error) => reject(error));
		});
	},

	putPromise(endpoint: string, data: any) {
		// Form data oluştur
		let formData: FormData;
		
		// Eğer data bir FormData değilse, her bir property'i FormData'ya ekle
		if (!(data instanceof FormData)) {
			formData = new FormData();
			Object.keys(data).forEach(key => {
				if (typeof data[key] === 'boolean') {
					formData.append(key, data[key].toString());
				} else if (data[key] !== null && data[key] !== undefined) {
					formData.append(key, data[key]);
				}
			});
		} else {
			// Eğer zaten FormData ise direkt kullan
			formData = data;
		}

		// CURL komutunu oluştur (debug için)
		let curlCommand = `curl -X 'PUT' \\
		'${baseUrl}${endpoint}' \\
		-H 'accept: */*' \\
		-H 'Content-Type: multipart/form-data'`;

		// FormData içeriğini CURL komutuna ekle
		formData.forEach((value, key) => {
			if (value instanceof File) {
				curlCommand += ` \\\n  -F '${key}=@${value.name}'`;
			} else {
				curlCommand += ` \\\n  -F '${key}=${value}'`;
			}
		});

		return new Promise((resolve, reject) => {
			axios({
				method: 'PUT',
				data: formData,
				url: baseUrl + endpoint,
				headers: {
					'Content-Type': 'multipart/form-data',
					'accept': '*/*'
				},
			})
				.then((response: AxiosResponse) => resolve(response.data))
				.catch((error: Error) => reject(error));
		});
	},

	postQuestionPromise<T>(endpoint: string, data: any): Promise<T> {
		return new Promise((resolve, reject) => {
			axios({
				method: 'POST',
				data: data,
				url: baseUrl + endpoint,
				headers: {
					'Content-Type': 'application/json',
				},
			})
				.then((response: AxiosResponse) => resolve(response.data as T))
				.catch((error: Error) => reject(error));
		});
	},

	putCategoryRiskAnswerPromise(endpoint: string, data: any) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'PUT',
				data: data,
				url: baseUrl + endpoint,
				headers: {
					'Content-Type': 'application/json',
					'accept': '*/*'
				},
			})
				.then((response: AxiosResponse) => resolve(response.data))
				.catch((error: Error) => reject(error));
		});
	},

	postReportPromise<T>(endpoint: string): Promise<T> {
		return new Promise((resolve, reject) => {
			axios({
				method: 'POST',
				url: baseUrl + endpoint,
				headers: {
					'accept': '*/*'
				},
				data: ''  // Boş string olarak gönderiyoruz
			})
				.then((response: AxiosResponse) => resolve(response.data as T))
				.catch((error: Error) => reject(error));
		});
	},

	getBlobPromise(endpoint: string) {
		return new Promise<Blob>((resolve, reject) => {
			axios({
				method: 'GET',
				url: baseUrl + endpoint,
				responseType: 'blob'
			})
				.then((response: AxiosResponse) => resolve(response.data))
				.catch((error: Error) => reject(error));
		});
	},
};

axios.interceptors.request.use((config: InternalAxiosRequestConfig) => {
	(store.dispatch)(startLoading());
	return config;
});

axios.interceptors.response.use(
	(response: AxiosResponse) => {
		(store.dispatch)(stopLoading());
		return response;
	},
	(error: Error) => {
		(store.dispatch)(stopLoading());
		return Promise.reject(error);
	},
);

export default ApiBase;
