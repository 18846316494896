import React, { useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  IconButton,
  TextField,
  Autocomplete,
  Grid,
  Button,
  Stack
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useSelector } from '../../../redux/store';
import { IEventType } from '../../../redux/reducers/appReducer';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';

interface EventEntryModalProps {
  open: boolean;
  onClose: () => void;
}

const EventEntryModal: React.FC<EventEntryModalProps> = ({ open, onClose }) => {
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(dayjs());
  const [description, setDescription] = useState('');
  const [selectedEventType, setSelectedEventType] = useState<IEventType | null>(null);
  
  const { eventTypes } = useSelector(state => state.app);
  
  // Filter out unwanted event types (aynı Filter.tsx'deki gibi)
  const filteredEventTypes = eventTypes?.filter(type => 
    type.label !== 'Uncategorized' && 
    type.label !== 'Stratejik Gelişmeler (SG)'
  );

  const handleSave = () => {
    // TODO: Kaydetme işlemi burada yapılacak
    console.log({
      date: selectedDate,
      eventType: selectedEventType,
      description
    });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ m: 0, p: 2, paddingRight: '48px' }}>
        Olay Bildir
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr">
              <DatePicker
                label="Olay Tarihi"
                value={selectedDate}
                onChange={(newValue: dayjs.Dayjs | null) => setSelectedDate(newValue)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: "small"
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
          
          <Grid item xs={12} md={6}>
            <Autocomplete
              fullWidth
              size="small"
              options={filteredEventTypes ?? []}
              value={selectedEventType}
              getOptionLabel={option => option?.label || ''}
              isOptionEqualToValue={(option, value) => 
                option?.value === value?.value
              }
              onChange={(_, value) => setSelectedEventType(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Olay Türü"
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Olay Açıklaması"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Olay detaylarını buraya giriniz..."
            />
          </Grid>
        </Grid>
        
        <Stack direction="row" spacing={2} sx={{ mt: 4 }}>
          <Button
            onClick={onClose}
            fullWidth
            sx={{
              backgroundColor: '#fff',
              border: 1,
              borderColor: '#094151',
              color: "#081038",
              borderWidth: '2px',
              '&:hover': {
                borderWidth: '2px',
                backgroundColor: '#E9F8FC',
                color: "#081038",
                fontWeight: 600
              }
            }}
          >
            İptal
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
            fullWidth
            sx={{
              backgroundColor: '#094151',
              color: "#fff",
              '&:hover': {
                backgroundColor: '#022631',
                color: "#fff",
                fontWeight: 600
              }
            }}
          >
            Olay Kaydet
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default EventEntryModal;
