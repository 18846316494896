import Grid from "@mui/material/Grid";
import {Autocomplete, Paper} from "@mui/material";
import {subMonths} from 'date-fns';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {useEffect, useState} from "react";
import CustomDateRangePicker, {IRange} from "../../../components/dateRangePicker/CustomDateRangePicker";
import {useSelector} from "../../../redux/store";
import {IEventType} from "../../../redux/reducers/appReducer";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {desiredCities} from "../../../constants/consts";

interface IFilterProps {
	filters: any,
	setFilters: any,
	defaults: any
}

const Filter = (props: IFilterProps) => {
	const {filters, setFilters, defaults} = props
	const [olayYeri, setOlayYeri] = useState<string | null>(null);
	const [olayTuru, setOlayTuru] = useState<IEventType | null>(null);
	const [dateRange, setDateRange] = useState<IRange>({
		startDate: defaults.dateStart || subMonths(new Date(), 1),
		endDate: defaults.dateEnd || new Date(),
	});
	const {eventTypes} = useSelector(state => state.app)

	// Filter out unwanted event types
	const filteredEventTypes = eventTypes?.filter(type => 
		type.label !== 'Uncategorized' && 
		type.label !== 'Stratejik Gelişmeler (SG)'
	);

	useEffect(() => {
		setOlayYeri(filters.city || null);
	}, [filters]);
	return <Paper>
		<Grid container spacing={2} paddingX={"1rem"}  sx={{backgroundColor: '#fff'}}>
			<Grid item xs={12} md={3} padding={"1rem"}>
				<CustomDateRangePicker dateRange={dateRange} setDateRange={setDateRange} label={"Olay Tarihi"}/>
			</Grid>
			<Grid item xs={12} md={3} padding={"1rem"}>
				<Autocomplete
					fullWidth
					size={"small"}
					options={desiredCities}
					value={olayYeri}
					onChange={(_, value) => setOlayYeri(value)}
					isOptionEqualToValue={(option, value) => 
						option === value || (option === null && value === '')
					}
					renderInput={(params) => (
						<TextField
							{...params}
							label="Olay Yeri"
						/>
					)}
				/>
			</Grid>
			<Grid item xs={12} md={3} padding={"1rem"}>
				<Autocomplete
					fullWidth
					size={"small"}
					options={filteredEventTypes ?? []}
					value={olayTuru}
					getOptionLabel={option => option?.label || ''}
					isOptionEqualToValue={(option, value) => 
						option?.value === value?.value
					}
					onChange={(_, value) => setOlayTuru(value)}
					renderInput={(params) => (
						<TextField
							{...params}
							label="Olay Türü"
						/>
					)}
				/>
			</Grid>
			<Grid item xs={12} md={3}>
				<Box display={"inline-flex"} width={"100%"} gap={"1rem"}>
					{/*<Box flexGrow={1}/>*/}
					<Button sx={{
						width: '50%',
						backgroundColor: '#fff',
						borderWidth: 2,
						borderStyle: 'solid',
						borderColor: '#022631',
						color: "#081038",
						'&:hover': {
							borderWidth: '2px', backgroundColor: '#E9F8FC', color:"#081038", fontWeight: 600
						}
					}}
							onClick={() => {
								setFilters(defaults);
								setDateRange({
									startDate: subMonths(new Date(), 1),
									endDate: new Date()
								});
								setOlayYeri(null);
								setOlayTuru(null);
							}}
					>
						Temizle
					</Button>
					<Button sx={{ width: '50%',
						backgroundColor: '#094151',
						color: "#fff",
						'&:hover': {
							backgroundColor: '#022631',
							color: "#fff",
							fontWeight: 600,
						}
					}}
					        onClick={() => {
						        setFilters({
							        ...filters,
							        dateStart: dateRange.startDate,
							        dateEnd: dateRange.endDate,
							        city: olayYeri,
							        eventTypeId: olayTuru?.value
						        })
					        }}>
						Filtrele
					</Button>

				</Box>
			</Grid>
		</Grid>
	</Paper>
}
export default Filter;