import React from 'react';
import { Box, CircularProgress } from "@mui/material";
import DistrictModal from './DistrictModal';

interface ISummaryProps {
	eventTypeCounts?: any[] | null;
	districtEventCounts?: any[] | null;
	startDate: Date;
	endDate: Date;
	filters: any;
	isLoading: boolean;
}

const Summary = (props: ISummaryProps) => {
	const { startDate, endDate, filters, isLoading } = props;

	return (
		<Box position="relative">
			{isLoading && (
				<Box
					sx={{
						position: 'absolute',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						backgroundColor: 'rgba(255, 255, 255, 0.7)',
						zIndex: 1000,
					}}
				>
					<CircularProgress />
				</Box>
			)}
			<DistrictModal
				open={true}
				onClose={() => {}}
				districtName=""
				filters={{
					dateStart: startDate.toISOString(),
					dateEnd: endDate.toISOString(),
					city: filters.city,
					eventTypeId: filters.eventTypeId
				}}
			/>
		</Box>
	);
};

export default Summary;