import React, {useEffect} from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import Login from "../views/login/Login";
import {AppDispatch, useSelector} from "../redux/store";
import PageWrapper from "../layouts/full/dataShield/PageWrapper";
import Logout from "../views/logout/Logout";
import {logout} from "../redux/actions/authActions";
import Home from "../views/home/Home";
import Analysis from "../views/analysis/Analysis";

/* ****Pages***** */
const Routes = () => {
	const accessToken = useSelector((state) => state.auth.accessToken)
	const exp = useSelector(state => state.auth.exp);
	const dispatch = AppDispatch();

	useEffect(() => {
		if (exp != null && exp <= new Date().valueOf() / 1000) {
			dispatch(logout());
		}
	}, [exp, dispatch]);

	return !accessToken ? [
		{
			path: '/',
			element: <Outlet/>,
			children: [
				{
					path: '/',
					element: <Login/>,
				},
				{
					path: '*',
					element: <Navigate to="/"/>,
				}
			]
		},
	] : [
		{
			path: '/',
			element: <PageWrapper/>,
			children: [
				{
					path: '/',
					element: <Home/>
				},
				{
					path: '/analysis',
					element: <Analysis/>,
				},
				{
					path: '/logout',
					element: <Logout/>,
				},
				{
					path: '*',
					element: <Navigate to="/"/>,
				}
			],
		},
	];
}

export default Routes;
