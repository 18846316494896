import React from 'react';
import { Grid, Box, Paper, Typography, CircularProgress, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';
import ChordDiagram from "./chordDiagram";
import EventsApi from "../../api/eventsApi/eventsApi";
import {useEffect, useState} from "react"
import WikipediaModal from "../../helpers/wikipediaModal";
import ReportPinModal from "../home/components/ReportPinModal";
import ChecklistModal from '../home/components/checklistModal';
import { fetchWikipediaData } from "../../api/publicApi/wikipedia";

interface EventTypeCount {
    eventType: string;
    count: number;
}

interface CityCount {
    city: string;
    count: number;
}

interface WeeklyData {
    weekStart: string;
    eventTypeCounts: EventTypeCount[];
}

interface Pin {
    id: string;
    name: string;
    sector: string;
    latitude: number;
    longitude: number;
    radiusInMeters: number;
    radius?: number;
    reportDescription?: string;
}

interface PinResponse {
    items: Pin[];
}

// Basitleştirilmiş Pin interface'i ekleyelim
interface SimplifiedPin {
    id: string;
    name: string;
    sector: string;
}

type ValueFormatter = (value: number | null) => string;
const TARGET_CITIES = ["Ankara", "İzmir", "İstanbul", "Antalya"];

// Renk skalasını mapStyles'dan al
const eventTypeColors: Record<string, string> = {
    'Terörizm (TE)': '#D76E3B',
    'Örgütlü Suçlar (OS)': '#F35E5E',
    'Sosyal ve Politik İstikrarsızlık (SP)': '#67C086',
    'Çevresel ve Doğal Afetler (CA)': '#689D7B',
    'Bilgi Güvenliği ve Enformasyon Tehditleri (BG)': '#63ABC3',
    'Bireysel ve Adi Suçlar (BA)': '#4995DB',
    'Göç ve Mülteci Krizleri (GM)': '#8e6c58',
    'Sağlık ve Biyolojik Tehditler (SB)': '#265454',
    'Yasal ve Düzenleyici Uyumsuzluklar (YD)': '#0f223a',
    'Ekonomik ve Finansal Tehditler (EF)': '#4a3b39'
};

// Özel Legend Komponenti
const CustomLegend = ({ series }: { series: { label: string; }[] }) => (
    <Stack 
        direction="row" 
        spacing={2} 
        sx={{ 
            flexWrap: 'wrap', 
            justifyContent: 'center',
            gap: 1,
            mb: 2
        }}
    >
        {series.map((item) => (
            <Stack 
                key={item.label} 
                direction="row" 
                alignItems="center" 
                spacing={0.5}
            >
                <Box
                    sx={{
                        width: 12,
                        height: 12,
                        backgroundColor: eventTypeColors[item.label] || '#000',
                        borderRadius: '50%'
                    }}
                />
                <Typography variant="caption">
                    {item.label}
                </Typography>
            </Stack>
        ))}
    </Stack>
);

interface DailyEventCount {
    date: string;
    eventType: string;
    count: number;
}

interface WeeklyEventTypeCount {
    eventType: string;
    dailyCounts: { [key: string]: number }; // key: "Pazartesi", "Salı" vb.
}

// Interface'leri ekleyelim
interface EventCountsResponse {
    events: {
        items: Array<{
            date: string;
            eventType: {
                id: string;
                name: string;
            };
        }>;
    };
    eventTypeCounts: Array<{
        eventType: string;
        count: number;
    }>;
    averageDailyCount: number;
}

const Analysis = () => {
    const formatNumber: ValueFormatter = (value: number | null) => {
        if (value === null) return '';
        return value.toString().replace('.', ',');
    };

    const [chartData, setChartData] = useState<any[]>([]);
    const [xAxisData, setXAxisData] = useState<string[]>([]);
    const [stackedData, setStackedData] = useState<any[]>([]);
    const [eventTypes, setEventTypes] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [weeklyData, setWeeklyData] = useState<WeeklyData[]>([]);
    const [pins, setPins] = useState<Pin[]>([]);

    // Yeni state sadece sol üst grafik için
    const [weeklyEventData, setWeeklyEventData] = useState<{
        series: { data: number[]; label: string; }[];
        xAxis: string[];
    }>({ series: [], xAxis: [] });

    const [wikipediaModalOpen, setWikipediaModalOpen] = useState(false);
    const [reportPinModalOpen, setReportPinModalOpen] = useState(false);
    const [checklistOpen, setChecklistOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedPin, setSelectedPin] = useState<Pin | null>(null);
    const [wikipediaData, setWikipediaData] = useState<{
        title: string | null;
        summary: string | null;
        thumbnail: string | null;
    }>({
        title: null,
        summary: null,
        thumbnail: null
    });

    const [weeklyEventTypeCounts, setWeeklyEventTypeCounts] = useState<WeeklyEventTypeCount[]>([]);

    // getCurrentWeekDays fonksiyonunu useEffect dışına çıkar
    const getCurrentWeekDays = () => {
        const days = ['Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi', 'Pazar'];
        const today = new Date();
        const currentDay = today.getDay(); // 0 = Pazar, 1 = Pazartesi
        const monday = new Date(today);
        
        // Pazartesi gününe git
        monday.setDate(today.getDate() - (currentDay === 0 ? 6 : currentDay - 1));
        
        return days.map((day, index) => {
            const date = new Date(monday);
            date.setDate(monday.getDate() + index);
            return {
                name: day,
                date: date.toISOString().split('T')[0],
                displayDate: date.toLocaleDateString('tr-TR', { 
                    day: 'numeric',
                    month: 'numeric'
                })
            };
        });
    };

    // fetchCurrentWeekEvents fonksiyonunu da useEffect dışına çıkar
    const fetchCurrentWeekEvents = async () => {
        try {
            const weekDays = getCurrentWeekDays();
            const eventTypeMap = new Map<string, { [key: string]: number }>();

            // Tüm günler için ayrı ayrı API çağrısı yap
            const dailyResponses = await Promise.all(
                weekDays.map(day => 
                    EventsApi.getEventsCounts({
                        dateStart: day.date,
                        dateEnd: day.date
                    }) as Promise<EventCountsResponse>
                )
            );

            // İlk günün olay türlerini kullanarak boş map'i oluştur
            const firstDayResponse = dailyResponses[0];
            if (firstDayResponse?.eventTypeCounts) {
                firstDayResponse.eventTypeCounts.forEach((count) => {
                    eventTypeMap.set(count.eventType, {
                        'Pazartesi': 0,
                        'Salı': 0,
                        'Çarşamba': 0,
                        'Perşembe': 0,
                        'Cuma': 0,
                        'Cumartesi': 0,
                        'Pazar': 0
                    });
                });
            }

            // Her günün verilerini işle
            dailyResponses.forEach((response, index) => {
                const dayName = weekDays[index].name;
                response.eventTypeCounts?.forEach((count) => {
                    const eventTypeCounts = eventTypeMap.get(count.eventType);
                    if (eventTypeCounts) {
                        eventTypeCounts[dayName] = count.count;
                    }
                });
            });

            // Boş veri kontrolü
            if (eventTypeMap.size === 0) {
                console.log('Bu hafta için olay verisi bulunamadı');
                setWeeklyEventTypeCounts([]);
                return;
            }

            // State'i güncelle
            const formattedData = Array.from(eventTypeMap.entries()).map(([eventType, dailyCounts]) => ({
                eventType,
                dailyCounts
            }));

            setWeeklyEventTypeCounts(formattedData);
        } catch (error) {
            console.error('Haftalık olay sayıları alınırken hata:', error);
            setWeeklyEventTypeCounts([]);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        
        // Son 6 haftanın tarih aralıklarını hesapla
        const getWeekRanges = () => {
            const ranges = [];
            const today = new Date();
            
            const currentDay = today.getDay();
            const diff = currentDay === 0 ? 6 : currentDay - 1;
            today.setDate(today.getDate() - diff);
            
            for (let i = 5; i >= 0; i--) {
                const weekStart = new Date(today);
                weekStart.setDate(today.getDate() - (i * 7));
                
                const weekEnd = new Date(weekStart);
                weekEnd.setDate(weekEnd.getDate() + 6);

                // Tarihleri Türkçe formatla ve ay isimlerini tam olarak yaz
                const startStr = weekStart.toLocaleDateString('tr-TR', { 
                    day: 'numeric', 
                    month: 'long' // 'numeric' yerine 'long' kullanarak ay ismini tam yazıyoruz
                });
                const endStr = weekEnd.toLocaleDateString('tr-TR', { 
                    day: 'numeric', 
                    month: 'long'
                });
                
                ranges.push({
                    dateStart: weekStart.toISOString().split('T')[0],
                    dateEnd: weekEnd.toISOString().split('T')[0],
                    label: `${startStr}-${endStr}`  // Örnek: "1 Ocak-7 Ocak"
                });
            }
            return ranges;
        };

        const weekRanges = getWeekRanges();

        // Sadece sol üst grafik için veri çekme
        Promise.all(
            weekRanges.map(({ dateStart, dateEnd }) =>
                EventsApi.getEventsCounts({ dateStart, dateEnd })
                    .then((response: any) => ({
                        weekStart: dateStart,
                        eventTypeCounts: response.eventTypeCounts || []
                    }))
            )
        ).then((results) => {
            const allEventTypes = new Set<string>();
            results.forEach(week => {
                week.eventTypeCounts.forEach((count: { eventType: string; }) => {
                    allEventTypes.add(count.eventType);
                });
            });

            const series = Array.from(allEventTypes).map(eventType => ({
                data: results.map(week => {
                    const eventData = week.eventTypeCounts.find(
                        (count: { eventType: string; }) => count.eventType === eventType
                    );
                    return eventData?.count || 0;
                }),
                label: eventType,
                color: eventTypeColors[eventType],
                // Nokta stilini ayarla
                valueFormatter: (value: number) => `${value}`,
                // Çizgi ve nokta stilini ayarla
                showMark: true, // Noktaları göster
                lineStyle: { strokeWidth: 2 }, // Çizgi kalınlığı
                markerSize: 5 // Nokta boyutu
            }));

            setWeeklyEventData({
                series,
                xAxis: weekRanges.map(range => range.label)
            });
            setIsLoading(false);
        }).catch((error) => {
            console.error("API Error:", error);
            setIsLoading(false);
        });

        // Diğer API çağrıları buraya gelecek...
        
        const fetchPins = async () => {
            try {
                const response = await EventsApi.getPins() as PinResponse;
                if (response?.items) {
                    setPins(response.items);
                }
            } catch (error) {
                console.error("Pin verisi alınırken hata:", error);
            }
        };

        fetchPins();

        fetchCurrentWeekEvents();
    }, []);

    // Line chart için seri hazırlama fonksiyonunu güncelle
    const prepareSeriesData = (results: any[]) => {
        const allEventTypes = new Set<string>();
        results.forEach(week => {
            week.eventTypeCounts.forEach((count: { eventType: string; }) => {
                allEventTypes.add(count.eventType);
            });
        });

        return Array.from(allEventTypes).map(eventType => ({
            data: results.map(week => {
                const eventData = week.eventTypeCounts.find(
                    (count: { eventType: string; }) => count.eventType === eventType
                );
                return eventData?.count || 0;
            }),
            label: eventType,
            color: eventTypeColors[eventType] // Rengi burada ayarla
        }));
    };

    // Değişken ismini weeklyLineChartData olarak değiştirdik
    const weeklyLineChartData = prepareSeriesData(weeklyData);
    const hasData = weeklyData.length > 0;

    // Pin'e tıklama işleyicisi
    const handlePinClick = async (pin: Pin) => {
        setLoading(true);
        setWikipediaModalOpen(true);
        
        // Pin'i kopyala ve radius'u ekle
        const pinWithRadius = {
            ...pin,
            radius: pin.radiusInMeters // radiusInMeters'ı radius olarak kullan
        };
        
        setSelectedPin(pinWithRadius);

        try {
            const pinInfo = await EventsApi.getPinInfo(pin.id);
            
            if (pinInfo.Name) {
                const wikiData = await fetchWikipediaData(pinInfo.Name);
                setWikipediaData({
                    title: pinInfo.Name || null,
                    summary: wikiData?.extract || pinInfo.QuestionsJson?.wikipediaSummary || null,
                    thumbnail: wikiData?.thumbnail?.source || null
                });
            }
        } catch (error) {
            console.error('Pin bilgileri yüklenirken hata:', error);
        } finally {
            setLoading(false);
        }
    };

    // Modal kapatma işleyicisi
    const handleModalClose = () => {
        setWikipediaModalOpen(false);
        setWikipediaData({
            title: null,
            summary: null,
            thumbnail: null
        });
        setSelectedPin(null);
    };

    // Pin silme işleyicisi
    const handleDeletePin = async (pinId: string) => {
        try {
            // Pin silme API çağrısı burada yapılabilir
            setPins(prevPins => prevPins.filter(pin => pin.id !== pinId));
            handleModalClose();
        } catch (error) {
            console.error('Pin silinirken hata:', error);
        }
    };

    // Veriler yüklenene kadar loading göster
    if (isLoading) {
        return (
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                height: '100vh' 
            }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ flexGrow: 1, padding: '1rem' }}>
            <Grid container spacing={3} sx={{ height: '100vh' }}>
                {/* Sol Üst Alan */}
                <Grid item xs={12} md={6} sx={{ height: '50%' }}>
                    <Paper elevation={3} sx={{ 
                        height: '100%', 
                        padding: '1rem', 
                        display: 'flex',
                        flexDirection: 'column', 
                        alignItems: 'center'
                    }}>
                        <Typography variant="h5" gutterBottom>
                            Tüm Türkiye için Son 6 Hafta Olay Türü Dağılımı
                        </Typography>
                        {weeklyEventData.series.length > 0 ? (
                            <>
                                <CustomLegend series={weeklyEventData.series} />
                                <LineChart
                                    sx={{ flexGrow: 1, width: '100%' }}
                                    height={250}
                                    series={weeklyEventData.series}
                                    xAxis={[{
                                        data: weeklyEventData.xAxis,
                                        scaleType: 'point',
                                        tickLabelStyle: {
                                            angle: 45,
                                            textAnchor: 'start',
                                            fontSize: 12
                                        }
                                    }]}
                                    margin={{ top: 20, bottom: 50, left: 40, right: 10 }}
                                    legend={{ hidden: true }} // MUI legend'ı gizle
                                />
                            </>
                        ) : (
                            <Typography>Veri bulunamadı</Typography>
                        )}
                    </Paper>
                </Grid>

                {/* Sağ Üst Alan */}
                <Grid item xs={12} md={6} sx={{ height: '50%' }}>
                    <Paper elevation={3} sx={{ 
                        height: '100%', 
                        padding: '1rem', 
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <Typography variant="h5" gutterBottom align="center">
                            Bu Haftanın Günlük Olay Sayıları
                        </Typography>
                        <TableContainer sx={{ maxHeight: '90%', overflow: 'auto' }}>
                            {weeklyEventTypeCounts.length > 0 ? (
                                <Table stickyHeader size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell 
                                                sx={{ 
                                                    fontWeight: 'bold', 
                                                    backgroundColor: '#f5f5f5',
                                                    position: 'sticky',
                                                    left: 0,
                                                    zIndex: 2
                                                }}
                                            >
                                                Olay Türü
                                            </TableCell>
                                            {getCurrentWeekDays().map((day) => (
                                                <TableCell 
                                                    key={day.name} 
                                                    align="center"
                                                    sx={{ 
                                                        fontWeight: 'bold', 
                                                        backgroundColor: '#f5f5f5',
                                                        whiteSpace: 'pre-line',
                                                        padding: '8px 4px',
                                                        minWidth: '100px'
                                                    }}
                                                >
                                                    {`${day.name}\n(${day.displayDate})`}
                                                </TableCell>
                                            ))}
                                            <TableCell 
                                                align="center"
                                                sx={{ 
                                                    fontWeight: 'bold', 
                                                    backgroundColor: '#f5f5f5'
                                                }}
                                            >
                                                Toplam
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {weeklyEventTypeCounts.map((row) => {
                                            const total = Object.values(row.dailyCounts).reduce((sum, count) => sum + count, 0);
                                            return (
                                                <TableRow key={row.eventType}>
                                                    <TableCell 
                                                        sx={{ 
                                                            position: 'sticky',
                                                            left: 0,
                                                            backgroundColor: 'white',
                                                            zIndex: 1
                                                        }}
                                                    >
                                                        {row.eventType}
                                                    </TableCell>
                                                    {Object.values(row.dailyCounts).map((count, index) => (
                                                        <TableCell 
                                                            key={index} 
                                                            align="center"
                                                        >
                                                            {count}
                                                        </TableCell>
                                                    ))}
                                                    <TableCell 
                                                        align="center"
                                                        sx={{ 
                                                            fontWeight: 'bold',
                                                            backgroundColor: 'rgba(25, 118, 210, 0.12)'
                                                        }}
                                                    >
                                                        {total}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            ) : (
                                <Box sx={{ 
                                    display: 'flex', 
                                    justifyContent: 'center', 
                                    alignItems: 'center', 
                                    height: '200px' 
                                }}>
                                    <Typography variant="body1" color="text.secondary">
                                        Bu hafta için olay verisi bulunamadı
                                    </Typography>
                                </Box>
                            )}
                        </TableContainer>
                    </Paper>
                </Grid>

                {/* Sol Alt Alan */}
                <Grid item xs={12} md={6} sx={{ height: '50%' }}>
                    <Paper elevation={3} sx={{ 
                        height: '100%', 
                        padding: '1rem', 
                        display: 'flex',
                        flexDirection: 'column', 
                        alignItems: 'center' 
                    }}>
                        <Typography variant="h5" gutterBottom>
                            Pin Listesi
                        </Typography>
                        <TableContainer sx={{ maxHeight: '90%', overflow: 'auto' }}>
                            <Table stickyHeader size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ 
                                            fontWeight: 'bold', 
                                            backgroundColor: '#f5f5f5'
                                        }}>
                                            Pin Adı
                                        </TableCell>
                                        <TableCell sx={{ 
                                            fontWeight: 'bold', 
                                            backgroundColor: '#f5f5f5'
                                        }}>
                                            Sektör
                                        </TableCell>
                                        <TableCell sx={{ 
                                            fontWeight: 'bold', 
                                            backgroundColor: '#f5f5f5',
                                            textAlign: 'center'
                                        }}>
                                            Risk Faktörü
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {pins.map((pin) => (
                                        <PinListItem 
                                            key={pin.id} 
                                            pin={pin} 
                                            onPinClick={handlePinClick}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>

                {/* Sağ Alt Alan */}
                <Grid item xs={12} md={6} sx={{ height: '50%' }}>
                    <Paper elevation={3} sx={{ height: '100%' }}>
                        <ChordDiagram />
                    </Paper>
                </Grid>
            </Grid>

            <WikipediaModal
                open={wikipediaModalOpen}
                onClose={handleModalClose}
                onDelete={() => selectedPin && handleDeletePin(selectedPin.id)}
                onCreateReport={() => setReportPinModalOpen(true)}
                onOpenChecklist={() => setChecklistOpen(true)}
                title={wikipediaData.title}
                loading={loading}
                thumbnail={wikipediaData.thumbnail}
                summary={wikipediaData.summary}
                savedPinData={selectedPin ? {
                    id: selectedPin.id,
                    name: selectedPin.name,
                    latitude: selectedPin.latitude,
                    longitude: selectedPin.longitude,
                    radius: selectedPin.radiusInMeters, // radiusInMeters'ı radius olarak kullan
                    sector: selectedPin.sector,
                    reportDescription: selectedPin.reportDescription
                } : null}
            />

            <ReportPinModal
                open={reportPinModalOpen}
                onClose={() => setReportPinModalOpen(false)}
                coordinates={selectedPin ? { 
                    latitude: selectedPin.latitude, 
                    longitude: selectedPin.longitude 
                } : undefined}
                radius={selectedPin?.radiusInMeters}
                wikipediaData={{
                    title: wikipediaData.title,
                    summary: wikipediaData.summary
                }}
                onSubmit={() => {
                    setReportPinModalOpen(false);
                    // Gerekirse burada pin güncelleme işlemleri yapılabilir
                }}
            />

            <ChecklistModal
                open={checklistOpen}
                onClose={() => setChecklistOpen(false)}
                pinId={selectedPin?.id || ''}
            />
        </Box>
    );
};

// TableRow içindeki onClick handler'ı için yeni bir fonksiyon ekleyelim
const PinListItem = ({ pin, onPinClick }: { pin: Pin, onPinClick: (pin: Pin) => void }) => (
    <TableRow 
        onClick={() => onPinClick(pin)}
        sx={{ 
            '&:hover': { backgroundColor: '#f5f5f5' },
            cursor: 'pointer'
        }}
    >
        <TableCell>{pin.name}</TableCell>
        <TableCell>{pin.sector}</TableCell>
        <TableCell align="center">-</TableCell>
    </TableRow>
);

export default Analysis;