import React, { useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { LineChart, PieChart } from '@mui/x-charts';
import SaveIcon from '@mui/icons-material/Save';
import html2canvas from 'html2canvas';
import EventsApi from '../../../api/eventsApi/eventsApi';

interface PinChartModalProps {
  pinId?: string;
}

interface EventInfo {
  id: string;
  eventType: string;
  date: string;
  description: string;
}

interface WeeklyData {
  date: Date;
  count: number;
  weekLabel: string;
}

interface EventTypeData {
  eventType: string;
  data: { date: Date; count: number; weekLabel: string }[];
}

// Analysis sayfasındaki renkleri import edelim
const eventTypeColors: Record<string, string> = {
  'Terörizm (TE)': '#D76E3B',
  'Örgütlü Suçlar (OS)': '#F35E5E',
  'Sosyal ve Politik İstikrarsızlık (SP)': '#67C086',
  'Çevresel ve Doğal Afetler (CA)': '#689D7B',
  'Bilgi Güvenliği ve Enformasyon Tehditleri (BG)': '#63ABC3',
  'Bireysel ve Adi Suçlar (BA)': '#4995DB',
  'Göç ve Mülteci Krizleri (GM)': '#8e6c58',
  'Sağlık ve Biyolojik Tehditler (SB)': '#265454',
  'Yasal ve Düzenleyici Uyumsuzluklar (YD)': '#0f223a',
  'Ekonomik ve Finansal Tehditler (EF)': '#4a3b39'
};

const PinChartModal: React.FC<PinChartModalProps> = ({ pinId }) => {
  const [eventDetails, setEventDetails] = useState<EventInfo[]>([]);
  const [monthlyData, setMonthlyData] = useState<WeeklyData[]>([]);
  const [eventTypeData, setEventTypeData] = useState<EventTypeData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const chartContainerRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!pinId) {
        return;
      }

      setIsLoading(true);
      try {
        await getPinEvents(pinId);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [pinId]);

  const getPinEvents = async (pinId: string) => {
    // Son 8 haftayı hesapla
    const endDate = new Date();
    const startDate = new Date();
    
    // Pazartesi gününü bul (0 = Pazar, 1 = Pazartesi, ...)
    const currentDay = endDate.getDay();
    const daysFromMonday = currentDay === 0 ? 6 : currentDay - 1;
    
    // İçinde bulunduğumuz haftanın pazartesi gününü bul
    const currentWeekMonday = new Date(endDate);
    currentWeekMonday.setDate(endDate.getDate() - daysFromMonday);
    currentWeekMonday.setHours(0, 0, 0, 0);

    // 8 hafta öncesinin pazartesi gününü bul
    startDate.setDate(currentWeekMonday.getDate() - (7 * 8));
    
    // Gelecek haftanın son gününü hesapla
    const forecastEndDate = new Date(currentWeekMonday);
    forecastEndDate.setDate(currentWeekMonday.getDate() + 13); // 2 hafta ekle

    const formattedEndDate = forecastEndDate.toISOString().split('T')[0];
    const formattedStartDate = startDate.toISOString().split('T')[0];

    try {
      const response = await EventsApi.getPinEvents(pinId, formattedStartDate, formattedEndDate);
      const allEvents = response.locations.flatMap(location => location.events);

      // Tüm haftaları oluştur (geçmiş 8 hafta + şimdiki hafta + gelecek hafta)
      const weeklyDates: Date[] = [];
      const currentDate = new Date(startDate);
      while (currentDate <= forecastEndDate) {
        weeklyDates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 7);
      }

      // Haftalık verileri işle
      const weeklyGroups: { [key: string]: number } = {};
      weeklyDates.forEach(date => {
        const weekKey = date.toISOString().split('T')[0];
        weeklyGroups[weekKey] = 0;
      });

      // Sadece geçmiş olayları say (mevcut haftanın olaylarını dahil etme)
      allEvents.forEach(event => {
        const eventDate = new Date(event.date);
        if (eventDate < currentWeekMonday) {
          const weekStart = weeklyDates.find((date, index) => {
            const nextDate = weeklyDates[index + 1];
            return eventDate >= date && (!nextDate || eventDate < nextDate);
          });
          
          if (weekStart) {
            const weekKey = weekStart.toISOString().split('T')[0];
            weeklyGroups[weekKey]++;
          }
        }
      });

      // Son 4 haftanın ortalamasını hesapla
      const last4Weeks = Object.entries(weeklyGroups)
        .map(([key, value]) => ({ date: new Date(key), count: value }))
        .sort((a, b) => b.date.getTime() - a.date.getTime())
        .slice(0, 4);

      const avgWeeklyEvents = last4Weeks.reduce((sum, week) => sum + week.count, 0) / 4;

      // Mevcut hafta için ağırlıklı tahmin
      const currentWeekEvents = allEvents.filter(event => {
        const eventDate = new Date(event.date);
        return eventDate >= currentWeekMonday && eventDate <= endDate;
      }).length;

      const daysIntoCurrentWeek = daysFromMonday + 1;
      const currentWeekEstimate = Math.round((currentWeekEvents / daysIntoCurrentWeek) * 7);

      // Tahminleri ekle
      const weeklyDataArray = Object.entries(weeklyGroups).map(([dateKey, count]) => {
        const date = new Date(dateKey);
        const weekEnd = new Date(date);
        weekEnd.setDate(date.getDate() + 6);
        
        const isCurrentWeek = date >= currentWeekMonday && date < new Date(currentWeekMonday.getTime() + 7 * 24 * 60 * 60 * 1000);
        const isNextWeek = date >= new Date(currentWeekMonday.getTime() + 7 * 24 * 60 * 60 * 1000);
        
        let finalCount = count;

        if (isCurrentWeek) {
          finalCount = currentWeekEstimate;
        } else if (isNextWeek) {
          finalCount = Math.round(avgWeeklyEvents);
        }
        
        const weekLabel = `${date.toLocaleDateString('tr-TR', { 
          day: 'numeric',
          month: 'short'
        })} - ${weekEnd.toLocaleDateString('tr-TR', { 
          day: 'numeric',
          month: 'short'
        })}`;

        return {
          date,
          count: finalCount,
          weekLabel,
        };
      }).sort((a, b) => a.date.getTime() - b.date.getTime());

      setMonthlyData(weeklyDataArray);

      // Olay tiplerine göre haftalık verileri işle
      const eventTypes = Array.from(new Set(allEvents.map(event => event.eventType)));
      
      const eventTypeDataArray = eventTypes.map(eventType => {
        const weekData = weeklyDates.reduce((acc: { [key: string]: number }, date) => {
          acc[date.toISOString().split('T')[0]] = 0;
          return acc;
        }, {});

        allEvents.forEach(event => {
          if (event.eventType === eventType) {
            const eventDate = new Date(event.date);
            const weekStart = weeklyDates.find((date, index) => {
              const nextDate = weeklyDates[index + 1];
              return eventDate >= date && (!nextDate || eventDate < nextDate);
            });
            
            if (weekStart) {
              const weekKey = weekStart.toISOString().split('T')[0];
              weekData[weekKey]++;
            }
          }
        });

        return {
          eventType,
          data: Object.entries(weekData).map(([dateKey, count]) => {
            const date = new Date(dateKey);
            const weekEnd = new Date(date);
            weekEnd.setDate(date.getDate() + 6);
            
            const weekLabel = `${date.toLocaleDateString('tr-TR', { 
              day: 'numeric',
              month: 'short'
            })} - ${weekEnd.toLocaleDateString('tr-TR', { 
              day: 'numeric',
              month: 'short'
            })}`;

            return {
              date,
              count,
              weekLabel
            };
          }).sort((a, b) => a.date.getTime() - b.date.getTime())
        };
      });

      setEventTypeData(eventTypeDataArray);
      setEventDetails(allEvents);
    } catch (error) {
      console.error('Error in getPinEvents:', error);
    }
  };

  const handleSaveChart = async () => {
    if (!chartContainerRef.current || !pinId) return;

    try {
      // Capture the chart container
      const canvas = await html2canvas(chartContainerRef.current, {
        scale: 2,
        backgroundColor: '#fff',
        logging: false,
        removeContainer: true,
        // Kaydet butonunu gizle
        onclone: (document, element) => {
          const buttonElement = element.querySelector('button');
          if (buttonElement) {
            buttonElement.style.display = 'none';
          }
        }
      });

      // Convert canvas to blob
      const blob = await new Promise<Blob>((resolve, reject) => {
        canvas.toBlob((blob) => {
          if (blob) resolve(blob);
          else reject(new Error('Canvas to Blob conversion failed'));
        }, 'image/png');
      });

      // Convert blob to File
      const chartImage = new File([blob], 'chart.png', { type: 'image/png' });

      // Call updatePin with the image
      await EventsApi.updatePin(pinId, {
        Name: '', // These fields are required but will be ignored in update
        Latitude: 0,
        Longitude: 0,
        RadiusInMeters: 0,
        Sector: '',
        userId: '',
        File: chartImage
      });

      console.log('Chart saved successfully');
    } catch (error) {
      console.error('Error saving chart:', error);
    }
  };

  return (
    <Box sx={{ width: '100%', mt: 3 }}>
      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
          <Typography>Veriler yükleniyor...</Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            width: '100%',
          }}
        >
          <Box
            ref={chartContainerRef}
            sx={{
              height: '300px',
              backgroundColor: '#fff',
              borderRadius: '8px',
              p: 2,
              border: '1px solid #e0e0e0',
              position: 'relative'
            }}
          >
            <Button
              size="small"
              startIcon={<SaveIcon />}
              onClick={handleSaveChart}
              sx={{
                position: 'absolute',
                right: -8,
                top: -16,
                backgroundColor: 'white',
                color: '#094151',
                '&:hover': {
                  backgroundColor: '#e0e0e0',
                },
                fontSize: '0.75rem',
                padding: '4px 8px',
                minWidth: 'auto',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                transform: 'translateY(50%)',
                borderRadius: '6px',
                border: '1px solid #e0e0e0',
                zIndex: 1
              }}
            >
              Rapor için kaydet
            </Button>

            <Typography variant="h6" gutterBottom>
              Son 8 Haftalık Olay Sayısı ve Tahmin
            </Typography>
            {monthlyData.length > 0 && (
              <>
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 1 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Box sx={{ width: 12, height: 12, bgcolor: '#094151', borderRadius: '50%' }}/>
                    <Typography sx={{ fontSize: '0.75rem' }}>Gerçekleşen</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Box sx={{ width: 12, height: 12, bgcolor: '#FF9800', borderRadius: '50%' }}/>
                    <Typography sx={{ fontSize: '0.75rem' }}>Tahmin</Typography>
                  </Box>
                </Box>
                <LineChart
                  xAxis={[{
                    data: monthlyData.map(d => d.weekLabel),
                    scaleType: 'band',
                    tickLabelStyle: {
                      angle: 45,
                      textAnchor: 'start',
                      fontSize: 10
                    }
                  }]}
                  series={[
                    {
                      data: [...monthlyData.map(d => d.count)],
                      curve: 'linear',
                      showMark: true,
                      color: '#094151',
                      id: 'actual',
                      valueFormatter: (value, context) => {
                        const index = context.dataIndex;
                        return index < monthlyData.length - 2 ? `Olay Sayısı: ${value}` : null
                      }
                    },
                    {
                      data: [...Array(monthlyData.length - 2).fill(null), 
                             monthlyData[monthlyData.length - 2].count, 
                             monthlyData[monthlyData.length - 1].count],
                      curve: 'linear',
                      showMark: true,
                      color: '#FF9800',
                      id: 'forecast',
                      valueFormatter: (value) => value ? `Tahmin: ${value}` : null,
                      connectNulls: true
                    }
                  ]}
                  height={250}
                  margin={{left: 40, right: 40, top: 16, bottom: 80}}
                  sx={{
                    '.MuiLineElement-root': {
                      strokeWidth: 2,
                    },
                    '.MuiLineElement-series-forecast': {
                      stroke: '#FF9800'
                    },
                    '.MuiMarkElement-root': {
                      strokeWidth: 2,
                      r: 4,
                      stroke: '#fff',
                      fill: 'currentColor',
                      display: 'inline'
                    },
                    '.MuiMarkElement-series-forecast': {
                      fill: '#FF9800'
                    }
                  }}
                  slotProps={{
                    legend: {
                      hidden: true
                    }
                  }}
                />
              </>
            )}
          </Box>

          <Box
            sx={{
              height: '300px',
              backgroundColor: '#fff',
              borderRadius: '8px',
              p: 2,
              border: '1px solid #e0e0e0',
            }}
          >
            <Typography variant="h6" gutterBottom>
              Olay Tiplerine Göre Dağılım (Son 8 Hafta)
            </Typography>
            {eventTypeData.length > 0 && (
              <PieChart
                series={[
                  {
                    data: eventTypeData.map((typeData) => ({
                      id: typeData.eventType,
                      value: typeData.data.reduce((sum, d) => sum + d.count, 0),
                      label: typeData.eventType,
                      color: eventTypeColors[typeData.eventType] || `hsl(${eventTypeData.indexOf(typeData) * (360 / eventTypeData.length)}, 70%, 50%)`
                    })),
                    highlightScope: { faded: 'global', highlighted: 'item' },
                    faded: { innerRadius: 30, additionalRadius: -30 }
                  }
                ]}
                height={250}
                width={600}
                margin={{ right: 200 }}
                slotProps={{
                  legend: {
                    direction: 'column',
                    position: { vertical: 'middle', horizontal: 'right' },
                    padding: { left: 0, right: 20, top: 0, bottom: 0 },
                    itemMarkWidth: 8,
                    itemMarkHeight: 8,
                    markGap: 5,
                    itemGap: 8,
                    labelStyle: {
                      fontSize: 11,
                    }
                  }
                }}
              />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PinChartModal;
