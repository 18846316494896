import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Box
} from '@mui/material';
import ChecklistModal from './checklistModal';
import EventsApi, { PinData } from '../../../api/eventsApi/eventsApi';
import { useSelector } from 'react-redux';

interface ReportPinModalProps {
    open: boolean;
    onClose: () => void;
    coordinates?: {
        latitude: number;
        longitude: number;
    };
    radius?: number;
    wikipediaData: {
        title: string | null;
        summary: string | null;
    };
    onSubmit: (data: any) => void;
}

interface ReportPinData {
    name: string;
    radius: number;
    sector: string;
    id?: string;
}

const ReportPinModal: React.FC<ReportPinModalProps> = ({ 
    open, 
    onClose, 
    onSubmit,
    coordinates,
    radius,
    wikipediaData 
}) => {
    const userId = useSelector((state: any) => state.auth.id);
    const [reportPinData, setReportPinData] = useState<ReportPinData>({
        name: '',
        radius: radius ? Math.round(radius) : 1000,
        sector: ''
    });
    const [checklistOpen, setChecklistOpen] = useState(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        if (radius) {
            setReportPinData(prev => ({
                ...prev,
                radius: Math.round(radius)
            }));
        }
    }, [radius]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setReportPinData(prev => ({
            ...prev,
            [name]: name === 'radius' ? Number(value) : value
        }));
    };

    const handleContinue = async () => {
        if (!coordinates) {
            setError('Koordinat bilgisi bulunamadı');
            return;
        }

        try {
            // Wikipedia verilerini JSON olarak hazırla
            const questionsJsonData = {
                wikipediaTitle: wikipediaData?.title || null,
                wikipediaSummary: wikipediaData?.summary || null
            };

            // JSON'ı Blob'a çevir ve File oluştur
            const jsonBlob = new Blob([JSON.stringify(questionsJsonData)], { type: 'application/json' });
            const jsonFile = new File([jsonBlob], 'questions.json', { type: 'application/json' });

            const formData = new FormData();
            formData.append('Name', reportPinData.name);
            formData.append('Latitude', coordinates.latitude.toString());
            formData.append('Longitude', coordinates.longitude.toString());
            formData.append('RadiusInMeters', reportPinData.radius.toString());
            formData.append('Sector', reportPinData.sector);
            formData.append('userId', userId);
            formData.append('File', jsonFile); // QuestionsJson yerine File olarak gönder



            const response = await EventsApi.createNewPin(formData);

            if (response?.id) {
                onSubmit({
                    ...reportPinData,
                    id: response.id
                });
                // Ana modalı kapat, sonra checklist modalı aç
                onClose();
                setTimeout(() => {
                    setChecklistOpen(true);
                }, 100);
                setError(null);
            } else {
                console.error('Response içinde id bulunamadı:', response);
                throw new Error('Pin ID alınamadı');
            }
        } catch (err) {
            setError('Pin kaydedilirken bir hata oluştu');
            console.error('Pin kaydetme hatası:', err);
        }
    };

    const handleChecklistClose = () => {
        setChecklistOpen(false);
        // Form verilerini sıfırla
        setReportPinData({
            name: '',
            radius: 0,
            sector: ''
        });
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                PaperProps={{
                    sx: { width: '400px', p: 1 }
                }}
            >
                <DialogTitle>Yeni Lokasyon Ekle</DialogTitle>
                <DialogContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, my: 2 }}>
                        <TextField
                            label="Lokasyon Adı"
                            name="name"
                            value={reportPinData.name}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                        <TextField
                            label="Yarıçap (metre)"
                            name="radius"
                            type="number"
                            value={reportPinData.radius}
                            onChange={handleChange}
                            fullWidth
                            required
                            inputProps={{ 
                                min: 0,
                                step: 1 // Sadece tam sayı girişine izin ver
                            }}
                        />
                        <TextField
                            label="Sektör"
                            name="sector"
                            value={reportPinData.sector}
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                        {error && (
                            <Box sx={{ color: 'error.main', mt: 1 }}>
                                {error}
                            </Box>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={onClose} 
                        fullWidth
                        sx={{
                            backgroundColor: '#fff',
                            border: 1, 
                            borderColor: '#094151', 
                            color: "#081038",
                            borderWidth: '2px',
                            '&:hover': {
                                borderWidth: '2px', 
                                backgroundColor: '#E9F8FC', 
                                color: "#081038"
                            }
                        }}
                    >
                        İptal
                    </Button>
                    <Button
                        onClick={handleContinue}
                        fullWidth
                        disabled={!reportPinData.name || !reportPinData.radius || !reportPinData.sector}
                        sx={{
                            backgroundColor: '#094151',
                            color: "#fff",
                            '&:hover': {
                                backgroundColor: '#022631',
                                color: "#fff", fontWeight: 600
                            },
                            '&:disabled': {
                                backgroundColor: 'rgba(9, 65, 81, 0.75)',   color: "#fff"
                            },
                        }}
                    >
                        Kaydet
                    </Button>
                </DialogActions>
            </Dialog>

            {/* ChecklistModal'ı ana Dialog'un dışına taşıyalım */}
            {checklistOpen && (
                <ChecklistModal
                    open={checklistOpen}
                    onClose={handleChecklistClose}
                    pinId={reportPinData.id || ''}
                />
            )}
        </>
    );
};

export default ReportPinModal;