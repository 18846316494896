import * as L from "leaflet";
import "leaflet.markercluster";

interface EventItem {
    latitude: number;
    longitude: number;
    eventTypeId: string;
}

interface EventTypeInfo {
    id: string;
    name: string;
    color: string;
}

interface CustomMarkerOptions extends L.MarkerOptions {
    events?: EventItem[];
}

let heatmapLayer: L.Layer | null = null;

export function clearHeatmap(map: L.Map) {
    if (heatmapLayer) {
        map.removeLayer(heatmapLayer);
        heatmapLayer = null;
    }
}

export function markerClusterPieChart(
    map: L.Map,
    events: EventItem[],
    onMarkerClick?: (events: EventItem[], coordinates: { latitude: number; longitude: number }) => void
): void {
    const markers = L.markerClusterGroup({
        maxClusterRadius: (zoom) => {
            if (zoom <= 7) return 80;
            if (zoom <= 9) return 60;
            if (zoom <= 11) return 40;
            return 20;
        },
        spiderfyOnMaxZoom: true,
        zoomToBoundsOnClick: true,
        disableClusteringAtZoom: 13,
        spiderfyDistanceMultiplier: 2,
        showCoverageOnHover: false,
        animate: true,
        animateAddingMarkers: true,
        chunkedLoading: true,
        chunkInterval: 100,
        chunkDelay: 50,
        spiderLegPolylineOptions: {
            weight: 1.5,
            color: '#222',
            opacity: 0.5
        },
        singleMarkerMode: false,
        removeOutsideVisibleBounds: true,
        iconCreateFunction: function(cluster) {
            const markers = cluster.getAllChildMarkers();
            
            // Tüm marker'lardaki toplam olay sayısını hesapla
            let totalEvents = 0;
            const typeCounts: { [key: string]: number } = {};
            
            markers.forEach((marker: any) => {
                const events = marker.options.events || [];
                totalEvents += events.length; // Toplam olay sayısını artır
                events.forEach((event: EventItem) => {
                    typeCounts[event.eventTypeId] = (typeCounts[event.eventTypeId] || 0) + 1;
                });
            });

            // En çok olan event tipini bul
            let maxCount = 0;
            let dominantType = '';
            Object.entries(typeCounts).forEach(([typeId, count]) => {
                if (count > maxCount) {
                    maxCount = count;
                    dominantType = typeId;
                }
            });

            // Dominant tipin rengini kullan
            const color = colorMap[dominantType] || '#cccccc';

            const svgContent = `
                <svg width="40" height="40" viewBox="0 0 40 40">
                    <circle r="20" cx="20" cy="20" fill="${color}" />
                    <circle r="18" cx="20" cy="20" fill="white" opacity="0.3"/>
                    <text x="20" y="20" text-anchor="middle" dominant-baseline="middle" 
                          fill="white" font-size="14" font-weight="bold">${totalEvents}</text>
                </svg>`;

            return L.divIcon({
                html: svgContent,
                className: 'marker-cluster',
                iconSize: L.point(40, 40)
            });
        },
    });

    const colorMap: { [key: string]: string } = {
        'cf5f045f-a360-9c6f-5566-757fd856878a': '#EC8E5B', //Terörizm (TE)
        '450b0d5e-6639-3649-b055-45ad25dc1e72': '#F35E5E', //Örgütlü Suçlar (OS)
        'ff4ee709-9d4d-3b66-e0c7-09456d55d54d': '#67C086', //Sosyal ve Politik İstikrarsızlık (SP)
        '0d2afe18-19b2-d63a-5d3e-6f92105b778b': '#689D7B', //Çevresel ve Doğal Afetler (ÇA)
        '2996d900-08f7-fe3b-624e-685d3e0230f8': '#63ABC3', //Bilgi Güvenliği ve Enformasyon Tehditleri (BG)
        '0a6d93e8-135c-d061-cac2-e9ff390567c8': '#4995DB', //Bireysel ve Adi Suçlar (BA)
        'fa04c441-2bc2-3c4a-7f77-d59738f05372': '#8e6c58', //Göç ve Mülteci Krizleri (GM)
        '415de057-31d6-87ab-00c2-9d038fc4a7d2': '#265454', //Sağlık ve Biyolojik Tehditler (SB)
        'fb7cdcca-889e-f136-64ed-351dee94cebf': '#0f223a', //Yasal ve Düzenleyici Uyumsuzluklar (YD)
        '2567eb67-43ad-8075-07ce-8b95a5c59305': '#4a3b39', //Ekonomik ve Finansal Tehditler (EF)
    };

    const eventTypes: { [key: string]: EventTypeInfo } = {
        'cf5f045f-a360-9c6f-5566-757fd856878a': { id: 'cf5f045f-a360-9c6f-5566-757fd856878a', name: 'Terörizm (TE)', color: '#EC8E5B' },
        '450b0d5e-6639-3649-b055-45ad25dc1e72': { id: '450b0d5e-6639-3649-b055-45ad25dc1e72', name: 'Örgütlü Suçlar (OS)', color: '#F35E5E' },
        'ff4ee709-9d4d-3b66-e0c7-09456d55d54d': { id: 'ff4ee709-9d4d-3b66-e0c7-09456d55d54d', name: 'Sosyal ve Politik İstikrarsızlık (SP)', color: '#67C086' },
        '0d2afe18-19b2-d63a-5d3e-6f92105b778b': { id: '0d2afe18-19b2-d63a-5d3e-6f92105b778b', name: 'Çevresel ve Doğal Afetler (ÇA)', color: '#EC8E5B' },
        '2996d900-08f7-fe3b-624e-685d3e0230f8': { id: '2996d900-08f7-fe3b-624e-685d3e0230f8', name: 'Bilgi Güvenliği ve Enformasyon Tehditleri (BG)', color: '#63ABC3' },
        '0a6d93e8-135c-d061-cac2-e9ff390567c8': { id: '0a6d93e8-135c-d061-cac2-e9ff390567c8', name: 'Bireysel ve Adi Suçlar (BA)', color: '#4995DB' },
        'fa04c441-2bc2-3c4a-7f77-d59738f05372': { id: 'fa04c441-2bc2-3c4a-7f77-d59738f05372', name: 'Göç ve Mülteci Krizleri (GM)', color: '#8e6c58' },
        '415de057-31d6-87ab-00c2-9d038fc4a7d2': { id: '415de057-31d6-87ab-00c2-9d038fc4a7d2', name: 'Sağlık ve Biyolojik Tehditler (SB)', color: '#265454' },
        'fb7cdcca-889e-f136-64ed-351dee94cebf': { id: 'fb7cdcca-889e-f136-64ed-351dee94cebf', name: 'Yasal ve Düzenleyici Uyumsuzluklar (YD)', color: '#0f223a' },
        '2567eb67-43ad-8075-07ce-8b95a5c59305': { id: '2567eb67-43ad-8075-07ce-8b95a5c59305', name: 'Ekonomik ve Finansal Tehditler (EF)', color: '#4a3b39' },
    };

    const groupedEvents: { [key: string]: EventItem[] } = {};
    events.forEach((event) => {
        const key = `${event.latitude},${event.longitude}`;
        if (!groupedEvents[key]) groupedEvents[key] = [];
        groupedEvents[key].push(event);
    });

    Object.entries(groupedEvents).forEach(([key, eventsAtLocation]) => {
        const [latitude, longitude] = key.split(",").map(Number);

        const typeCounts: { [key: string]: number } = {};
        eventsAtLocation.forEach((event) => {
            typeCounts[event.eventTypeId] = (typeCounts[event.eventTypeId] || 0) + 1;
        });

        const total = Object.values(typeCounts).reduce((acc, val) => acc + val, 0);
        const typeEntries = Object.entries(typeCounts);

        const pieSlices = typeEntries.map(([eventTypeId, count]) => {
            const eventColor = colorMap[eventTypeId];
            return {
                color: eventColor || "#cccccc",
                percentage: (count / total) * 100
            };
        });


        const svgContent = typeEntries.length === 1 ?
            // Tek kategori için düz daire
            `<svg width="30" height="30" viewBox="0 0 32 32">
                <circle r="16" cx="16" cy="16" fill="${pieSlices[0].color}" />
                <text x="16" y="16" text-anchor="middle" dominant-baseline="middle" fill="white" font-size="15">${total}</text>
            </svg>` :
            // Çoklu kategori için pie chart
            `<svg width="30" height="30" viewBox="0 0 32 32">
                <circle r="16" cx="16" cy="16" fill="white" />
                ${generatePieChartSlices(pieSlices)}
                <text x="16" y="16" text-anchor="middle" dominant-baseline="middle" fill="black" font-size="15">${total}</text>
            </svg>`;

        const markerIcon = L.divIcon({
            html: svgContent,
            className: "",
            iconSize: [50, 50],
        });

        const marker = L.marker([latitude, longitude], {
            icon: markerIcon,
            events: eventsAtLocation
        } as CustomMarkerOptions)
            .bindTooltip(() => {
                return `
           <div style="background: white; border: 1px solid #ccc; padding: 8px; border-radius: 4px; box-shadow: 0 2px 4px rgba(0,0,0,0.1); min-width: 200px;">
               ${Object.entries(typeCounts)
                    .map(([eventTypeId, count]) => {
                        const info = eventTypes[eventTypeId];
                        return `
                           <div style="display: flex; align-items: center; margin: 4px 0;">
                               <div style="width: 12px; height: 12px; background: ${info.color}; margin-right: 8px; border-radius: 2px;"></div>
                               <span style="font-family: Arial, sans-serif;">${info.name}: ${count}</span>
                           </div>
                       `;
                    })
                    .join('')
                }
           </div>
       `;
            }, {
                direction: 'top',
                offset: [0, -20],
                permanent: false,
                sticky: true
            })
            .on('click', () => {
                if (onMarkerClick) {
                    onMarkerClick(eventsAtLocation, { latitude, longitude });
                }
            });

        markers.addLayer(marker);
    });

    map.addLayer(markers);
}

function generatePieChartSlices(slices: { color: string; percentage: number }[]) {
    let cumulativePercentage = 0;

    return slices
        .map(({ color, percentage }) => {
            const start = cumulativePercentage;
            const end = cumulativePercentage + percentage;
            cumulativePercentage = end;

            const largeArcFlag = percentage > 50 ? 1 : 0;
            const startX = Math.cos((2 * Math.PI * start) / 100) * 16 + 16;
            const startY = Math.sin((2 * Math.PI * start) / 100) * 16 + 16;
            const endX = Math.cos((2 * Math.PI * end) / 100) * 16 + 16;
            const endY = Math.sin((2 * Math.PI * end) / 100) * 16 + 16;

            return `
        <path d="M16 16 L${startX} ${startY} A16 16 0 ${largeArcFlag} 1 ${endX} ${endY} Z" fill="${color}" />
      `;
        })
        .join("");
}
