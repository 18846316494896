import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Filter from "./components/Filter";
import {useEffect, useState} from "react";
import {subMonths} from "date-fns";
import eventsApi from "../../api/eventsApi/eventsApi";
import Map from "./components/Map";
import Summary from "./components/Summary";

const defaultFiltreler = {
	dateStart: subMonths(new Date(), 1),
	dateEnd: new Date(),
}

const Home = () => {
	const [filters, setFilters] = useState<any>(defaultFiltreler)
	const [events, setEvents] = useState<any>([])
	const [pins, setPins] = useState<any>([])
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		eventsApi.getPins().then((res: any) => {
			setPins(res?.items ?? [])
		})
	}, [])

	useEffect(() => {
		setIsLoading(true)
		if (filters?.dateStart && filters?.dateEnd) {
			// Şehir seçili değilse getEventsCounts, seçili ise getEvents kullan
			const apiCall = filters.city ? eventsApi.getEvents : eventsApi.getEventsCounts;
			
			apiCall(filters)
				.then(res => {
					setEvents(res);
				})
				.finally(() => {
					setIsLoading(false)
				});
		}
	}, [filters])

	return <Box width={"100%"}>
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Filter filters={filters} setFilters={setFilters} defaults={defaultFiltreler}/>
			</Grid>
			<Grid item xs={12} md={9}>
				<Map
					setPins={setPins}
					pins={pins}
					events={events}
					filters={filters}
					setFilters={setFilters}
					isLoading={isLoading}
				/>
			</Grid>
			<Grid item xs={12} md={3}>
				<Summary
					eventTypeCounts={events.eventTypeCounts}
					districtEventCounts={events.districtEventCounts}
					startDate={filters.dateStart}
					endDate={filters.dateEnd}
					filters={filters}
					isLoading={isLoading}
				/>
			</Grid>
		</Grid>
	</Box>
}
export default Home;