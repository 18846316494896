import {
	Dialog,
	DialogContent,
	DialogTitle,
	Paper,
	Typography,
	Box,
	CircularProgress,
	FormControlLabel,
	Switch
} from "@mui/material";
import {GeoJSON, MapContainer, Marker, TileLayer, useMap} from "react-leaflet";
import React, {useEffect, useState} from "react";
import il_sinirlari from "../../../assets/geojson/tr-cities-utf8.json";
import * as L from "leaflet";
import {LatLngExpression} from "leaflet";
import {addFeatureEvents, getFeatureStyle} from "../../../helpers/mapStyles";
import { markerClusterPieChart } from "../../../helpers/markerClusterPieChart";
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import {useSelector} from "../../../redux/store";
import WikipediaModal from "../../../helpers/wikipediaModal";
import {fetchWikipediaData, fetchWikipediaDataByCoordinates} from "../../../api/publicApi/wikipedia";
import Legend from './Legend';
import DistrictModal from './DistrictModal';
import EventPinModal, { EventItem } from './EventPinModal';
import ReportPinModal from "./ReportPinModal";
import ChecklistModal from './checklistModal';
import EventsApi from "../../../api/eventsApi/eventsApi";
import 'leaflet.heat';

interface IMapProps {
	events: any,
	filters: any,
	setFilters: any,
	pins: any,
	setPins: any,
	isLoading: boolean
}

interface FormAnswers {
	[key: string]: {
		answer: string;
		description: string;
	};
}

interface CityEventCount {
	city: string;
	count: number;
	averageDailyCount: number;
	lastDayCount: number;
	logThreatLevel: number;
	threatLevel: number;
	events?: Array<{
		latitude: number;
		longitude: number;
		eventTypeId: string;
		description: string;
		date: string;
	}>;
}

interface DistrictEventCount {
	district: string;
	count: number;
	averageDailyCount: number;
	lastDayCount: number;
	logThreatLevel: number;
	threatLevel: number;
	events?: Array<{
		latitude: number;
		longitude: number;
		eventTypeId: string;
		description: string;
		date: string;
	}>;
}

interface PinDialogProps {
	open: boolean;
	onClose: () => void;
	pin: any;
}

interface PinResponse {
	items: Array<{
		id: string;
		name: string;
		latitude: number;
		longitude: number;
		radiusInMeters: number;
		sector: string;
		userId: string;
	}>;
}

const customMarkerIcon = new L.Icon({
	iconUrl: '/placeholder.png', //????
	iconSize: [32, 32], // İkon boyutu: genişlik, yükseklik
	iconAnchor: [16, 32], // Haritada sabit noktası (ikonun alt ortası)
	popupAnchor: [0, -32], // Popup konumlandırması
});

const PinDialog = ({open, onClose, pin}: PinDialogProps) => {
	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>{pin?.name || 'Pin Details'}</DialogTitle>
			<DialogContent>
				<div className="space-y-4">
					<div>
						<strong>Sector:</strong> {pin?.sector}
					</div>
					<div>
						<strong>Radius:</strong> {pin?.radiusInMeters?.toFixed(2)} meters
					</div>
					<div>
						<strong>Event Count:</strong> {pin?.events?.length ?? 0}
					</div>
					<div>
						<strong>Report:</strong> {pin?.reportDescription ?? ""}
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};

const Map = (props: IMapProps) => {
	const {events, filters, setFilters, pins, setPins, isLoading} = props;
	const center: LatLngExpression = [38.9637, 35.2433];
	const [useNewColorScale, setUseNewColorScale] = useState<boolean>(true);

	return (
		<Paper elevation={3} sx={{width: '100%', height: '70vh', overflow: 'hidden', position: 'relative'}}>
			{isLoading && (
				<Box
					sx={{
						position: 'absolute',
						top: 0,
						left: 0,
						right: 0,
						bottom: 0,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						backgroundColor: 'rgba(255, 255, 255, 0.7)',
						zIndex: 1000,
					}}
				>
					<CircularProgress />
				</Box>
			)}
			<MapContainer
				center={center}
				zoom={6}
				style={{height: '100%', width: '100%'}}
				dragging={true}
				touchZoom={true}
				doubleClickZoom={true}
				scrollWheelZoom={true}
				boxZoom={true}
				keyboard={true}
			>
				<MapComponents 
					events={events} 
					filters={filters} 
					setFilters={setFilters} 
					pins={pins} 
					setPins={setPins}
					isLoading={isLoading}
					useNewColorScale={useNewColorScale}
					setUseNewColorScale={setUseNewColorScale}
				/>
			</MapContainer>
		</Paper>
	)
}

// HeatLayer için type declaration ekleyelim
declare module 'leaflet' {
	export interface HeatMapOptions {
		minOpacity?: number;
		maxZoom?: number;
		max?: number;
		radius?: number;
		blur?: number;
		gradient?: {[key: number]: string};
	}
	
	export interface HeatLayer extends L.Layer {
		setLatLngs(latlngs: L.LatLngExpression[]): void;
		addLatLng(latlng: L.LatLngExpression): void;
		setOptions(options: HeatMapOptions): void;
	}
	
	export interface HeatLayerFactory {
		(latlngs: L.LatLngExpression[], options?: HeatMapOptions): HeatLayer;
	}
	
	export interface LeafletStatic {
		heatLayer: HeatLayerFactory;
	}

	interface Map {
		pm?: any;
		addControl(control: Control.Draw): this;
		removeControl(control: Control.Draw): this;
	}
}

const MapComponents = (props: IMapProps & {
	useNewColorScale: boolean;
	setUseNewColorScale: (value: boolean) => void;
}) => {
	const {events, filters, setFilters, pins, setPins, isLoading, useNewColorScale, setUseNewColorScale} = props;
	const map = useMap();
	const [cityPins, setCityPins] = useState<Array<{
		id: string;
		name: string;
		latitude: number;
		longitude: number;
		radiusInMeters: number;
		sector: string;
		userId: string;
	}>>([]);
	const [searchQuery, setSearchQuery] = React.useState('');
	const [suggestions, setSuggestions] = React.useState<any[]>([]);
	const [searchPin, setSearchPin] = useState<[number, number] | null>(null);
	const [openModal, setOpenModal] = useState(false);
	const [wikipediaModalOpen, setWikipediaModalOpen] = useState(false);
	const [wikipediaTitle, setWikipediaTitle] = useState<string>('');
	const [eventPinModalOpen, setEventPinModalOpen] = useState(false);
	const [reportPinModalOpen, setReportPinModalOpen] = useState(false);
	const [selectedPinEvents, setSelectedPinEvents] = useState<EventItem[]>([]);
	const [selectedPinCoordinates, setSelectedPinCoordinates] = useState<{latitude: number; longitude: number} | null>(null);
	const [districtModalOpen, setDistrictModalOpen] = useState(false);
	const [selectedDistrict, setSelectedDistrict] = useState("");
	const [showPieChart, setShowPieChart] = useState<boolean>(true);
	const [showDistrictFill, setShowDistrictFill] = useState<boolean>(true);
	const [showHeatmap, setShowHeatmap] = useState<boolean>(false);
	const [wikipediaData, setWikipediaData] = useState<{
		title: string | null;
		summary: string | null;
		thumbnail: string | null;
	}>({
		title: null,
		summary: null,
		thumbnail: null
	});
	const [loading, setLoading] = useState<boolean>(false);
	const [savedPinData, setSavedPinData] = useState<{
		id?: string;
		name: string;
		latitude: number;
		longitude: number;
		radius: number;
		sector: string;
		reportDescription?: string | null;
	} | null>(null);
	const [checklistOpen, setChecklistOpen] = useState(false);
	const [drawnItems] = useState(() => new L.FeatureGroup());
	const [drawnCircle, setDrawnCircle] = useState<{
		center: [number, number];
		radius: number;
	} | null>(null);

	const center: LatLngExpression = [38.9637, 35.2433];

	const getCleanTitle = (displayName: string) => {
		const parts = displayName.split(',');
		return parts[0].trim(); // İlk kısmı al ve döndür
	};

	const userId = useSelector(state => state.auth.id);

	const {districtBounds: ilce_sinirlari} = useSelector(state => state.app)
	// const drawnItems = L.featureGroup().addTo(map);

	useEffect(() => {
		if (map) {
			if (suggestions.length > 0) {
				map.scrollWheelZoom.disable(); // Dropdown açıkken zoom'u devre dışı bırak
			} else {
				map.scrollWheelZoom.enable(); // Dropdown kapalıyken zoom'u tekrar aktif et
			}
		}
	}, [map, suggestions]);

	const calculateTValue = (count: number): number => {
		return Math.log(count + 1) * 3; // Basit bir risk hesaplaması
	}

	const interpretTValue = (t: number): string => {
		if (t < 3) return 'Düşük';
		if (t >= 3 && t <= 6) return 'Orta';
		if (t >= 6 && t <= 12) return 'Yüksek';
		return 'Çok Yüksek';
	}

	var ilSinirlari: any = il_sinirlari;

	const eventCounts: any = {}
	if (!filters.city && !!events.cityEventCounts) {
		events.cityEventCounts.forEach((city: CityEventCount) => {
			eventCounts[city.city] = {
				city: city.city,
				count: city.count,
				averageDailyCount: city.averageDailyCount,
				lastDayCount: city.lastDayCount,
				logThreatLevel: city.logThreatLevel,
				threatLevel: city.threatLevel,
				t: calculateTValue(city.count),
				tInterpretation: interpretTValue(calculateTValue(city.count)),
				events: events.items?.filter((item: any) => item.city === city.city).map((item: any) => ({
					latitude: item.latitude,
					longitude: item.longitude,
					eventTypeId: item.eventType.id,
					description: item.description,
					date: item.date
				}))
			}
		});
	} else if (filters.city && !!events.districtEventCounts) {
		events.districtEventCounts.forEach((district: DistrictEventCount) => {
			eventCounts[district.district] = {
				city: district.district,
				count: district.count,
				averageDailyCount: district.averageDailyCount,
				lastDayCount: district.lastDayCount,
				logThreatLevel: district.logThreatLevel,
				threatLevel: district.threatLevel,
				t: calculateTValue(district.count),
				tInterpretation: interpretTValue(calculateTValue(district.count)),
				events: events.items?.filter((item: any) => item.district === district.district).map((item: any) => ({
					latitude: item.latitude,
					longitude: item.longitude,
					eventTypeId: item.eventType.id,
					description: item.description,
					date: item.date
				}))
			}
		});
	}

	const CityBounds = () => {
		map.setView(center, 6)

		// Marker'ları temizle
		map.eachLayer((layer) => {
			if (layer instanceof L.MarkerClusterGroup) {
				map.removeLayer(layer);
			}
		});

		return ilSinirlari.features.map((feature: any) => {
			const city = feature.properties.name;
			const eventData = eventCounts[city];
			const uniqueKey = feature.id || `city-${city}`;

			return (
				<GeoJSON
					key={uniqueKey}
					data={feature}
					style={getFeatureStyle(eventData, filters.eventTypeId, useNewColorScale)}
					onEachFeature={(feature: any, layer: any) => {
						layer.on({
							click: () => {
								const bounds = layer.getBounds();
								map.fitBounds(bounds, { padding: [50, 50] });
								setFilters({...filters, city});
							}
						});
						addFeatureEvents(layer, eventData)
					}}
				/>
			);
		})
	}

	const DistrictBounds = () => {
		const [isDistrictDataLoading, setIsDistrictDataLoading] = useState(true);
		const [bounds, setBounds] = useState<L.LatLngBounds | null>(null);

		// İlçe sınırları yüklenme durumunu kontrol et
		useEffect(() => {
			if (ilce_sinirlari?.features) {
				setIsDistrictDataLoading(false);
			}
		}, [ilce_sinirlari]);

		// İlçe sınırları ve harita işlemleri
		useEffect(() => {
			if (!isDistrictDataLoading && ilce_sinirlari?.features) {
				const ilFeature = ilce_sinirlari.features.find((q: any) => q.properties.name === filters.city);
				
				if (ilFeature) {
					const layer = L.geoJSON(ilFeature);
					const newBounds = layer.getBounds();
					setBounds(newBounds);
					map.fitBounds(newBounds);
				}

				// Tüm katmanları temizle
				map.eachLayer((layer) => {
					if (layer instanceof L.MarkerClusterGroup) {
						map.removeLayer(layer);
					}
				});

				if (events?.events?.items?.length > 0 && showPieChart) {
					const mappedItems = events.events.items.map((item: {
						latitude: any;
						longitude: any;
						eventType: { id: any; };
						description?: string;
						date?: string;
					}) => ({
						latitude: item.latitude,
						longitude: item.longitude,
						eventTypeId: item.eventType.id,
						description: item.description,
						date: item.date
					}));

					markerClusterPieChart(
						map,
						mappedItems,
						(events, coordinates) => {
							setSelectedPinEvents(events);
							setSelectedPinCoordinates(coordinates);
							setEventPinModalOpen(true);
						}
					);
				}
			}
		}, [isDistrictDataLoading, ilce_sinirlari, events?.events?.items, map, filters.city, showPieChart]);

		const getModifiedFeatureStyle = (eventData: any) => {
			const baseStyle = getFeatureStyle(eventData, filters.eventTypeId, useNewColorScale);
			return (feature: any) => {
				const style = baseStyle(feature);
				return showDistrictFill ? style : { ...style, fillOpacity: 0 };
			};
		};

		if (isDistrictDataLoading || !ilce_sinirlari?.features) {
			return <CircularProgress 
				sx={{ 
					position: 'absolute', 
					top: '50%', 
					left: '50%', 
					transform: 'translate(-50%, -50%)' 
				}} 
			/>;
		}

		// İlçe sınırlarını render et
		return (
			<React.Fragment>
				{ilce_sinirlari.features
					.filter((q: any) => q.properties.IL_ADI === filters.city)
					.map((feature: any) => {
						const eventData = eventCounts[feature.properties.ILCE_ADI];
						const uniqueKey = `district-${feature.id || feature.properties.ILCE_ADI}`;

						return (
							<GeoJSON
								pane="tilePane"
								key={uniqueKey}
								data={feature}
								style={getModifiedFeatureStyle(eventData)}
								onEachFeature={(feature, layer) => {
									addFeatureEvents(layer, eventData);
									layer.on('click', () => {
										setSelectedDistrict(feature.properties.ILCE_ADI);
										setDistrictModalOpen(true);
									});
								}}
							/>
						);
					})}
				<DistrictModal
					open={districtModalOpen}
					onClose={() => setDistrictModalOpen(false)}
					districtName={selectedDistrict}
					filters={filters}
				/>
				{selectedPinCoordinates && (
					<EventPinModal
						open={eventPinModalOpen}
						onClose={() => setEventPinModalOpen(false)}
						events={selectedPinEvents}
						coordinates={selectedPinCoordinates}
					/>
				)}
			</React.Fragment>
		);
	}

	const MarkdownOptions = {
		overrides: {
			h4: {
				component: Typography,
				props: {
					variant: 'h6',
					color: 'primary',
					gutterBottom: true,
					sx: {mt: 2}
				},
			},
			h3: {
				component: Typography,
				props: {
					variant: 'h5',
					color: 'primary',
					gutterBottom: true,
					sx: {mt: 3}
				},
			},
			p: {
				component: Typography,
				props: {
					paragraph: true,
					sx: {mb: 2}
				},
			},
			ul: {
				props: {
					style: {
						marginBottom: '1rem',
						paddingLeft: '1.5rem'
					}
				},
			},
			li: {
				component: Typography,
				props: {
					component: 'li',
					sx: {mb: 1}
				},
			}
		},
	};

	const handleSearch = async () => {
		if (!searchQuery) return;

		try {
			const response = await fetch(
				`https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
					searchQuery
				)}&format=json&addressdetails=1&accept-language=tr`
			);
			const data = await response.json();
			setSuggestions(data); // Öneri listesini güncelle
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	const [summary, setSummary] = useState<string | null>(null);
	const [thumbnail, setThumbnail] = useState<string | null>(null);
	const [showClearButton, setShowClearButton] = useState(false);

	const handleKeyDown = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter') {
			handleSearch();
		}
	};

// Handler fonksiyonları
	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setSearchQuery(value);
		setShowClearButton(value.length > 0);

		// Eğer input boş ise suggestion listesini temizle
		if (!value) {
			setSuggestions([]);
		}
	};

	const handleClear = () => {
		setSearchQuery('');
		setShowClearButton(false);
		setSuggestions([]);
	};


	// Öneri seçimi
	const handleSuggestionSelect = async (suggestion: any) => {
		const {lat, lon, display_name} = suggestion;
		const newPosition: [number, number] = [parseFloat(lat), parseFloat(lon)];
		setSearchPin(newPosition);

		const cleanTitle = getCleanTitle(display_name);

		setLoading(true);
		if (cleanTitle) {
			try {
				const wikiData = await fetchWikipediaData(cleanTitle);
				if (!wikiData) {
					if (!!lat && !!lon) {
						const coordData = await fetchWikipediaDataByCoordinates(lat, lon);
						if (coordData) {
							setWikipediaData({
								title: coordData.title,
								summary: coordData.extract,
								thumbnail: coordData.thumbnail?.source || null
							});
						}
					}
				} else {
					setWikipediaData({
						title: wikiData.title,
						summary: wikiData.extract,
						thumbnail: wikiData.thumbnail?.source || null
					});
				}
				// Yeni pin için savedPinData'yı null yap
				setSavedPinData(null);
				setWikipediaModalOpen(true);
				setSuggestions([]);
			} catch (error) {
				console.error('Wikipedia verisi alınırken hata:', error);
			}
		} else {
			setWikipediaData({
				title: null,
				summary: null,
				thumbnail: null
			});
		}
		setLoading(false);

		// Hangi ilin sınırları içinde olduğunu bul
		const selectedCity = ilSinirlari.features.find((feature: any) =>
			L.geoJSON(feature).getBounds().contains(L.latLng(newPosition))
		);

		if (selectedCity) {
			// İlin sınırlarını yükle
			const cityName = selectedCity.properties.name;
			setFilters({...filters, city: cityName});

			// Şehir sınırlarına zoom yap
			const bounds = L.geoJSON(selectedCity).getBounds();
			map.fitBounds(bounds);
		} else {
			console.warn("Bu konum herhangi bir şehir sınırları içinde değil.");
		}

		setLoading(false);

	};

	// handleSearchPinClick fonksiyonunu basitleştirelim
	const handleSearchPinClick = () => {
		if (searchPin) {
			// Sadece modal'ı aç, Wikipedia verisi zaten var
			setWikipediaModalOpen(true);
		}
	};

	// handlePinClick fonksiyonunu güncelleyelim
	const handlePinClick = async (pin: any) => {
		try {
			setLoading(true);
			setWikipediaModalOpen(true);
			setSearchPin(null);
			
			// Pin bilgilerini API'den al
			const pinInfo = await EventsApi.getPinInfo(pin.id);
			console.log('Pin Info Response:', pinInfo);
			
			setWikipediaData({
				title: pinInfo.Name,
				summary: null,
				thumbnail: null
			});

			setSavedPinData({
				id: pinInfo.Id,
				name: pinInfo.Name,
				latitude: pinInfo.Latitude,
				longitude: pinInfo.Longitude,
				radius: pinInfo.RadiusInMeters,
				sector: pinInfo.Sector,
				reportDescription: pinInfo.ReportDescription
			});
		}
		catch (error) {
			console.error('Pin bilgileri yüklenirken hata:', error);
			setWikipediaModalOpen(false);
		}
		finally {
			setLoading(false);
		}
	};

	// useEffect içindeki draw control kısmını güncelleyelim
	useEffect(() => {
		if (!map) return;

		drawnItems.addTo(map);

		// Sadece il seçili ise draw control'ü ekle
		if (filters.city) {
			const drawControl = new L.Control.Draw({
				draw: {
					polyline: false,
					rectangle: false,
					marker: false,
					circlemarker: false,
					polygon: false,
					circle: {
						showRadius: true,
						metric: true,
						feet: false,
						shapeOptions: {
							color: '#3388ff'
						}
					}
				}
			});

			map.addControl(drawControl);

			// Erişilebilirlik özelliklerini manuel olarak ekle
			setTimeout(() => {
				const drawButton = document.querySelector('.leaflet-draw-draw-circle');
				if (drawButton) {
					drawButton.setAttribute('aria-label', 'Daire çizme aracı');
					drawButton.setAttribute('role', 'button');
					drawButton.setAttribute('tabindex', '0');
					// Klavye erişilebilirliği için
					(drawButton as HTMLElement).addEventListener('keydown', (e) => {
						if (e.key === 'Enter' || e.key === ' ') {
							e.preventDefault();
							(drawButton as HTMLElement).click();
						}
					});
				}
			}, 100);

			map.on(L.Draw.Event.CREATED, (e: any) => {
				const layer = e.layer;
				const center = layer.getLatLng();
				const radius = layer.getRadius();


				// Çizilen daire bilgilerini sakla
				setDrawnCircle({
					center: [center.lat, center.lng],
					radius: radius
				});

				// Çizilen daireyi haritaya ekle
				drawnItems.addLayer(layer);

				// Merkez noktasına marker ekle
				setSearchPin([center.lat, center.lng]);

				setWikipediaData({
					title: null,
					summary: null,
					thumbnail: null
				});
				setWikipediaModalOpen(true);
			});

			// Cleanup fonksiyonunu güncelle
			return () => {
				const drawButton = document.querySelector('.leaflet-draw-draw-circle');
				if (drawButton) {
					(drawButton as HTMLElement).removeEventListener('keydown', () => {});
				}
				map.removeControl(drawControl);
				map.off(L.Draw.Event.CREATED);
				drawnItems.clearLayers();
			};
		} else {
			// İl seçili değilse sadece drawnItems'ı temizle
			return () => {
				drawnItems.clearLayers();
			};
		}
	}, [map, filters.city]); // filters.city dependency'sini ekledik

	// handleReportPinSubmit fonksiyonunu güncelleyelim
	const handleReportPinSubmit = (reportPinData: any) => {

		setSavedPinData({
			id: reportPinData.id,
			name: reportPinData.name,
			latitude: searchPin![0],
			longitude: searchPin![1],
			radius: drawnCircle?.radius || reportPinData.radius,
			sector: reportPinData.sector
		});
		
		setReportPinModalOpen(false);
		setSearchPin(null);
		setDrawnCircle(null);
		drawnItems.clearLayers();
		loadPins();
	};

	// handleWikipediaModalClose fonksiyonunu güncelleyelim
	const handleWikipediaModalClose = () => {
		setWikipediaModalOpen(false);
		setWikipediaData({
			title: null,
			summary: null,
			thumbnail: null
		});
		setSavedPinData(null);
		
		if (searchPin && !savedPinData?.id) {
			setSearchPin(null);
			setDrawnCircle(null); // Çizilen daire bilgilerini temizle
			drawnItems.clearLayers(); // Çizilen daireyi haritadan kaldır
		}
	};

	// Pinleri yükle
	const loadPins = async () => {
		try {
			const response = await EventsApi.getPins() as PinResponse;
			if (response?.items && Array.isArray(response.items)) {
				setCityPins(response.items);
			} else {
				console.error('Beklenmeyen pin verisi formatı:', response);
			}
		} catch (error) {
			console.error('Pinler yüklenirken hata:', error);
		}
	};

	// Şehir değiştiğinde pinleri filtrele
	useEffect(() => {
		if (filters.city && ilce_sinirlari) {
			loadPins();
		}
	}, [filters.city]);

	// Pin'in seçili şehir sınırları içinde olup olmadığını kontrol et
	const isPinInSelectedCity = (pin: any) => {
		if (!filters.city || !ilce_sinirlari) return false;

		const selectedCityFeature = ilSinirlari.features.find(
			(feature: any) => feature.properties.name === filters.city
		);

		if (!selectedCityFeature) return false;

		const point = L.latLng(pin.latitude, pin.longitude);
		const cityPolygon = L.geoJSON(selectedCityFeature);
		
		return cityPolygon.getBounds().contains(point);
	};

	// Filters değiştiğinde veya temizlendiğinde markerları temizle
	useEffect(() => {
		// Sadece markerCluster ve heat layer'ları temizle, custom pinleri koru
		map.eachLayer((layer) => {
			// MarkerClusterGroup veya heat layer ise kaldır
			if (layer instanceof L.MarkerClusterGroup || 
				// @ts-ignore - HeatLayer kontrolü için
				layer._heat) {
				map.removeLayer(layer);
			}
			// Custom marker'ları kontrol et ve koru
			if (layer instanceof L.Marker) {
				// Eğer marker customMarkerIcon kullanıyorsa (yani custom pin ise) koru
				const markerIcon = (layer.getIcon() as L.Icon);
				if (markerIcon.options.iconUrl !== customMarkerIcon.options.iconUrl) {
					map.removeLayer(layer);
				}
			}
		});

		// Eğer şehir seçili değilse veya events yoksa, marker'ları ekleme
		if (!filters.city || !events?.events?.items) {
			return;
		}

		// Şehir seçili ve events varsa
		if (events?.events?.items?.length > 0) {
			if (showPieChart) {
				const mappedItems = events.events.items.map((item: {
					latitude: any;
					longitude: any;
					eventType: { id: any; };
					description?: string;
					date?: string;
				}) => ({
					latitude: item.latitude,
					longitude: item.longitude,
					eventTypeId: item.eventType.id,
					description: item.description,
					date: item.date
				}));

				markerClusterPieChart(
					map,
					mappedItems,
					(events, coordinates) => {
						setSelectedPinEvents(events);
						setSelectedPinCoordinates(coordinates);
						setEventPinModalOpen(true);
					}
				);
			} else if (showHeatmap) {
				const heatData = events.events.items.map((item: any) => [
					item.latitude,
					item.longitude,
					1
				]);
				
				// @ts-ignore
				const heatLayer = L.heatLayer(heatData, {
					radius: 35,
					blur: 30,
					maxZoom: 7,
					max: 1,
					gradient: {
						0.4: 'blue',
						0.6: 'lime',
						0.8: 'yellow',
						1.0: 'red'
					}
				}).addTo(map);

				// @ts-ignore
				map.heatLayer = heatLayer;
			}
		}
	}, [filters, events, showPieChart, showHeatmap, map]);

	// Silinen fonksiyonları geri ekleyelim
	const handleDeletePin = (pinId: string) => {
		setCityPins((prevPins) => prevPins.filter(pin => pin.id !== pinId));
		setSearchPin(null);
		setWikipediaModalOpen(false);
	};

	// Switch handler'ları geri ekleyelim
	const handlePieChartSwitch = (checked: boolean) => {
		setShowPieChart(checked);
		if (checked) {
			if (events?.events?.items && events.events.items.length > 0) {
				const mappedItems = events.events.items.map((item: {
					latitude: any;
					longitude: any;
					eventType: { id: any; };
					description?: string;
					date?: string;
				}) => ({
					latitude: item.latitude,
					longitude: item.longitude,
					eventTypeId: item.eventType.id,
					description: item.description,
					date: item.date
				}));

				markerClusterPieChart(map, mappedItems);
			}
		} else {
			map.eachLayer((layer) => {
				if (layer instanceof L.MarkerClusterGroup) {
					map.removeLayer(layer);
				}
			});
		}
	};

	const handleDistrictFillSwitch = (checked: boolean) => {
		setShowDistrictFill(checked);
	};

	const handleHeatmapSwitch = (checked: boolean) => {
		setShowHeatmap(checked);
		if (checked) {
			if (showPieChart) {
				setShowPieChart(false);
				map.eachLayer((layer) => {
					if (layer instanceof L.MarkerClusterGroup) {
						map.removeLayer(layer);
					}
				});
			}
			
			if (events?.events?.items && events.events.items.length > 0) {
				const heatData = events.events.items.map((item: any) => [
					item.latitude,
					item.longitude,
					1
				]);
				
				// @ts-ignore
				const heatLayer = L.heatLayer(heatData, {
					radius: 25,
					blur: 15,
					maxZoom: 10,
					max: 1,
					gradient: {
						0.4: 'blue',
						0.6: 'lime',
						0.8: 'yellow',
						1.0: 'red'
					}
				}).addTo(map);

				// @ts-ignore
				map.heatLayer = heatLayer;
			}
		} else {
			// @ts-ignore
			if (map.heatLayer) {
				// @ts-ignore
				map.removeLayer(map.heatLayer);
				// @ts-ignore
				map.heatLayer = null;
			}
		}
	};

	return (
		<React.Fragment>
			<TileLayer
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				attribution=""
			/>
			{!isLoading && (
				<>
					<Legend 
						selectedEventType={filters.eventTypeId}
						useNewColorScale={useNewColorScale}
						onColorScaleChange={setUseNewColorScale}
					/>
					{filters.city && (
						<div
							style={{
								position: 'absolute',
								top: '10px',
								left: '10px',
								zIndex: 1000,
								backgroundColor: 'white',
								padding: '10px',
								borderRadius: '8px',
								boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
							}}
						>
							<FormControlLabel
								control={
									<Switch
										checked={showDistrictFill}
										onChange={(e) => handleDistrictFillSwitch(e.target.checked)}
										disabled={showHeatmap}
										color="primary"
									/>
								}
								label="İlçe Renklendirme"
							/>
							<FormControlLabel
								control={
									<Switch
										checked={showPieChart}
										onChange={(e) => {
											if (e.target.checked && showHeatmap) {
												setShowHeatmap(false);
											}
											handlePieChartSwitch(e.target.checked);
										}}
										disabled={showHeatmap}
										color="primary"
									/>
								}
								label="Olay Dağılımı"
							/>
							<FormControlLabel
								control={
									<Switch
										checked={showHeatmap}
										onChange={(e) => {
											if (e.target.checked) {
												setShowPieChart(false);
												setShowDistrictFill(false);
											}
											handleHeatmapSwitch(e.target.checked);
										}}
										color="primary"
									/>
								}
								label="Isı Haritası"
							/>
						</div>
					)}
					<div
						style={{
							position: 'absolute',
							top: '10px',
							right: '10px',
							zIndex: 1000,
							backgroundColor: 'white',
							padding: '10px',
							borderRadius: '8px',
							boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
							width: '225px',
						}}
					>
						{/* Arama Kutusu */}
						<div style={{position: 'relative'}}>
							<input
								type="text"
								value={searchQuery}
								onChange={handleInputChange}
								onKeyDown={handleKeyDown}
								placeholder="Search for a place"
								style={{
									width: '100%',
									padding: '5px',
									paddingRight: '25px' // X butonu için sağdan boşluk
								}}

							/>
							{showClearButton && (
								<div
									onClick={handleClear}
									style={{
										position: 'absolute',
										right: '8px',
										top: '50%',
										transform: 'translateY(-50%)',
										cursor: 'pointer',
										opacity: 0.5,
										fontSize: '16px',
										color: '#666'
									}}
								>
									×
								</div>
							)}
						</div>
						{/* Öneri Listesi - searchQuery kontrolü eklendi */}
						{searchQuery && suggestions.length > 0 && (
							<ul
								style={{
									listStyleType: 'none',
									padding: 0,
									margin: '10px 0 0 0',
									backgroundColor: 'white',
									border: '1px solid #ddd',
									borderRadius: '4px',
									boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
									maxHeight: '600px',
									overflowY: 'auto',
									position: 'absolute',
									width: '100%',
									zIndex: 1000,
								}}
							>
								{suggestions.map((suggestion, index) => (
									<li
										key={index}
										style={{
											padding: '8px',
											cursor: 'pointer',
											borderBottom: '1px solid #eee',
										}}
										onClick={() => handleSuggestionSelect(suggestion)}
									>
										{suggestion.display_name}
									</li>
								))}
							</ul>
						)}
					</div>

					{/* Search Pin */}
					{searchPin && (
						<Marker
							position={searchPin}
							icon={customMarkerIcon}
							eventHandlers={{
								click: handleSearchPinClick, // Pin'e tıklayınca modal açılır
							}}
						/>
					)}

					{/* Modal */}
					<WikipediaModal
						open={wikipediaModalOpen}
						onClose={handleWikipediaModalClose}
						onDelete={() => handleDeletePin(savedPinData?.id || '')}
						onCreateReport={() => setReportPinModalOpen(true)}
						onOpenChecklist={() => setChecklistOpen(true)}
						title={wikipediaData.title}
						loading={loading}
						thumbnail={wikipediaData.thumbnail}
						summary={wikipediaData.summary}
						savedPinData={savedPinData}
					/>

					<ReportPinModal
						open={reportPinModalOpen}
						onClose={() => {
							setReportPinModalOpen(false);
						}}
						coordinates={searchPin ? { latitude: searchPin[0], longitude: searchPin[1] } : undefined}
						radius={drawnCircle?.radius}
						wikipediaData={{
							title: wikipediaData.title,
							summary: wikipediaData.summary
						}}
						onSubmit={handleReportPinSubmit}
					/>

					<ChecklistModal
						open={checklistOpen}
						onClose={() => {
							setChecklistOpen(false);
						}}
						pinId={savedPinData?.id || ''}
					/>

					{!filters.city ? <CityBounds/> : <DistrictBounds/>}

					{/* Şehir pinlerini göster - Circle yerine Marker kullanacağız */}
					{cityPins.map(pin => {
						if (isPinInSelectedCity(pin)) {
							return (
								<Marker
									key={pin.id}
									position={[pin.latitude, pin.longitude]}
									icon={customMarkerIcon}
									eventHandlers={{
										click: () => handlePinClick(pin)
									}}
								/>
							);
						}
						return null;
					})}
				</>
			)}
		</React.Fragment>
	)
}

export default Map;