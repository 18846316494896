import * as React from 'react';
import { useEffect } from 'react';
import { AppDispatch } from "../../redux/store";
import { logout } from "../../redux/actions/authActions";
import { Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';

const Logout = () => {
	const dispatch = AppDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(logout());
		// İsteğe bağlı: Logout işleminden sonra login sayfasına yönlendirme
		navigate('/login');
	}, []); // Boş dependency array ile sadece component mount olduğunda çalışır

	return (
		<Typography>
			Çıkış yapılıyor...
		</Typography>
	);
}

export default Logout;