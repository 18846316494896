import AppBar from "./AppBar";
import {Outlet} from "react-router-dom";
import {Paper} from "@mui/material";
import {AppDispatch, useSelector} from "../../../redux/store";
import {useEffect} from "react";
import eventsApi from "../../../api/eventsApi/eventsApi";
import {saveEventTypes, saveDistrictBounds} from "../../../redux/actions/appActions";
import {IEventType} from "../../../redux/reducers/appReducer";

interface EventTypeResponse {
	items: Array<{
		id: string;
		name: string;
	}>;
}

interface DistrictBoundsResponse {
	features: any[]; // GeoJSON feature tipini kullanabilirsiniz
}

const PageWrapper = () => {
	const {eventTypes, districtBounds} = useSelector(state => state.app)
	const dispatch = AppDispatch();

	useEffect(() => {
		// Eğer veriler henüz yüklenmediyse, paralel olarak yükle
		if (!eventTypes || !districtBounds) {
			Promise.all([
				!eventTypes ? eventsApi.getEventTypes() : Promise.resolve(null),
				!districtBounds ? eventsApi.getDistrictBounds() : Promise.resolve(null)
			])
			.then(([eventTypesResponse, districtBoundsResponse]) => {
				if (eventTypesResponse) {
					const typedResponse = eventTypesResponse as EventTypeResponse;
					// API response'unu IEventType formatına dönüştür
					const formattedEventTypes: IEventType[] = typedResponse.items.map(item => ({
						label: item.name,
						value: item.id
					}));
					dispatch(saveEventTypes(formattedEventTypes));
				}
				if (districtBoundsResponse) {
					const typedResponse = districtBoundsResponse as DistrictBoundsResponse;
					dispatch(saveDistrictBounds(typedResponse));
				}
			})
			.catch(error => {
				console.warn('Veri yükleme hatası:', error);
			});
		}
	}, []); // Dependency array'i boş bırakıyoruz çünkü sadece ilk yüklemede çalışmasını istiyoruz

	return <>
		<AppBar/>
		<Paper sx={{
			display: "flex",
			width: {xs: "100%", md: "calc(100% - 2rem)"},
			height: 'calc(100% - 7rem)',
			marginX: {xs: ".2rem", md: "1rem"},
			marginTop: "1rem",
			paddingY: {xs: ".2rem", md: "2rem"},
			paddingX: {xs: ".2rem", md: "1rem", xl: "2rem"},
			backgroundColor: "white",
			alignItems: "start",
			overflowY: "scroll",
		}}>
			<Outlet/>
		</Paper>
	</>
}

export default PageWrapper