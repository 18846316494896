import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Dialog, Typography, Stack, IconButton, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import EventsApi  from '../api/eventsApi/eventsApi';
import PinChartModal from '../views/home/components/pinChartModal';
import CircularProgress from '@mui/material/CircularProgress';
import ReportTemplateModal from '../views/home/components/reportTemplateModal';

interface WikipediaModalProps {
	open: boolean;
	onClose: () => void;
	onDelete: () => void;
	onCreateReport: () => void;
	onOpenChecklist?: () => void;
	title: string | null;
	loading: boolean;
	summary: string | null;
	thumbnail: string | null;
	savedPinData?: {
		id?: string;
		name?: string;
		latitude: number;
		longitude: number;
		radius: number;
		sector: string;
		reportDescription?: string | null;
	} | null;
}

const WikipediaModal: React.FC<WikipediaModalProps> = ({
														   open,
														   onClose,
														   onDelete,
														   onCreateReport,
														   onOpenChecklist,
														   loading,
														   thumbnail,
														   summary,
														   title,
														   savedPinData
													   }) => {

	// Modal için ref oluştur
	const modalRef = useRef<HTMLDivElement>(null);

	// Yeni state ekleyelim
	const [currentSummary, setCurrentSummary] = useState<string | null>(summary);
	const [isProcessing, setIsProcessing] = useState(false);
	const [animatedText, setAnimatedText] = useState('');
	const [isAnimating, setIsAnimating] = useState(false);
	const [reportTemplateOpen, setReportTemplateOpen] = useState(false);
	const [reportData, setReportData] = useState<any>(null);

	// summary prop'u değiştiğinde currentSummary'yi güncelle
	useEffect(() => {
		// Eğer pin kaydedilmişse ve reportDescription varsa onu göster
		if (savedPinData?.reportDescription) {
			setCurrentSummary(savedPinData.reportDescription);
		} else if (!savedPinData) {
			// Eğer savedPinData yoksa (yani yeni bir pin ise) Wikipedia özetini göster
			setCurrentSummary(summary);
		}
	}, [summary, savedPinData]);

	// Modal kapanırken focus'u yönet
	useEffect(() => {
		if (!open) {
			// Modal kapandığında focus'u güvenli bir şekilde yönlendir
			const mapContainer = document.querySelector('.leaflet-container');
			if (mapContainer) {
				(mapContainer as HTMLElement).focus();
			}
		} else {
			// Modal açıldığında focus'u modal içine al
			if (modalRef.current) {
				modalRef.current.focus();
			}
		}
	}, [open]);

	const handleDelete = async () => {


		if (savedPinData?.id) {
			try {
	
				await EventsApi.deletePin(savedPinData.id);

				onDelete();
			} catch (error) {
				console.error('Pin silinirken hata oluştu:', error);
			}
		} else {
			console.warn('Silinecek pin ID\'si bulunamadı:', savedPinData);
		}
	};

	const handleOpenChecklist = () => {
		if (savedPinData?.id && onOpenChecklist) {
			onOpenChecklist();
		}
	};

	// Kelime kelime animasyon için yardımcı fonksiyon
	const animateText = (text: string) => {
		const words = text.split(' ');
		let currentIndex = 0;
		setIsAnimating(true);
		
		const animationInterval = setInterval(() => {
			if (currentIndex < words.length) {
				setAnimatedText(prev => prev + (prev ? ' ' : '') + words[currentIndex]);
				currentIndex++;
			} else {
				clearInterval(animationInterval);
				setIsAnimating(false);
			}
		}, 3000 / words.length); // 3 saniyeye böl

		return () => clearInterval(animationInterval);
	};

	const handleAIEdit = async () => {
		if (!savedPinData?.id) return;

		try {
			setIsProcessing(true);
			setAnimatedText('');
			
			const result = await EventsApi.getPinReport(savedPinData.id);
			
			// Önce 'hazırlanıyor' mesajını kaldır, sonra animasyonu başlat
			setTimeout(() => {
				setIsProcessing(false);
				animateText(result.reportDescription);
			}, 500);

			setCurrentSummary(result.reportDescription);
		} catch (error) {
			console.error('Rapor oluşturma hatası:', error);
			setIsProcessing(false);
		}
	};

	const handleGetFullReport = async () => {
		if (!savedPinData?.id) return;
		
		try {
			const fullReport = await EventsApi.getPinFullReport(savedPinData.id);
			setReportData(fullReport);
			setReportTemplateOpen(true);
		} catch (error) {
			console.error('Rapor alınırken hata:', error);
		}
	};

	return (
		<>
			<Dialog
				open={open}
				onClose={onClose}
				ref={modalRef}
				aria-modal={true}
				PaperProps={{
					sx: { 
						width: '60%',
						maxWidth: '800px',
						position: 'relative',
						bgcolor: 'background.paper',
						boxShadow: 24,
						p: 4,
						borderRadius: '8px',
						maxHeight: '90vh',
						overflowY: 'auto'
					},
					role: "dialog",
					tabIndex: -1
				}}
			>
				<IconButton
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: '#094151'
					}}
				>
					<CloseIcon />
				</IconButton>

				{loading ? (
					<Box sx={{ 
						display: 'flex', 
						justifyContent: 'center', 
						alignItems: 'center',
						minHeight: '200px',
						flexDirection: 'column',
						gap: 2
					}}>
						<CircularProgress size={40} sx={{ color: '#094151' }} />
						<Typography>Yükleniyor...</Typography>
					</Box>
				) : (
					<>
						<Box sx={{ mb: 4, borderBottom: '1px solid #e0e0e0', pb: 3 }}>
							{(savedPinData?.name || title) && (
								<>
									<Typography 
										variant="h5" 
										component="h2" 
										sx={{ 
											mb: 2,
											color: '#094151',
											fontWeight: 'bold'
										}}
									>
										{savedPinData?.name || title || 'Yeni Lokasyon'}
									</Typography>
									{savedPinData && (
										<Box sx={{ mb: 2 }}>
											<Typography variant="subtitle1" color="text.secondary">
												Konum: {savedPinData.latitude.toFixed(6)}, {savedPinData.longitude.toFixed(6)}
											</Typography>
											<Typography variant="subtitle1" color="text.secondary">
												Yarıçap: {savedPinData.radius} metre
											</Typography>
											<Typography variant="subtitle1" color="text.secondary">
												Sektör: {savedPinData.sector}
											</Typography>
										</Box>
									)}
								</>
							)}
							
							{thumbnail && (
								<Box sx={{ mb: 3, display: 'flex', justifyContent: 'center' }}>
									<img
										src={thumbnail}
										alt={title || 'Wikipedia görseli'}
										style={{ 
											maxWidth: '100%',
											maxHeight: '300px',
											objectFit: 'contain',
											borderRadius: '8px'
										}}
									/>
								</Box>
							)}
							
							<Paper
								elevation={0}
								sx={{
									position: 'relative',
									p: 3,
									mt: 2,
									backgroundColor: '#f5f5f5',
									borderRadius: '12px',
									minHeight: '150px', // Minimum yükseklik ekle
								}}
							>
								<Button
									size="small"
									startIcon={<EditIcon />}
									onClick={handleAIEdit}
									disabled={isProcessing || isAnimating}
									sx={{
										position: 'absolute',
										right: -8,
										top: -16,
										backgroundColor: 'white',
										color: '#094151',
										'&:hover': {
											backgroundColor: '#e0e0e0',
										},
										fontSize: '0.75rem',
										padding: '4px 8px',
										minWidth: 'auto',
										boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
										transform: 'translateY(50%)',
										borderRadius: '6px',
										border: '1px solid #e0e0e0'
									}}
								>
									UMAY ile düzenle
								</Button>
								
								{isProcessing ? (
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
											justifyContent: 'center',
											height: '100%',
											minHeight: '100px',
											gap: 2
										}}
									>
										<CircularProgress size={20} sx={{ color: '#094151' }} />
										<Typography 
											variant="body1" 
											sx={{ 
												fontStyle: 'italic',
												color: '#666'
											}}
										>
											Hazırlanıyor... Bu işlem birkaç dakika sürebilir.
										</Typography>
									</Box>
								) : (
									<Typography 
										variant="body1" 
										sx={{ 
											lineHeight: 1.6,
											color: '#333',
											mt: 1,
											minHeight: '100px',
											transition: 'opacity 0.3s ease'
										}}
									>
										{isAnimating ? animatedText : currentSummary || 'Gösterilecek özet yok'}
									</Typography>
								)}
							</Paper>
						</Box>

						{savedPinData && (
							<>
								<Button
									variant="outlined"
									onClick={handleGetFullReport}
									fullWidth
									sx={{
										my: 3,
										py: 1.5,
										backgroundColor: '#fff',
										border: 1,
										borderColor: '#094151',
										color: "#081038",
										borderWidth: '2px',
										'&:hover': {
											borderWidth: '2px',
											backgroundColor: '#E9F8FC',
											color: "#081038"
										}
									}}
								>
									Rapor Taslağı Oluştur
								</Button>
								<PinChartModal pinId={savedPinData.id} />
							</>
						)}

						<Stack direction="row" spacing={2} sx={{ mt: 3 }}>
							<Button
								onClick={handleDelete}
								fullWidth
								sx={{
									backgroundColor: '#fff',
									border: 1,
									borderColor: '#094151',
									color: "#081038",
									borderWidth: '2px',
									'&:hover': {
										borderWidth: '2px',
										backgroundColor: '#E9F8FC',
										color: "#081038", fontWeight: 600
									}
								}}
							>
								Sil
							</Button>
							{savedPinData ? (
								<Button
									variant="contained"
									onClick={handleOpenChecklist}
									fullWidth
									sx={{
										backgroundColor: '#094151',
										color: "#fff",
										'&:hover': {
											backgroundColor: '#022631',
											color: "#fff", fontWeight: 600
										}
									}}
								>
									Kontrol Listesine Git
								</Button>
							) : (
								<Button
									variant="contained"
									onClick={onCreateReport}
									fullWidth
									sx={{
										backgroundColor: '#094151',
										color: "#fff",
										'&:hover': {
											backgroundColor: '#022631',
											color: "#fff"
										}
									}}
								>
									Bu Lokasyonu Kaydet
								</Button>
							)}
						</Stack>
					</>
				)}
			</Dialog>

			<ReportTemplateModal
				open={reportTemplateOpen}
				onClose={() => setReportTemplateOpen(false)}
				reportData={reportData}
			/>
		</>
	);
};

export default WikipediaModal;