let L: any;
if (typeof window !== 'undefined') {
	import('leaflet').then(leaflet => {
		L = leaflet;
	});
}

export interface HoverCityOptions {
	IscitySelected: boolean;
	selectedEventType: string | null;
	eventCounts: Array<{ city: string; t: number | null }>;
	interpretTValue: (t: number) => string;
}

let activeTooltips: any[] = [];

export function addFeatureEvents(layer: any, eventData: any): void {
	let tooltip: any = null;

	const getRiskLevel = (logThreatLevel: number): string => {
		if (logThreatLevel >= 12) return 'Çok Yüksek';
		if (logThreatLevel > 6) return 'Yüksek';
		if (logThreatLevel > 3) return 'Orta';
		return 'Düşük';
	};

	layer.on('mouseover', function (e: any) {
		const properties = e.target.feature.properties;
		const name = properties.name || properties.ILCE_ADI;

		let content = `<div style="text-align: center;">
            <strong>${name}</strong>`;

		if (eventData) {
			const riskLevel = getRiskLevel(eventData.logThreatLevel || 0);
			const roundedAverage = Math.ceil(eventData.averageDailyCount);
			const isLastDayHigher = eventData.lastDayCount > roundedAverage;
			const isEqual = eventData.lastDayCount === roundedAverage;

			const arrowIcon = isEqual 
				? `<svg xmlns="http://www.w3.org/2000/svg" fill="gray" height="20" viewBox="0 0 24 24" width="20" style="vertical-align: middle; margin: 0 0 0 4px;">
					<path d="M22 12l-20 0" stroke="gray" stroke-width="2"/>
				 </svg>`
				: isLastDayHigher
					? `<svg xmlns="http://www.w3.org/2000/svg" fill="red" height="20" viewBox="0 96 960 960" width="20" style="vertical-align: middle; margin: 0 0 0 4px;">
						<path d="M480 230l315 315H585v360H375V545H165L480 230z"/>
					 </svg>`
					: `<svg xmlns="http://www.w3.org/2000/svg" fill="green" height="20" viewBox="0 96 960 960" width="20" style="vertical-align: middle; margin: 0 0 0 4px;">
						<path d="M480 856L165 541h210V181h210v360h210L480 856z"/>
					 </svg>`;

			content += `<br/>Tehdit Seviyesi: ${eventData.logThreatLevel}`
			content += `<br/>Günlük Ortalama: ${roundedAverage}`;
			content += `<br/>Son gündeki Olay Sayısı: <span style="display: inline-flex; align-items: center; margin: 0;">${eventData.lastDayCount} ${arrowIcon}</span>`;
			content += `<br/>Risk Seviyesi: <strong>${riskLevel}</strong>`;
		} else {
			content += '<br/>Olay Yok';
		}
		content += '</div>';

		tooltip = L.tooltip({
			permanent: false,
			direction: 'top',
			className: 'custom-leaflet-tooltip',
		})
			.setContent(content)
			.setLatLng(e.latlng)
			.addTo(e.target._map);

		layer.setStyle({
			weight: 2,
			color: '#f1f1f1',
		});

		if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
			layer.bringToFront();
		}
	});

	layer.on('mousemove', function (e: any) {
		if (tooltip) {
			tooltip.setLatLng(e.latlng);
		}
	});

	layer.on('mouseout', function (e: any) {
		if (tooltip) {
			e.target._map.removeLayer(tooltip);
			tooltip = null;
		}

		layer.setStyle({
			weight: 1,
			color: '#094151',
		});
	});
}

export function removeAllTooltips(map: any): void {
	activeTooltips.forEach(tooltip => map.removeLayer(tooltip));
	activeTooltips = [];
}

export function getFeatureStyle(eventData: any, selectedEventType: string | null, useNewColorScale: boolean = true): (feature: any) => any {
	
	// Eski renk skalası
	const colorScales: Record<string, string[]> = {
		'cf5f045f-a360-9c6f-5566-757fd856878a': ['#FFC19F', '#FCAE84', '#EC8E5B', '#D76E3B'], //Terörizm (TE)
		'450b0d5e-6639-3649-b055-45ad25dc1e72': ['#FFB8B8', '#FF9B9B', '#FF7C7C', '#F35E5E'], //Örgütlü Suçlar (OS)
		'ff4ee709-9d4d-3b66-e0c7-09456d55d54d': ['#DEFFE9', '#B5EBC7', '#93DDAC', '#67C086'], //Sosyal ve Politik İstikrarsızlık (SP)
		'0d2afe18-19b2-d63a-5d3e-6f92105b778b': ['#C0E3CD', '#9ED0B1', '#7BB691', '#689D7B'], //Çevresel ve Doğal Afetler (ÇA)
		'2996d900-08f7-fe3b-624e-685d3e0230f8': ['#D7F5FF', '#ABE2F4', '#82CAE2', '#63ABC3'], //Bilgi Güvenliği ve Enformasyon Tehditleri (BG)
		'0a6d93e8-135c-d061-cac2-e9ff390567c8': ['#C0E1FF', '#96C8F6', '#75B1E9', '#4995DB'], //Bireysel ve Adi Suçlar (BA)
		'fa04c441-2bc2-3c4a-7f77-d59738f05372': ['#f6dfb4', '#b5a088', '#9d866b', '#8e6c58'], //Göç ve Mülteci Krizleri (GM)
		'415de057-31d6-87ab-00c2-9d038fc4a7d2': ['#a8c8ab', '#71ac81', '#417162', '#265454'], //Sağlık ve Biyolojik Tehditler (SB)
		'fb7cdcca-889e-f136-64ed-351dee94cebf': ['#329a98', '#1a7082', '#204551', '#0f223a'], //Yasal ve Düzenleyici Uyumsuzluklar (YD)
		'2567eb67-43ad-8075-07ce-8b95a5c59305': ['#ebc3aa', '#cb8374', '#a4574a', '#4a3b39'], //Ekonomik ve Finansal Tehditler (EF)
		'default': ['#9CC9E1', '#4C9CC2', '#367793', '#215264']
	};

	// Yeni renk skalası
	const newColorScale = {
		darkRed: '#ff0000',    // Neon kırmızı
		lightRed: '#ff4d4d',   // Açık neon kırmızı
		neutral: '#f5f5f5',    // Beyaz tonu
		lightGreen: '#66ff66', // Açık neon yeşil
		darkGreen: '#00ff00'   // Neon yeşil
	};

	return (feature: any): any => {
		const name = feature.properties.name || feature.properties.ILCE_ADI;

		let style: any = {
			color: '#094151',
			weight: 1,
			dashArray: '3',
			fillColor: 'transparent',
			opacity: 1.0,
			fillOpacity: 0
		};

		if (useNewColorScale) {
			// Yeni renk skalası mantığı
			if (eventData && eventData.lastDayCount >= 0) {
				const roundedAverage = Math.ceil(eventData.averageDailyCount);
				const lastDayCount = eventData.lastDayCount;
				let fillColor = 'transparent';

				if (roundedAverage === lastDayCount) {
					fillColor = newColorScale.neutral;
				} else if (roundedAverage < lastDayCount) {
					fillColor = lastDayCount > (roundedAverage * 3) 
						? newColorScale.darkRed
						: newColorScale.lightRed;
				} else {
					fillColor = lastDayCount < (roundedAverage * 3)
						? newColorScale.lightGreen
						: newColorScale.darkGreen;
				}

				if (fillColor !== 'transparent') {
					style.fillColor = fillColor;
					style.fillOpacity = 0.8;
				}
			}
		} else {
			// Eski renk skalası mantığı
		if (eventData && eventData.logThreatLevel > 0) {
				const colorScale = colorScales[selectedEventType || 'default'];
				let fillColor = 'transparent';

				if (eventData.logThreatLevel > 12) {
					fillColor = colorScale[3]; // Çok Yüksek
				} else if (eventData.logThreatLevel > 6) {
					fillColor = colorScale[2]; // Yüksek
				} else if (eventData.logThreatLevel > 3) {
					fillColor = colorScale[1]; // Orta
				} else {
					fillColor = colorScale[0]; // Düşük
				} 
				if (fillColor !== 'transparent') {
					style.fillColor = fillColor;
					style.fillOpacity = 0.8;
				}
			}
		}

		return style;
	};
}